/*Styles*/
import styled from 'styled-components';

/**Public offer**/
export const InfoWrapper = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Montserrat', sans-serif;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: white;
`

export const MainTheme = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
`

export const SimpleText = styled.div`
  font-size: 0.75rem;
  line-height: 1.1rem;
  font-weight: 300;
  margin: 0.4rem 0;
`
export const LinkUnderlining = styled.a`
  color: white;
  text-decoration: none;
  font-size: 0.75rem;
  border-bottom: 0.08rem solid white;
`

export const SimpleCause = styled.div`
  font-size: 0.75rem;
  line-height: 1.1rem;
  font-weight: 300;
  margin: 0.25rem 0 0.25rem 1rem;
`

export const Theme = styled.div`
  margin: 1.5rem 0 0.5rem 0;
  font-size: 1.1rem;
  font-weight: 600;
`



export const a6 = styled.div`

`


/**Delivery offer**/