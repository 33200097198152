/*Styles*/
import styled from 'styled-components';
import AdditionalImages from "@values/additional_images";
import {SelectNone} from '@core/css/css_snippets';



export const Scroll__Wrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 1.5rem;
  bottom: 1rem;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  z-index: 10;
  opacity: 30%;
  transition: all ease 0.25s;

  &:hover {
    opacity: 80%;
  }
`;

export const Scroll__Icon = styled.div`
  ${SelectNone};
  width: 1.3rem;
  height: 1.3rem;
  background: url(${AdditionalImages.ThinArrow}) no-repeat center;
  transform: rotate(180deg);
  filter: brightness(200%);
  margin-bottom: 0.025rem;
`;
