import React, { createContext, useContext, useReducer, useEffect } from 'react';
import Cookies from 'js-cookie';
import PropTypes from "prop-types";



const cartReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CART':
            return action.payload;
        case 'ADD_ITEM':
            // eslint-disable-next-line no-case-declarations
            const existingItemIndex = state.findIndex(item => item.id === action.payload.id);
            if (existingItemIndex !== -1) {
                return state.map(item =>
                    item.id === action.payload.id ? { ...item, count: item.count + action.payload.count } : item
                );
            } else {
                return [...state, action.payload];
            }
        case 'UPDATE_ITEM_COUNT':
            return state.map(item =>
                item.id === action.payload.id ? { ...item, count: action.payload.count } : item
            );
        case 'REMOVE_ITEM':
            return state.filter(item => item.id !== action.payload.id);
        case 'REMOVE_CART':
            return [];
        default:
            return state;
    }
};



const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, dispatch] = useReducer(cartReducer, []);

    useEffect(() => {
        const cartCookie = Cookies.get('cart');
        if (cartCookie) {
            dispatch({ type: 'SET_CART', payload: JSON.parse(cartCookie) });
        }
    }, []);

    useEffect(() => {
        Cookies.set('cart', JSON.stringify(cart));
    }, [cart]);

    const addItemToCart = (id, count) => {
        dispatch({ type: 'ADD_ITEM', payload: { id, count } });
    };

    const updateItemCount = (id, count) => {
        dispatch({ type: 'UPDATE_ITEM_COUNT', payload: { id, count } });
    };

    const removeItemFromCart = (id) => {
        dispatch({ type: 'REMOVE_ITEM', payload: { id } });
    };

    const removeCart = () => {
        Cookies.remove('cart');
        dispatch({ type: 'REMOVE_CART' });
    };

    return (
        <CartContext.Provider value={{ cart, addItemToCart, updateItemCount, removeItemFromCart, removeCart }}>
            {children}
        </CartContext.Provider>
    );
};

CartProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
    ]),
};

export const useCart = () => useContext(CartContext);


