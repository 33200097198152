import React, {useCallback, useEffect, useState} from "react";

/*Styles*/
import * as S from "./styles/inputs_form_styles";

/*Created components*/
import ErrorTooltip
    from "@core/components/form_slider_components/general_popup/tooltip_components/form_tooltip/error_tooltip";
import PropTypes from "prop-types";
import useRequest from "@core/api/hooks/useRequest";
import inputsFormContext
    from "@core/components/form_slider_components/general_popup/input_components/page_form/context/InputsFormContext";



export const InputsForm = ({children, requestUrl, formData, formDataHandleChange, successFunction, isTooltip = false, tooltipInputName}) => {
    /**API**/
    const {executeRequest, loading, errors, setErrors, successData} = useRequest();

    const handleSendData = async (e) => {
        e.preventDefault();
        await executeRequest("post", requestUrl, formData);
    };

    const [performActions, setPerformActions] = useState([]);

    const handleSetPerformAction = useCallback((action) => {
        setPerformActions((prevActions) => [...prevActions, action]);
        setErrors(null)
    }, []);

    useEffect(() => {
        if (errors && performActions.length > 0) {
            performActions.forEach((performAction) => {
                performAction(errors);
            });
        }
    }, [errors, performActions]);

    useEffect(()=>{
        if (successData){
            successFunction(successData);
        }
    },[successData])

    return (
        <inputsFormContext.Provider
            value={{formData, formDataHandleChange,
            handleSendData, loading,
            handleSetPerformAction}}>

            <S.InputsForm>
                {children}
            </S.InputsForm>
            {isTooltip &&
                <ErrorTooltip name={tooltipInputName}></ErrorTooltip>
            }
        </inputsFormContext.Provider>
    );
};

InputsForm.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
    ]),
    requestUrl: PropTypes.string,
    successFunction: PropTypes.func,
    formData: PropTypes.object,
    formDataHandleChange: PropTypes.func,
    isTooltip: PropTypes.bool,
    tooltipErrorText: PropTypes.string,
};

export default InputsForm;