/*Styles*/
import styled from 'styled-components';
import {motion} from "framer-motion";



export const MotionDiv = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
`
