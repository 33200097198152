/*React*/
import React, {useContext} from 'react';

/*Created components*/
import PopupExternalContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import SliderComponent from "@core/components/slider/slider";
import UpdateForm from "@core/components/form_slider_components/update_user_data_popup/update_form";
import UpdateConfirmation from "@core/components/form_slider_components/update_user_data_popup/update_confirmation";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";

/*Styles*/
import {AnimatePresence} from "framer-motion";



const UpdateUserDataPopup = () => {
    const {isStartFirstPos} = useContext(PopupExternalContext)
    const {definedPages} = useContext(PopupInsideContext)

    const SliderSettings = {
        closeStatus: isStartFirstPos,
    };

    return (
        <SliderComponent {...SliderSettings}>
            <SliderComponent.Slide>
                <UpdateForm />
            </SliderComponent.Slide>

            <SliderComponent.Slide>
                <AnimatePresence>
                    {definedPages.pageTwo &&
                        <UpdateConfirmation/>
                    }
                </AnimatePresence>
            </SliderComponent.Slide>
        </SliderComponent>
    );
};

export default UpdateUserDataPopup;