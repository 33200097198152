import React, {useContext, useRef, useState} from 'react';

/*Styles*/
import * as S from './styles/phone_email_input';

/*Created components*/
import InputHeader from "@core/components/form_slider_components/general_popup/input_components/input_header/input_header";
import InputWrapper from "@core/components/form_slider_components/general_popup/input_components/input_wrapper/input_wrapper";
import inputsFormContext
    from "@core/components/form_slider_components/general_popup/input_components/page_form/context/InputsFormContext";



const PhoneInput = ({name = "phone", isExplanation = false, tooltipText, forPage}) => {
    const {formDataHandleChange} = useContext(inputsFormContext)
    const explanationRef = useRef(null);
    const [phoneContact, setPhoneContact] = useState("");

    /*IMask settings*/
    const contactsInputRef = useRef(null);

    const phoneMaskReg = "+7 (000)-000-00-00";
    const [phoneLazyStatus, setPhoneLazyStatus] = useState(true)

    const handlerPhone = (value) =>{
        setPhoneContact(value);
        formDataHandleChange(name, value)
    }

    /*Additional for phone input*/
    const handlerFocusPhoneInput = () =>{
        setPhoneLazyStatus(false);
    }

    const handlerBlurPhoneInput = () =>{
        if (phoneContact === '+7 (___)-___-__-__'){
            setPhoneContact("")
            setPhoneLazyStatus(true);
        }
    }

    return (
        <InputWrapper explanationRef={explanationRef} tooltipStandardText={tooltipText} forPage={forPage} tooltipName={name}>
            <InputHeader isExplanation={isExplanation} explanationRef={explanationRef}>
                <S.PhoneEmailInput__PhoneText>Телефон</S.PhoneEmailInput__PhoneText>
            </InputHeader>

            <div>
                <S.PhoneInput__MainInput
                    mask={phoneMaskReg}
                    lazy={phoneLazyStatus}
                    unmask={false}

                    ref={contactsInputRef}
                    value={phoneContact}

                    /*Instead onChange - lib requirement*/
                    onAccept={(value) => {handlerPhone(value)}}
                    onFocus={handlerFocusPhoneInput}
                    onBlur={handlerBlurPhoneInput}
                    placeholder={"Введите телефон"}/>
            </div>
        </InputWrapper>
    );
};

export default PhoneInput;