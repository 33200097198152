import styled from "styled-components";



export const InputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 0.85em;
  font-weight: 500;
  margin-left: 0.18em;
`;

export const InputHeader__Wrapper = styled.div`
  position: relative;
`;

export const InputHeader__Text = styled.span`
`;
