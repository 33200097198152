import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/wait_payment_page_styles';

/*Created components*/
import MotionDiv from "@core/components/motion_div/motion_div";
import {
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import PopupExternalContext
    from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import {useCart} from "@entry_point/initalizers/cart_context";
import OrderPopupContext from "@components/order_popup/contexts/context";
import {useDispatch} from "react-redux";
import {setTextAndHeaderOkPopup} from "@current/main/redux_reducers/ok_popup_slice";
import useRequest from "@core/api/hooks/useRequest";



const WaitPaymentPage = () => {
    const {closePopupFunc} = useContext(PopupExternalContext);
    const {removeCart} = useCart();
    const dispatch = useDispatch();
    const {redirectUrl} = useContext(OrderPopupContext);

    const successFunction = () =>{
        closePopupFunc();
        dispatch(setTextAndHeaderOkPopup({text: "Ваш заказ оплачен и добавлен в профиль!", header: ""}))
        removeCart();
    }

    const {executeRequest, successData} = useRequest()

    const CheckPayment = async () => {
        const payment_id = localStorage.getItem('paymentId');
        if (payment_id){
            await executeRequest('post', `/api/orders/check_payment/`, {"paymentId": payment_id})
        }
    }

    useEffect(() => {
        const intervalId = setInterval(async () => {
            await CheckPayment();
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const [isPayment, setIsPayment] = useState(false)

    useEffect(()=>{
        if (successData){
            setIsPayment(true)
            localStorage.removeItem('paymentId');
            localStorage.removeItem('paymentUrl');
            successFunction();
        }
    },[successData])

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Оплата заказа</PageHeader>

                <S.SimpleFormWrapper>
                    {isPayment
                        ? <S.OrderStatus>Заказ успешно оплачен!</S.OrderStatus>
                        : <S.OrderStatus>Ожидание оплаты</S.OrderStatus>
                    }

                    <S.SimpleText>Если у вас автоматически не открылась страница оплаты, пожалуйста, перейдите по ссылке ниже</S.SimpleText>
                    <S.LinkPayment to={redirectUrl}>Ссылка на оплату</S.LinkPayment>
                </S.SimpleFormWrapper>

            </PageWrapper>
        </MotionDiv>
    );
};

export default WaitPaymentPage;