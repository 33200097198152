/*React*/
import React from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/styles';
import {standardFadeOut} from "@core/components/form_slider_components/general_popup/motion_animations/animations";



const MainBackground = ({closeClickFunc}) => {
    return (<S.MainBackground onClick={closeClickFunc} {...standardFadeOut(0.3, 0.1)}/>);
};

MainBackground.propTypes = {
    closeClickFunc: PropTypes.func,
};

export default MainBackground;

