/*React*/
import React, {useContext, useEffect, useRef, useState} from 'react';

/*Floating UI*/
import {
    arrow, autoUpdate,
    offset,
    useFloating, useTransitionStyles,
} from "@floating-ui/react";

/*Styles*/
import * as S from './styles/styles';

/*Created components*/
import addBrToString from "@core/functions/add_br_in_string";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";



const StandardTextTooltip = ({explanationRef = null, tooltipStandardText, forPage}) => {
    const {activePageName} = useContext(PopupInsideContext)

    const timerRef = useRef(null)

    /**Hover for explanation block**/
    useEffect(()=>{
        if (explanationRef && explanationRef.current){
            const handleMouseEnter = () => {
                clearTimeout(timerRef.current);
                setIsOpen(true);
            };
            const handleMouseLeave = () => {
                setIsOpen(false);
                explanationRef.current.classList.remove('hover');
            };

            const block = explanationRef.current;
            block.addEventListener('mouseenter', handleMouseEnter);
            block.addEventListener('mouseleave', handleMouseLeave);

            return () => {
                block.removeEventListener('mouseenter', handleMouseEnter);
                block.removeEventListener('mouseleave', handleMouseLeave);
            };
        }
    },[])

    /**Text defining*/
    useEffect(() =>{
        if(activePageName !== forPage){
            setIsOpen(false)
        }
        else {
            setIsOpen(true);
            if (timerRef.current){
                clearTimeout(timerRef.current);
            }
            if (explanationRef && explanationRef.current){
                explanationRef.current.classList.add('hover');
            }
            timerRef.current = setTimeout(()=>{
                setIsOpen(false);
                if (explanationRef && explanationRef.current){
                    explanationRef.current.classList.remove('hover');
                }
            }, 2500);
        }
    }, [tooltipStandardText, activePageName])



    /**Tooltip settings**/
    const [isOpen, setIsOpen] = useState(false);

    const arrowRef = useRef(null);
    const ARROW_HEIGHT = 5;
    const GAP = 1;

    const {refs, floatingStyles, context} = useFloating({
        placement: "bottom",
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset(ARROW_HEIGHT + GAP),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const {isMounted, styles} = useTransitionStyles(context, {
        duration: {
            open: 1000,
            close: 1000,
        },
    });

    return (
        <>
            <div ref={refs.setReference} />

            {isMounted && (
                <S.TooltipBlock
                    ref={refs.setFloating}
                    style={{...floatingStyles, ...styles}}
                    $zIndex = {'101'}>
                    <S.Arrow className="arrow" ref={arrowRef} context={context} width={10} height={ARROW_HEIGHT} />
                    {addBrToString(tooltipStandardText)}
                </S.TooltipBlock>
            )}
        </>
    );
};

export default StandardTextTooltip;