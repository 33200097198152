/*React*/
import React from 'react';

/*Created components*/
import DropInfo from "@components/drop_info/drop_info";
import ChainsModels from "@components/chains_models/chains_models";
import Products from "@components/product_block/products";



const MainPage = () => {
    return (
        <>
            <DropInfo />
            <ChainsModels />
            <Products />
        </>
    );
};

export default MainPage;
