/*React*/
import React, {useContext} from 'react';
import PropTypes from 'prop-types';

/*Created components*/
import {SliderContext} from '@core/components/slider/context';

/*Styles*/
import * as S from './styling/styles';



const Slide = ({children}) => {
    const {state} = useContext(SliderContext)

    return (
        <S.Slider__Slide $width={state.width}>
                {children}
        </S.Slider__Slide>
    );
};

Slide.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
    ]),
};

export default Slide;