import React, {useContext} from 'react';

/*Styles*/

/*Created components*/
import SliderContext from '@core/components/slider/context';
import PopupExternalContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import ConfirmCodePage
    from "@core/components/form_slider_components/general_popup/full_pages/confirm_code_page/confirm_code_page";



const ChangePasswordConfirmation = () => {
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const {setIsAdditionalBackground} = useContext(PopupExternalContext);
    const {
        setActivePageName,
        definedPages,
        setDefinedPages,
    } = useContext(PopupInsideContext);

    function slideToPasswordAddNewPassword() {
        setActivePageName('ChangePasswordAddNewPassword');
        setDefinedPages({...definedPages, pageFour: 'ChangePasswordAddNewPassword'});
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    }

    /**API**/
    /*Confirm page*/
    const parentSuccessFunction = (successData)=>{
        localStorage.setItem('access_token', successData.access);
        setIsAdditionalBackground(true);
        slideToPasswordAddNewPassword();
    }

    const confirmCodePageRegisterProps = {
        pageHeader: "Восстановления пароля",
        visibleAllFormPages: ['ChangePasswordConfirmation'],
        fromPage: 'ChangePassword',
        forPage: "ChangePasswordConfirmation",
        mainRequestUrl: 'api/users/change_password/verify/',
        parentSuccessFunction: parentSuccessFunction
    }

    return (
        <ConfirmCodePage {...confirmCodePageRegisterProps}/>
    );
};

export default ChangePasswordConfirmation;
