import styled from 'styled-components';
import {Link} from "react-router-dom";



export const SimpleFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

export const OrderStatus = styled.div`
  font-weight: 600;
  margin-top: 1rem;
`

export const SimpleText = styled.div`
  padding: 0 1rem;
  text-align: center;
  font-size: 0.7rem;
`

export const LinkPayment = styled(Link)`
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: 700;
  border-left: 0.1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  text-decoration: underline;
  color: #2A4480;
`
