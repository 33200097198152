/*React*/
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

/*Created components*/
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {PageText} from "@core/components/form_slider_components/general_popup/page_components/page_additonal";
import {
    PageButton,
    PageButtons__Wrapper
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import addBrToString from "@core/functions/add_br_in_string";
import {setTextAndHeaderOkPopup} from "@src/current/main/redux_reducers/ok_popup_slice";



const OkPopup = () => {
    const dispatch = useDispatch();
    const okPopupText = useSelector(state => state.OkPopupSliceReducer.okPopupText);
    const okPopupHeaderText = useSelector(state => state.OkPopupSliceReducer.okPopupHeaderText);

    /**Ok popup**/
    const [isExistPopup, setIsExistPopup] = useState(false);
    const popupStates = {isExist: isExistPopup, setExist: setIsExistPopup};

    useEffect(()=>{
        if (okPopupText){
            setIsExistPopup(true)
        }
    },[okPopupText])

    const closeFunc = () => {
        dispatch(setTextAndHeaderOkPopup({text: null, header: null}));
        setIsExistPopup(false);
    }

    return (
        <GeneralPopup {...popupStates}>
            <PageWrapper>
                {okPopupText &&
                    <PageText headerText={okPopupHeaderText}>{addBrToString(okPopupText)}</PageText>
                }

                <PageButtons__Wrapper>
                    <PageButton onClick={closeFunc}>Ок</PageButton>
                </PageButtons__Wrapper>
            </PageWrapper>
        </GeneralPopup>
    );
};

export default OkPopup;
