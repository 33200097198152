/*React*/
import React, {useContext} from 'react';

/*Created components*/
import MotionDiv from "@core/components/motion_div/motion_div";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import DefaultReadyFullInput
    from "@core/components/form_slider_components/general_popup/input_components/default_ready_full_input";
import SliderContext from "@core/components/slider/context";
import OrderPopupContext from "@components/order_popup/contexts/context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import {slideUpDown} from "@core/components/form_slider_components/general_popup/motion_animations/animations";
import {AnimatePresence} from "framer-motion";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";
import PhoneInput from "@core/components/form_slider_components/general_popup/input_components/phone_input";



const ContactsFullPage = () => {
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const {formData, handleChange, setRedirectUrl} = useContext(OrderPopupContext);
    const {activePageName, setActivePageName, prevPageName} = useContext(PopupInsideContext);
    /*Extra*/
    let noChangeActivePages = ['ContactsFullPage'];

    const slideToPrevPage = () => {
        setActivePageName(prevPageName);
        sliderDispatch({
            type: 'SLIDE_LEFT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    const slideToWaitPaymentPage = () =>{
        setActivePageName('WaitPaymentPage');
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    }

    const successFunction = (successData) =>{
        localStorage.setItem('paymentId', successData.payment_id);
        localStorage.setItem('paymentUrl', successData.redirect_url);
        setRedirectUrl(successData.redirect_url)
        window.open(successData.redirect_url, '_blank');
        slideToWaitPaymentPage();
    }

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Данные для доставки</PageHeader>

                <InputsForm requestUrl={"api/orders/standard_order/"} formData={formData} formDataHandleChange={handleChange} successFunction={successFunction}>
                    <AnimatePresence>
                        {noChangeActivePages.includes(activePageName) && (
                            <MotionDiv initial={'usualInitial'}
                                       animate='animate'
                                       exit='exit'
                                       variants={slideUpDown()}>
                            <PhoneInput/>

                            <DefaultReadyFullInput headerText={'Ваше ФИО'}
                                                   placeholder={'Введите ваше ФИО'}
                                                   name={'fullname'}/>

                            <DefaultReadyFullInput headerText={'Ваша улица'}
                                                   placeholder={'Введите вашу улицу'}
                                                   name={'street'}/>

                            <DefaultReadyFullInput headerText={'Ваш дом/строение'}
                                                   placeholder={'Введите ваш дом/строение'}
                                                   name={'house'}/>

                            <DefaultReadyFullInput headerText={'Ваша квартира/офис'}
                                                   placeholder={'Введите вашу квартиру/офис'}
                                                   name={'apartment'}/>

                            <DefaultReadyFullInput headerText={'Ваш этаж'}
                                                   placeholder={'Введите ваш этаж'}
                                                   name={'floor'}/>
                            </MotionDiv>
                        )}
                    </AnimatePresence>

                    <PageButtons__Wrapper margin={'big'}>
                        <PageButton onClick={slideToPrevPage}>Назад</PageButton>
                        <PageButton>Далее</PageButton>
                    </PageButtons__Wrapper>
                </InputsForm>
            </PageWrapper>
        </MotionDiv>
    );
};

export default ContactsFullPage;
