export const slideUpDown = (height = 'auto') => ({
    noAnimInitial: { height: 'auto', opacity: 1 },
    usualInitial: { height: 0, opacity: 0 },
    animate: { height: height, opacity: 1 },
    exit: { height: 0, opacity: 0 },
    exitForWholePage: { height: 'auto', opacity: 0 },
    transition: { duration: 0.25 },
    transitionForWholePage: { when: "afterChildren" }
});

export const standardFadeOut = (animateOpacity = 1, transition = 1) => ({
    initial: { opacity: 0 },
    animate: { opacity: animateOpacity },
    exit: { opacity: 0 },
    transition: { duration: transition }
});
