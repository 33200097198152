/*React*/
import React, {useContext, useEffect, useState} from 'react';
import PropTypes from "prop-types";

/*Styles*/
import {AnimatePresence} from "framer-motion";
import {slideUpDown} from "@core/components/form_slider_components/general_popup/motion_animations/animations";

/*Created components*/
import SliderContext from "@core/components/slider/context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import MotionDiv from "@core/components/motion_div/motion_div";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import DefaultReadyFullInput
    from "@core/components/form_slider_components/general_popup/input_components/default_ready_full_input";
import useRequest from "@core/api/hooks/useRequest";
import TimerBlock from "@core/components/form_slider_components/general_popup/additional_form_components/timer_block/timer_block";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";
import useFormData from "@core/hooks/useFormData";
import {
    checkExpiryDate
} from "@core/components/form_slider_components/general_popup/additional_form_components/timer_block/processing_date_from_request";



const ConfirmCodePage = ({pageHeader, visibleAllFormPages, fromPage, forPage, mainRequestUrl, parentSuccessFunction}) => {
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const {activePageName,
           setActivePageName,
           userContact,
           codeExpiryDate,
           setCodeExpiryDate,} = useContext(PopupInsideContext);

    const slidePrev = () => {
        setActivePageName(fromPage);
        sliderDispatch({
            type: 'SLIDE_LEFT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    /**API**/
    const {formData, handleChange} = useFormData({
        contact_info_type: userContact.contact_info_type,
        contact_info: userContact.contact_info,
        confirm_code: '',
    });

    const successFunction = (successData)=>{
        parentSuccessFunction(successData);
    }

    /**Data logic**/
    /**API - Get new code**/
    const {executeRequest, errors, successData} = useRequest();
    const [standardTextTooltip, setStandardTextTooltip] = useState("")

    const getNewCodeAndStartTimer = async (e) => {
        e.preventDefault();
        await executeRequest("post","api/new_code/", {
            "contact_info_type": userContact.contact_info_type,
            "contact_info": userContact.contact_info
        })
    };

    useEffect(() => {
        if (successData) {
            setStandardTextTooltip(successData?.message)
            setCodeExpiryDate(successData?.expiry_time)
        }
    }, [successData])

    useEffect(()=>{
        if (errors){
            setStandardTextTooltip(errors?.error)
        }
    },[errors])

    useEffect(()=>{
        if (activePageName === forPage){
            if (userContact.contact_info_type === "PHONE"){
                setStandardTextTooltip(`На ваш телефон было отправлено сообщение с кодом,\n введите этот код для подтверждения действия на сайте.`)
            }
            else {
                setStandardTextTooltip(`На вашу почту было отправлено сообщение с кодом,\n введите этот код для подтверждения действия на сайте.`)
            }
        }
    },[activePageName])

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>{pageHeader}</PageHeader>

                <InputsForm requestUrl={mainRequestUrl} formData={formData} formDataHandleChange={handleChange} successFunction={successFunction}>
                    <DefaultReadyFullInput headerText={'Код подтверждения'}
                                           placeholder={'Введите код'}
                                           name={'confirm_code'}
                                           isExplanation={true}
                                           tooltipText={standardTextTooltip}
                                           forPage={forPage}/>

                    <AnimatePresence>
                        {visibleAllFormPages.includes(activePageName) && (
                            <MotionDiv initial={'usualInitial'}
                                       animate='animate'
                                       exit='exit'
                                       variants={slideUpDown()}>

                                <TimerBlock expiryDate={codeExpiryDate} onClick={getNewCodeAndStartTimer}/>
                            </MotionDiv>
                        )}
                    </AnimatePresence>

                    <PageButtons__Wrapper margin={'big'}>
                        <PageButton onClick={slidePrev}>Назад</PageButton>
                        <PageButton>Далее</PageButton>
                    </PageButtons__Wrapper>
                </InputsForm>
            </PageWrapper>
        </MotionDiv>
    );
};

export default ConfirmCodePage;