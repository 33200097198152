/*React*/
import React from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/styles';

/*Для использования родительский блок должен быть в position:relative*/

const RequestLoaderBlock = () => {
    return (
        <S.RequestLoaderBlock initial={{opacity: 0}}
                              animate={{opacity: 1}}
                              exit={{opacity: 0}}
                              transition={{duration: 0.3}}>
            <S.RequestLoaderBlock__Loader />
        </S.RequestLoaderBlock>
    );
};

export default RequestLoaderBlock;
