/*React*/
import { configureStore } from '@reduxjs/toolkit'

/*Slices*/
import notifyMessageSlice from "@src/current/main/redux_reducers/notify_message_slice"
import okPopupSlice from "@src/current/main/redux_reducers/ok_popup_slice"
import yesNoPopupSlice from "@src/current/main/redux_reducers/yes_no_popup_slice"



const store = configureStore({
    reducer: {
        NotifyMessageReducer: notifyMessageSlice,
        OkPopupSliceReducer: okPopupSlice,
        YesNoPopupReducer: yesNoPopupSlice,
    },
})

export default store;