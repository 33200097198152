/*React*/
import React from 'react';
import {useRouteError} from "react-router-dom";

/*Styles*/
import * as S from './styles/general_error_page_styles';



const GeneralErrorPage = () => {
    const error = useRouteError();

    return (
        <S.GeneralErrorPage>
            <S.GeneralErrorPage__MainText>Oops...</S.GeneralErrorPage__MainText>
            <S.GeneralErrorPage__Description>Sorry, an unexpected error has occurred.</S.GeneralErrorPage__Description>
            <S.GeneralErrorPage__ErrorTypeText>{error.statusText || error.message}</S.GeneralErrorPage__ErrorTypeText>
        </S.GeneralErrorPage>
    );
};

export default GeneralErrorPage;