import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/phone_email_input';

/*Created components*/
import InputHeader from "@core/components/form_slider_components/general_popup/input_components/input_header/input_header";
import InputWrapper from "@core/components/form_slider_components/general_popup/input_components/input_wrapper/input_wrapper";
import inputsFormContext
    from "@core/components/form_slider_components/general_popup/input_components/page_form/context/InputsFormContext";






const PhoneEmailInput = ({name ="contact_info", defaultActive = 'PHONE', isExplanation = false, tooltipText, forPage}) => {
    const {formDataHandleChange} = useContext(inputsFormContext)
    const explanationRef = useRef(null);

    const [isActiveInputFirst, setIsActiveInputFirst] = useState(defaultActive === 'PHONE');

    const [phoneContact, setPhoneContact] = useState("");
    const [emailContact, setEmailContact] = useState("");

    /*IMask settings*/
    const contactsInputRef = useRef(null);

    const phoneMaskReg = "+7 (000)-000-00-00";
    const [phoneLazyStatus, setPhoneLazyStatus] = useState(true)

    const phonePlaceholder = "Введите телефона";
    const emailPlaceholder = "Введите электронную почту";

    const handlerChangeInputInactive = (e) => {
        if (e.currentTarget.classList.contains('inactive')) {
            setIsActiveInputFirst(prevIsActiveInputFirst =>{
                if (prevIsActiveInputFirst) {
                    formDataHandleChange(name, emailContact);
                } else {
                    formDataHandleChange(name, phoneContact);
                }
                return !prevIsActiveInputFirst;
            });
        }
    };

    /**Type change**/
    useEffect(()=>{
        if (isActiveInputFirst){
            formDataHandleChange("contact_info_type", 'PHONE');
        }
        else {
            formDataHandleChange("contact_info_type", 'EMAIL');
        }
    },[isActiveInputFirst])

    const handlerPhone = (value) =>{
        setPhoneContact(value);
        formDataHandleChange(name, value)
    }

    const handlerEmail = (e) =>{
        setEmailContact(e.target.value);
        formDataHandleChange(name, e.target.value)
    }

    /*Additional for phone input*/
    const handlerFocusPhoneInput = () =>{
        setPhoneLazyStatus(false);
    }

    const handlerBlurPhoneInput = () =>{
        if (phoneContact === '+7 (___)-___-__-__'){
            setPhoneContact("")
            setPhoneLazyStatus(true);
        }
    }

    return (
        <InputWrapper explanationRef={explanationRef} tooltipStandardText={tooltipText} forPage={forPage} tooltipName={name}>
            <InputHeader isExplanation={isExplanation} explanationRef={explanationRef}>
                <S.PhoneEmailInput__PhoneText
                    className={!isActiveInputFirst && 'inactive'}
                    onClick={handlerChangeInputInactive}>
                    Телефон
                </S.PhoneEmailInput__PhoneText>

                <S.PhoneEmailInput__PhoneEmailBetween>|</S.PhoneEmailInput__PhoneEmailBetween>

                <S.PhoneEmailInput__EmailText
                    className={isActiveInputFirst && 'inactive'}
                    onClick={handlerChangeInputInactive}>
                    Почта
                </S.PhoneEmailInput__EmailText>
            </InputHeader>

            <div>
                {isActiveInputFirst
                    ?(<S.PhoneInput__MainInput
                        mask={phoneMaskReg}
                        lazy={phoneLazyStatus}
                        unmask={false}

                        ref={contactsInputRef}
                        value={phoneContact}

                        /*Instead onChange - lib requirement*/
                        onAccept={(value) => {handlerPhone(value)}}
                        onFocus={handlerFocusPhoneInput}
                        onBlur={handlerBlurPhoneInput}
                        placeholder={phonePlaceholder} />)

                    :(<S.EmailInput__MainInput
                        value={emailContact}
                        onChange={handlerEmail}
                        placeholder={emailPlaceholder}
                    />)}
            </div>
        </InputWrapper>
    );
};

PhoneEmailInput.propTypes = {
    defaultActive: PropTypes.string,
    onlyOneType: PropTypes.bool,
    isExplanation: PropTypes.bool,
    tooltipText: PropTypes.string,
    tooltipErrorText: PropTypes.string,
    forPage: PropTypes.string,
};

export default PhoneEmailInput;