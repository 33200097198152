import {useLocation, useNavigate} from 'react-router-dom';
import {handlerAnchorClick} from "@core/functions/anchors_scrolling";


const useScrollCheck = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (e) => {
        if (location.pathname === '/') {
            handlerAnchorClick(e);
        } else {
            navigate('/');
        }
    };
};

export default useScrollCheck;