/**
    return time for timer in seconds
**/

export const fromDjangoToJSTime = (date) =>{
    return new Date(date)
}

export const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const checkExpiryDate = (expiryDate) =>{
    if (fromDjangoToJSTime(expiryDate) > (Date.now() + 1000)) return true
}