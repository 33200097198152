import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styling/styles';
import {AnimatePresence} from 'framer-motion';
import {motion} from 'framer-motion';

/*Core*/
import {handlerAnchorClick} from '@core/functions/anchors_scrolling';


const ScrollTopButton = () => {
    /**State for tracking appering**/
    const [isApper, setIsApper] = useState(false);
    const offsetAppering = 350;

    useEffect(() => {
        function AppearScrollTopButton() {
            const getTop = () => window.pageYOffset;
            if (getTop() > offsetAppering) {
                setIsApper(true);
            } else {
                setIsApper(false);
            }
        }

        window.addEventListener('scroll', AppearScrollTopButton);
    }, []);


    return (
        <AnimatePresence>
            {isApper && (
                <motion.div initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}>
                    <S.Scroll__Wrapper href="#top" onClick={handlerAnchorClick}>
                        <S.Scroll__Icon />
                    </S.Scroll__Wrapper>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ScrollTopButton;


