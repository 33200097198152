/*React*/
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

/*Created components*/
import {
    fromDjangoToJSTime,
    formatTime,
    checkExpiryDate
} from "@core/components/form_slider_components/general_popup/additional_form_components/timer_block/processing_date_from_request";

/*Styles*/
import * as S from './styles/styles';



const TimerBlock = ({expiryDate, onClick}) => {
    const [isActiveTimer, setIsActiveTimer] = useState(false);
    const [expiryTime, setExpiryTime] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);
    let setIntervalRef = useRef(null);

    useEffect(() => {
        if (checkExpiryDate(expiryDate)){
            setIsActiveTimer(true);
            setExpiryTime(fromDjangoToJSTime(expiryDate))
        }
    }, [expiryDate]);

    useEffect(() => {
        if (isActiveTimer){
            setIntervalRef = setInterval(() => {
                const now = Date.now();
                const remainingTime = expiryTime - now;
                if (remainingTime > 1000) {
                    setRemainingTime(remainingTime);
                } else {
                    clearInterval(setIntervalRef)
                    resetTimer();
                }
            }, 1000);
        }
        return () => clearInterval(setIntervalRef);
    }, [isActiveTimer]);

    const resetTimer = () => {
        setRemainingTime(0);
        setIsActiveTimer(false);
        setIntervalRef = null
    };

    return (
        <S.Timer__Wrapper $isActiveTimer={isActiveTimer} onClick={onClick}>
            <S.Timer__Text>
                {isActiveTimer ? ('Вызвать повторный код: ' + formatTime(remainingTime)) : 'Вызвать повторный код'}
            </S.Timer__Text>
        </S.Timer__Wrapper>
    );
};

TimerBlock.propTypes = {
    expiryDate: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

export default TimerBlock;