/*React*/
import React, {useContext} from 'react';

/*Styles*/
import * as S from './styles/page_components';
import {AnimatePresence} from "framer-motion";
import inputsFormContext
    from "@core/components/form_slider_components/general_popup/input_components/page_form/context/InputsFormContext";
import {request} from "axios";



/**Page components**/
/*Header*/
export const PageHeader = ({children}) => {
    return (
        <S.PageHeader>
            <S.PageHeader__Text>{children}</S.PageHeader__Text>
        </S.PageHeader>
    );
};


/*Buttons wrapper*/
export const PageButtons__Wrapper = ({children, margin}) => {
    return (
        <S.PageButtons__Wrapper $margin={margin}>
            {children}
        </S.PageButtons__Wrapper>
    );
};


/*Button*/
export const PageButton = ({children, type="request", externalRef=null, onClick, customLoading = false}) => {
    /*OnClick and loading process*/
    let resultClickHandler;
    let resultLoading;

    if(!onClick) {
        const {handleSendData, loading} = useContext(inputsFormContext);
        resultClickHandler = handleSendData;
        resultLoading = loading;
    }
    else{
        resultClickHandler = onClick;
        resultLoading = customLoading;
    }

    return (
        <S.PageButton ref={externalRef} onClick={resultClickHandler}>
            <AnimatePresence>
                {resultLoading && (
                    <S.PageButton__LoaderBlock initial={{opacity: 0}}
                                                       animate={{opacity: 1}}
                                                       exit={{opacity: 0}}
                                                       transition={{duration: 0.3}}>
                        <S.PageButton__LoaderIcon />
                    </S.PageButton__LoaderBlock>
                )}
            < /AnimatePresence>
            <S.PageButton__Text $loading={resultLoading}>
                {children}
            </S.PageButton__Text>
        </S.PageButton>
    );
};



