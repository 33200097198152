import styled from "styled-components";
import EyeIcons from "@core/components/form_slider_components/simple_form_slider_auth/values/eye";



/*Header*/
export const PasswordInput__Header = styled.div`
  width: 100%;
  font-size: 0.85em;
  margin-left: 0.18em;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: self-end;
`;

export const PasswordInput__PasswordText = styled.span`
`;


/*Change password text*/
export const PasswordInput__ChangePassword = styled.span`
  cursor: pointer;
  font-size: 0.7em;
  font-weight: 500;
  margin-right: 0.35em;
  transition: all ease 0.4s;

  &:hover {
    color: var(--STANDARD)
  }
`;


/*Input*/
export const PasswordInput__InputWrapper = styled.div`
  position: relative;
`;

export const PasswordInput__ImgPassword = styled.div`
  cursor: pointer;
  opacity: 80%;
  position: absolute;
  top: 0.6em;
  right: 0.4em;
  width: 1em;
  height: 1em;
  background: ${props => props.$openEye ? `url(${EyeIcons.OpenEye})` : `url(${EyeIcons.CloseEye})`} 0 0 / 100% no-repeat;
`;



