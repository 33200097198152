/*React*/
import React, {Children, useEffect, useReducer, useRef} from 'react';
import PropTypes from 'prop-types';
import reducer, {initialStates} from '@core/components/slider/reducer';

/*Created components*/
import {SliderContext} from '@core/components/slider/context';
import Slide from '@core/components/slider/slide';

/*Styles*/
import * as S from './styling/styles';



const SliderComponent = ({
                     children,
                     onArrows = false,
                     onSwitcher = false,
                     infinite = false,
                     dragging = false,
                     slickPercent = 20,
                     autoPlay = false,
                     closeStatus = true,
                     }) => {


    const [state, dispatch] = useReducer(reducer, initialStates);

    const childrenElements = children;
    const childrenCount = Children.count(childrenElements);

    const windowOverflow = useRef(null);
    const draggableRef = useRef(null);
    const offsetX = useRef(0);

    /**Initial**/
    useEffect(() => {
        if (infinite) {
            dispatch({
                type: 'SET_SLIDES_INFINITY',
                payload: {childrenElements: childrenElements},
            });
        } else {
            dispatch({
                type: 'SET_SLIDES_STANDARD',
                payload: {childrenElements: childrenElements},
            });
        }
    }, [children]);

    /*Initial width and slick in px*/
    useEffect(() => {
        const handlerResize = () => {
            let windowWidth = windowOverflow.current.offsetWidth;
            dispatch({
                type: 'RESIZE_SLIDER',
                payload: {windowWidth: windowWidth},
            });
            if (dragging) {
                dispatch({
                    type: 'SET_SLICK_IN_PX',
                    payload: {
                        windowWidth: windowWidth,
                        slickPercent: slickPercent,
                    },
                });
            }
        };

        handlerResize();
        window.addEventListener('resize', handlerResize);

        return () => {
            window.removeEventListener('resize', handlerResize);
        };
    }, []);

    /*Control offset*/
    useEffect(() => {
        dispatch({type: 'SET_SLIDER_POSITION'});
    }, [state.sliderPosition, state.width]);


    /**Main infinity function**/
    useEffect(() => {
        if (!infinite) return;

        if (state.sliderPosition === 0) {
            setTimeout(() => {
                dispatch({type: 'INFINITY_END', payload: {childrenCount: childrenCount}});
            }, state.transition);
        } else if (state.sliderPosition === (childrenCount + state.clonesCount.tail)) {
            setTimeout(() => {
                dispatch({type: 'INFINITY_START'});
            }, state.transition);
        }
    }, [state.sliderPosition]);


    /**Do slide**/
    const doSlideRight = () => {
        dispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                transition: 500,
                blockDisplay: infinite,
                /*I may delete this*/
                childrenCount: childrenCount,
            },
        });
    };

    const doSlideLeft = () => {
        dispatch({
            type: 'SLIDE_LEFT',
            payload: {
                transition: 500,
                blockDisplay: infinite,
                childrenCount: childrenCount,
            },
        });
    };


    /**Addition**/
    /*Display blocker*/
    useEffect(() => {
        if (state.blockerDisplay === 'none') return;
        setTimeout(() => {
            dispatch({type: 'OFF_DISPLAY_BLOCKER'});
        }, 500);
    }, [state.blockerDisplay]);

    useEffect(() => {
        if (infinite) return;

        let hideMarkers = {left: true, right: true};

        if ((state.sliderPosition) === 0) {
            hideMarkers = {left: false, right: true};
        } else if ((state.sliderPosition + 1) === childrenCount) {
            hideMarkers = {left: true, right: false};
        }

        dispatch({
            type: 'HIDE_ARROWS',
            payload: {
                hideMarkers: hideMarkers,
            },
        });
    }, [state.sliderPosition]);


    /**Switcher**/
    /*Clicking  on switcher*/
    const handleSwitchSlide = (numSlideKey) => {
        let newTransition = 500;
        if (Math.abs(numSlideKey - state.indexSwitch) > 1) {
            newTransition = 800;
        }
        dispatch({
            type: 'SWITCH_SLIDE',
            payload: {transition: newTransition, slideKey: numSlideKey},
        });
    };

    /*Initialing quantity of switcher buttons*/
    useEffect(() => {
        dispatch({
            type: 'RELOAD_SWITCHERS',
            payload: {
                childrenCount: childrenCount,
                switchFunc: handleSwitchSlide,
            },
        });
    }, [state.indexSwitch]);

    /*Switchers color-animations*/
    useEffect(() => {
        let newActiveIndexSwitch = undefined;
        let countSwitchers = Children.count(state.switchers);

        if (state.indexSwitch === 0) {
            newActiveIndexSwitch = countSwitchers;
        } else if (state.indexSwitch > childrenCount) {
            newActiveIndexSwitch = 1;
        }

        dispatch({
            type: 'SET_SWITCHER_COLOR',
            payload: {
                newActiveIndexSwitch: newActiveIndexSwitch,
            },
        });
    }, [state.indexSwitch]);


    /**Dragging**/
    const handleMouseDown = (event) => {
        if (!dragging) return;

        dispatch({
            type: 'START_DRAGGING',
        });
        offsetX.current = event.clientX - draggableRef.current.getBoundingClientRect().left;
    };


    const handleMouseMove = (event) => {
        if (!state.clickForDragging) return;
        dispatch({
            type: 'DRAGGING',
            payload: {
                newOffset: event.clientX - offsetX.current,
            },
        });
    };


    const doSlideOnPagePosition = () => {
        dispatch({type: 'RETURN_PREV_POSITION'});
    };


    const handleMouseUp = () => {
        if (state.moveForDragging) {
            if (state.draggingOffsetDifference > 0 && state.draggingOffsetDifference > state.slickPX) {
                doSlideRight();
            } else if (state.draggingOffsetDifference < 0 && Math.abs(state.draggingOffsetDifference) > state.slickPX) {
                doSlideLeft();
            } else {
                doSlideOnPagePosition();
            }
        }
        dispatch({type: 'END_DRAGGING'});
    };

    const handleMouseOut = () => {
        if (!dragging) return;
        dispatch({type: 'OUT_DRAGGING'});
        doSlideOnPagePosition();
    };


    /**Auto playing**/
    useEffect(() => {
        if (!autoPlay || state.clickForDragging) return;

        const timer = setTimeout(() => {
            doSlideRight();
        }, state.TimeToSlideMS);

        return () => clearTimeout(timer);
    }, [autoPlay, state.clickForDragging, state.sliderPosition]);


    /**For slider forms*/
    useEffect(()=>{
        if (closeStatus){
            dispatch({type: 'SLIDE_ON_START_PAGE'});
        }
    },[closeStatus])



    return (
        <SliderContext.Provider value={{infinite, state, dispatch, childrenElements, childrenCount}}>
            <S.Slider__MainContainer>
                <S.Slider__WindowOverflow ref={windowOverflow}>
                    {onArrows &&
                        <>
                            <S.Slider__ArrowBlock_SideLeft $arrowIsVisible={state.arrowIsVisible.left}
                                                           onClick={doSlideLeft} />
                            <S.Slider__ArrowBlock_SideRight $arrowIsVisible={state.arrowIsVisible.right}
                                                            onClick={doSlideRight} />
                        </>
                    }

                    {onSwitcher &&
                        <S.Slider__SwitcherMainBlock>
                            <S.Slider__SwitcherWrapperInside>
                                {state.switchers}
                            </S.Slider__SwitcherWrapperInside>
                        </S.Slider__SwitcherMainBlock>
                    }

                    <S.Slider__InteractBlocker $display={state.blockerDisplay}></S.Slider__InteractBlocker>

                    <S.Slider__SlidersContainer $offset={state.offset}
                                                $transition={state.transition}
                                                ref={draggableRef}
                                                onMouseDown={handleMouseDown}
                                                onMouseMove={handleMouseMove}
                                                onMouseUp={handleMouseUp}
                                                onMouseOut={handleMouseOut}>
                        {state.slides}
                    </S.Slider__SlidersContainer>
                </S.Slider__WindowOverflow>
            </S.Slider__MainContainer>
        </SliderContext.Provider>
    );
};

SliderComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.node]),
    onArrows: PropTypes.bool,
    onSwitcher: PropTypes.bool,
    infinite: PropTypes.bool,
    dragging: PropTypes.bool,
    slickPercent: PropTypes.number,
    autoPlay: PropTypes.bool,
    closeStatus: PropTypes.bool,
};

SliderComponent.Slide = Slide;

export default SliderComponent;