import React, {useContext, useEffect, useRef, useState} from 'react';

/*Styles*/
import {AnimatePresence} from "framer-motion";
import {slideUpDown} from "@core/components/form_slider_components/general_popup/motion_animations/animations";
import {addNotification} from "@src/current/main/redux_reducers/notify_message_slice";

/*Created components*/
import UserDataContext from "@pages/profile_pages/profile_settings_page/user_data_context";
import PopupExternalContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import SliderContext from "@core/components/slider/context";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import DefaultReadyFullInput from "@core/components/form_slider_components/general_popup/input_components/default_ready_full_input";
import {useDispatch} from "react-redux";
import useFormData from "@core/hooks/useFormData";
import {setTextAndHeaderOkPopup} from "@src/current/main/redux_reducers/ok_popup_slice";
import MotionDiv from "@core/components/motion_div/motion_div";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";



const UpdateForm = () => {
    const {closePopupFunc} = useContext(PopupExternalContext);
    const {activePageName, setActivePageName, definedPages, setDefinedPages, setUserContact, setCodeExpiryDate} = useContext(PopupInsideContext);
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const dispatch = useDispatch();
    const {userData, setUserData, setUserDataForConfirm} = useContext(UserDataContext);
    /*Extra*/
    const [isFirstOpen, setIsFirstOpen] = useState(true);

    useEffect(()=>{
        setActivePageName('UpdateForm')
        setDefinedPages({...definedPages, pageOne: 'UpdateForm'})
    },[])

    const slideToCodeConfirmation = () => {
        setActivePageName('UpdateConfirmation')
        setDefinedPages({...definedPages, pageTwo: 'UpdateConfirmation'})
        setIsFirstOpen(false);
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    /**API**/
    const {formData, handleChange} = useFormData({
        phone: userData?.phone || "",
        email: userData?.email || "",
        name: userData?.name || "",
        surname: userData?.surname || "",
        patronymic: userData?.patronymic || "",
    });

    const successFunction = (successData) =>{
        if (successData?.no_changes){
            dispatch(addNotification("You have not made any changes."));
            closePopupFunc();
        }
        if (successData?.success){
            closePopupFunc();
            setUserData(formData)
            dispatch(setTextAndHeaderOkPopup({text: "Вы успешно изменили данные!", header: ""}))
        }
        if (successData?.send_code || successData?.already_sent){
            setUserDataForConfirm(formData)
            setUserContact({
                contact_info: successData.contact_info_type === "PHONE" ? formData.phone : formData.email,
                contact_info_type: successData.contact_info_type
            })
            setCodeExpiryDate(successData.expiry_time)
            slideToCodeConfirmation()
        }
    }

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Изменение контактных данных</PageHeader>

                <InputsForm requestUrl={"api/users/update_data/"} formData={formData} formDataHandleChange={handleChange} successFunction={successFunction}>
                    <DefaultReadyFullInput headerText={'Ваше имя'}
                                           placeholder={'Введите ваше имя'}
                                           name={'name'}/>

                    <AnimatePresence>
                        {activePageName === 'UpdateForm' && (
                            <MotionDiv initial={isFirstOpen ? 'noAnimInitial' : 'usualInitial'}
                                       animate='animate'
                                       exit='exit'
                                       variants={slideUpDown()}>

                                <DefaultReadyFullInput headerText={'Ваша фамилия'}
                                                       placeholder={'Введите вашу фамилию'}
                                                       name={'surname'}/>

                                <DefaultReadyFullInput headerText={'Ваше отчество'}
                                                       placeholder={'Введите ваше отчество'}
                                                       name={'patronymic'}/>

                                <DefaultReadyFullInput headerText={'Ваш телефон'}
                                                       placeholder={'Введите ваше имя'}
                                                       name={'phone'}/>

                                <DefaultReadyFullInput headerText={'Ваша почта'}
                                                       placeholder={'Введите вашу почту'}
                                                       name={'email'}/>
                            </MotionDiv>
                        )}
                    </AnimatePresence>

                    <PageButtons__Wrapper margin={'big'}>
                        <PageButton>Далее</PageButton>
                    </PageButtons__Wrapper>
                </InputsForm>
            </PageWrapper>
        </MotionDiv>
    );
};

export default UpdateForm;