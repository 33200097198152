import styled from 'styled-components';
import {motion} from "framer-motion";



export const NotifyMessage__MessageBlock = styled(motion.div)`
  position: fixed;
  left: 0;
  bottom: 5rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.5rem;
  overflow: hidden;
  transition: 0.25s linear;
`

export const NotifyMessage = styled(motion.div)`
  background: white;
  box-sizing: border-box;
  width: fit-content;
`

export const NotifyMessage__Text = styled.div`
  margin: 0.3rem 1rem 0.3rem 1rem;
`
