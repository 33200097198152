/*Styles*/
import styled from 'styled-components';


export const ChainsModels__Wrapper = styled.div`
  position: absolute;
  top: 3.125rem;
  width: 100%;
  height: 80%;
  overflow: hidden;
  opacity: 100;
  
  
  /*175%*/
  @media (max-width: 1200px) {
    top: 6rem;
  }

  /*200%*/
  @media (max-width: 1000px) {
    top: 7.5rem;
  }

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    opacity: 0;
  }
`

const __DropInfo__ChainCanvas = styled.canvas`
  position: absolute;
  width: 38.5rem;
  height: 37rem;
  z-index: 2;
  transition: 0.25s linear;
  opacity: ${props => props.$isLoaded  ? '100%' : '0'};

  /*150%*/
  @media (max-width: 1400px) {
    width: 35rem;
    height: 32.5rem;
  }

  /*175%*/
  @media (max-width: 1200px) {
    width: 32rem;
    height: 29.5rem;
  }

  /*200%*/
  @media (max-width: 1000px) {
    width: 25.5rem;
    height: 22.5rem;
  }

  /*400%*/
  @media (max-width: 600px) {
    width: 20.5rem;
    height: 17.5rem;
  }

  /*500%*/
  @media (max-width: 400px) {
    width: 19.5rem;
    height: 16.5rem;
  }
`

export const ChainsModels__Canvas_SideLeft = styled(__DropInfo__ChainCanvas)`
  left: -4.15rem;
`

export const ChainsModels__Canvas_SideRight = styled(__DropInfo__ChainCanvas)`
  right: -4.15rem;
`



