import React, {useContext, useState} from 'react';

/*Styles*/
import * as S from './styles/password_input';

/*Created components*/
import {
    InputWrapper
} from "@core/components/form_slider_components/general_popup/input_components/input_wrapper/input_wrapper";
import Input from "@core/components/form_slider_components/general_popup/input_components/input/input";
import inputsFormContext
    from "@core/components/form_slider_components/general_popup/input_components/page_form/context/InputsFormContext";



const PasswordInput = ({name = "password", isForgetPasswordButton = false, slideToChangePasswordFunc}) => {
    const {formData, formDataHandleChange} = useContext(inputsFormContext)

    /**Password input show-hide**/
    const [isPasswordShow, setItPasswordShow] = useState(true);
    const handlerPasswordShowToggle = () => {
        setItPasswordShow(!isPasswordShow);
    };

    return (
        <InputWrapper tooltipName={name}>
            <S.PasswordInput__Header>
                <S.PasswordInput__PasswordText>Пароль</S.PasswordInput__PasswordText>
                {isForgetPasswordButton &&
                    <S.PasswordInput__ChangePassword onClick={slideToChangePasswordFunc}>
                        Забыли пароль?
                    </S.PasswordInput__ChangePassword>
                }
            </S.PasswordInput__Header>

            <S.PasswordInput__InputWrapper>
                <Input type={isPasswordShow ? 'password' : 'text'}
                       name={name}
                       placeholder='Введите пароль'
                       value={formData[name]}
                       dataChange={formDataHandleChange}/>
                <S.PasswordInput__ImgPassword onClick={handlerPasswordShowToggle} $openEye={isPasswordShow}></S.PasswordInput__ImgPassword>
            </S.PasswordInput__InputWrapper>
        </InputWrapper>
    );
};

export default PasswordInput;