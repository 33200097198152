/*React*/
import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/styles.js';

/*Created components*/
import {useLoaderData} from "react-router-dom";



const DropInfo = () => {
    const {products: productsFirst} = useLoaderData();

    const [totalCount, setTotalCount] = useState(null);

    useEffect(()=>{
        if (productsFirst && productsFirst.data){
            setTotalCount(productsFirst.data.total_count)
        }
    },[productsFirst])

    return (
        <S.DropInfo>
            <S.DropInfo__InfoBlockWrapper>
                <S.DropInfo__InfoBlockInsideWrapper>
                    <S.DropInfo__AdditionalInfo_SideLeft>
                    </S.DropInfo__AdditionalInfo_SideLeft>

                    <S.DropInfo__MainInfo>
                        <S.DropInfo__MainInfoTextBlock>
                            <S.DropInfo__MainInfoAdditionalBlock>
                                <S.DropInfo__MainInfoAdditionalText_SideLeft>DROP: 1.0</S.DropInfo__MainInfoAdditionalText_SideLeft>
                                <S.DropInfo__MainInfoAdditionalText_SideRight>ITEMS: {totalCount}</S.DropInfo__MainInfoAdditionalText_SideRight>
                            </S.DropInfo__MainInfoAdditionalBlock>

                            <S.DropInfo__MainInfoBigText>FIRST<br/>RELEASE</S.DropInfo__MainInfoBigText>
                            <S.DropInfo__MainInfoSmallText>FIRST BLOOD, BUT NOT THE LAST</S.DropInfo__MainInfoSmallText>
                        </S.DropInfo__MainInfoTextBlock>
                    </S.DropInfo__MainInfo>
                    <S.DropInfo__AdditionalInfo_SideRight>
                    </S.DropInfo__AdditionalInfo_SideRight>
                </S.DropInfo__InfoBlockInsideWrapper>
            </S.DropInfo__InfoBlockWrapper>
        </S.DropInfo>
    );
};

export default DropInfo;
