/*React*/
import React from "react";



const addBrToString = (text) => {
    if (text){
        const parts = text.split('\n');

        return parts.map((item, index) => (
            <React.Fragment key={index}>
                {item}
                {index < parts.length - 1 && <br />}
            </React.Fragment>
        ));
    }
    else return
};

export default addBrToString;