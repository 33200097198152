/*React*/
import React, {useContext, useEffect} from 'react';

/*Created components*/
import {useDispatch} from "react-redux";
import {
    PopupExternalContext
} from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import {setTextAndHeaderOkPopup} from "@src/current/main/redux_reducers/ok_popup_slice";
import UserDataContext from "@pages/profile_pages/profile_settings_page/user_data_context";
import ConfirmCodePage
    from "@core/components/form_slider_components/general_popup/full_pages/confirm_code_page/confirm_code_page";



const UpdateConfirmation = () => {
    const dispatch = useDispatch();
    const {closePopupFunc} = useContext(PopupExternalContext);
    const {setUserData, userDataForConfirm} = useContext(UserDataContext);

    /**API**/
    const parentSuccessFunction = () =>{
        closePopupFunc();
        setUserData(userDataForConfirm)
        dispatch(setTextAndHeaderOkPopup({text: "Данные успешно изменены!", header: ""}))
    }

    const confirmCodePageUpdateProps = {
        pageHeader: "Подтверждение изменений",
        visibleAllFormPages: ['UpdateForm', 'UpdateConfirmation'],
        fromPage: 'UpdateForm',
        forPage: 'UpdateConfirmation',
        mainRequestUrl: "api/users/update_data/confirm/",
        parentSuccessFunction: parentSuccessFunction
    }

    return (
        <ConfirmCodePage {...confirmCodePageUpdateProps}/>
    );
};

export default UpdateConfirmation;
