/*React*/
import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/styles.js';
import {AnimatePresence} from 'framer-motion';
import {removeFlickering} from "@current/main/functions/3D/flickeringRemove";



const Loader = () => {
    removeFlickering();

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const handlePageLoad = () => {
            setTimeout(()=>{
                setIsLoading(false)
            },500)
        };

        if (document.readyState === 'complete') {
            handlePageLoad();
        } else {
            window.addEventListener('load', handlePageLoad);
        }

        return () => {
            window.removeEventListener('load', handlePageLoad);
        };
    }, []);


    return (
        <>
            <AnimatePresence>
            {isLoading && (
                    <S.Loader__Wrapper
                        initial={{opacity: 1}}
                        exit={{opacity: 0}}>
                            <S.Loader__MainImages></S.Loader__MainImages>
                    </S.Loader__Wrapper>
            )}
            </AnimatePresence>
        </>
    );
};

export default Loader;


