/*React*/
import React from 'react';
import {Outlet} from "react-router-dom";

/*Created components*/
import Header from "@components/header/header";
import Contacts from "@components/contacts/contacts";



const BaseLayout = () => {
    return (
        <>
            <Header/>
            <Outlet />
            <Contacts />
        </>
    );
};

export default BaseLayout;