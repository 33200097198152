/*React*/
import React from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/hint_marker';



const HintMarker = ({explanationRef}) => {
    return (
        <S.HintMarker__Wrapper ref={explanationRef}>
            <S.HintMarker__InsideSign>?</S.HintMarker__InsideSign>
        </S.HintMarker__Wrapper>
    );
};

HintMarker.propTypes = {
    explanationRef: PropTypes.shape({ current: PropTypes.instanceOf(Element)})
};

export default HintMarker;