/*Styles*/
import styled from 'styled-components';
import {SelectNoneWithoutPointer} from "@core/css/css_snippets";


export const ProfileSettings__Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.7rem 2rem;
  display: flex;
  justify-content: flex-start;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    padding: 0.7rem 0.8rem;
  }
`

export const ProfileSettings__Column = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: white;
  letter-spacing: 0.1rem;
`

export const ProfileSettings__InsideBlock = styled.div`
    margin: 0 0 1rem 0;
`

export const ProfileSettings__Header = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 1000;
  font-size: 0.95rem;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 0.85rem;
  }
`

export const ProfileSettings__Text = styled.div`
  font-family: Montserrat,serif;
  font-size: 0.85rem;
  margin-bottom: ${props => (props.$margin ? `${props.$margin}rem` : 'auto')};

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 0.75rem;
    white-space: nowrap;
  }
`

export const ProfileSettings__ButtonChange = styled.div`
  font-size: 0.7rem;
  width: 10rem;
  padding: 0.2rem 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s linear;
  cursor: pointer;
  ${SelectNoneWithoutPointer};
  margin-bottom: ${props => (props.$margin ? `${props.$margin}rem` : 'auto')};
  background: var(--MAIN_GREY);
  font-family: Montserrat,serif;
  font-weight: 500;
  
  &:hover{
    color: var(--MAIN_RED);
  }
`