import React, {useEffect, useRef, useState} from 'react';

/*Styles*/
import * as S from './styles/styles';

/*Created components*/
import CreateScene from "@current/main/functions/3D/default_model_script";



const ChainsModels = () => {
    /*LeftChain*/
    const leftChainCanvas = useRef(null);
    const [isLoadedLeftChain, setIsLoadedLeftChain] = useState(false)
    let leftChainUrl = 'models/chains/left_chains.glb';
    let leftCameraValues = [0,0,20];
    let leftScaleValues = [11.5, 11.5, 11.5]
    let leftPositionValues = [-3.25, -3, 0]

    /*RightChain*/
    const rightChainCanvas = useRef(null);
    const [isLoadedRightChain, setIsLoadedRightChain] = useState(false)
    let rightChainUrl = 'models/chains/right_chains.glb';
    let rightCameraValues = [0,0,18];
    let rightScaleValues = [14.2, 14.2, 14.2]
    let rightPositionValues = [0, 0, 0]

    useEffect(() => {
        if (leftChainCanvas.current && rightChainUrl) {
            CreateScene(
                leftChainCanvas.current,
                () => {setIsLoadedLeftChain(true)},
                leftChainUrl,
                leftCameraValues,
                leftScaleValues,
                leftPositionValues
            );
        }
        if (rightChainCanvas.current && rightChainUrl) {
            CreateScene(
                rightChainCanvas.current,
                () => {setIsLoadedRightChain(true)},
                rightChainUrl,
                rightCameraValues,
                rightScaleValues,
                rightPositionValues
            );
        }
    }, []);

    return (
        <S.ChainsModels__Wrapper>
            <S.ChainsModels__Canvas_SideLeft ref={leftChainCanvas} $isLoaded={isLoadedLeftChain}/>
            <S.ChainsModels__Canvas_SideRight ref={rightChainCanvas} $isLoaded={isLoadedRightChain}/>
        </S.ChainsModels__Wrapper>
    );
};

export default ChainsModels;
