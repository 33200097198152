import ThinArrow from "@assets/images/icons/thin_arrow.svg"
import Plus from "@assets/images/icons/plus.svg"
import Minus from "@assets/images/icons/minus.svg"
import ProfileNonAuth from "@assets/images/icons/profile_non_auth.svg"
import ProfileAuth from "@assets/images/icons/profile_auth.svg"
import OpenEye from "@assets/images/icons/open_eye.svg"
import CloseEye from "@assets/images/icons/close_eye.svg"
import Basket from "@assets/images/icons/basket.svg"
import SharpArrow from "@assets/images/icons/thin_arrow.svg"



const AdditionalImages = {
    ThinArrow,
    Plus,
    Minus,
    ProfileNonAuth,
    ProfileAuth,
    OpenEye,
    CloseEye,
    Basket,
    SharpArrow,
};

export default AdditionalImages;
