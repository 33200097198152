import React, {useEffect, useState} from 'react';
import {useLoaderData} from "react-router-dom";

/*Created components*/
import ApiRequest from "@core/api/api_request";
import LineSeparator from "@components/line_separator/line_separator";
import ProductBlock from "@components/product_block/product_block";
import MotionDiv from "@core/components/motion_div/motion_div";
import RequestLoaderBlock from "@components/request_loader_block/request_loader_block";

/*Styles*/
import * as S from './styles/styles.js';
import {slideUpDown} from "@core/components/form_slider_components/general_popup/motion_animations/animations";



export async function productsLoader({iteration = 1}) {
    const products = await ApiRequest("get", `api/products/get_products/?page=${iteration}`);
    return {products};
}

const Products = () => {
    const [iteration, setIteration] = useState(2);
    const [products, setProducts] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [settingsCount, setSettingsCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    /*Extra states*/
    const [isFirstOpen, setIsFirstOpen] = useState(true);

    const {products: productsFirst} = useLoaderData();

    useEffect(()=>{
        if (productsFirst && productsFirst.data){
            setProducts((prevProducts) => [...prevProducts, ...productsFirst.data.results]);
            setSettingsCount(prev => prev + productsFirst.data.settings_count)
            setTotalCount(productsFirst.data.total_count)
        }
    },[productsFirst])

    const handleSendData = async (e) => {
        setIsFirstOpen(false);
        e.preventDefault();
        setIsLoading(true);
        const extraProducts = await productsLoader({ iteration });
        if (extraProducts.products && extraProducts.products.data) {
            setProducts((prevProducts) => [...prevProducts, ...extraProducts.products.data.results]);
            setSettingsCount(prev => prev + extraProducts.products.data.settings_count);
        }
        setIteration(prev => prev + 1);
        setIsLoading(false);
    };

    return (
        <>
            <LineSeparator/>
            <div id={'products'}></div>

            {/*Products*/}
            {products.length > 0 && (
                products.map(item => (
                    <MotionDiv key={item.id}
                                      initial={isFirstOpen ? 'noAnimInitial' : 'usualInitial'}
                                      animate='animate'
                                      exit='exit'
                                      variants={slideUpDown()}>
                        <ProductBlock productInfo={item} />
                        <LineSeparator />
                    </MotionDiv>
                ))
            )}

            {/*Button see more*/}
            {settingsCount < totalCount && (
                <S.ProductBlock__ButtonSeeMoreWrapper initial={'usualInitial'}
                                                      animate='animate'
                                                      exit='exit'
                                                      variants={slideUpDown()}>

                    <S.ProductBlock__ButtonSeeMore onClick={handleSendData} $loading={isLoading}>
                        {isLoading && <RequestLoaderBlock/>}
                        <S.ProductBlock__ButtonSeeMoreText $loading={isLoading}>WATCH MORE</S.ProductBlock__ButtonSeeMoreText>
                    </S.ProductBlock__ButtonSeeMore>

                    <S.ProductBlock__ButtonSeeMoreRemainsText>
                        {settingsCount} OUT OF {totalCount} PRODUCTS HAVE BEEN VIEWED
                    </S.ProductBlock__ButtonSeeMoreRemainsText>
                </S.ProductBlock__ButtonSeeMoreWrapper>
            )}
        </>
    );
};

export default Products;