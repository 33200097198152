import React, {useContext, useRef} from 'react';

/*Created components*/
import InputHeader from "@core/components/form_slider_components/general_popup/input_components/input_header/input_header";
import InputWrapper from "@core/components/form_slider_components/general_popup/input_components/input_wrapper/input_wrapper";
import Input from "@core/components/form_slider_components/general_popup/input_components/input/input";
import inputsFormContext
    from "@core/components/form_slider_components/general_popup/input_components/page_form/context/InputsFormContext";



const DefaultReadyFullInput = ({headerText, placeholder = "", name, isExplanation = false, tooltipText, forPage, disabled}) => {

    const {formData, formDataHandleChange} = useContext(inputsFormContext)
    const explanationRef = useRef(null);

    return (
        <InputWrapper explanationRef={explanationRef} tooltipStandardText={tooltipText} forPage={forPage} tooltipName={name}>
            <InputHeader isExplanation={isExplanation} explanationRef={explanationRef} headerText={headerText}/>
            <Input type='text'
                   name={name}
                   placeholder={placeholder}
                   value={formData[name]}
                   dataChange={formDataHandleChange}
                   disabled={disabled}/>
        </InputWrapper>
    );
};

export default DefaultReadyFullInput;
