import {createSlice} from '@reduxjs/toolkit';



const notifyMessageSlice = createSlice({
    name: 'notifyMessageSlice',
    initialState: {
        notifications: []
    },
    reducers: {
        addNotification: (state, action) => {
            const notificationExists = state.notifications.some(notification => notification.text === action.payload);
            if (!notificationExists) {
                state.notifications.push({
                    id: new Date().getTime(),
                    text: action.payload
                });
            }
        },
        removeNotification: (state, action) => {
            state.notifications = state.notifications.filter(notification => notification.id !== action.payload);
        }
    },
});

export const {addNotification, removeNotification} = notifyMessageSlice.actions;

export default notifyMessageSlice.reducer;