/*Styles*/
import styled from 'styled-components';
import {SelectNone, SelectNoneWithoutPointer} from "@core/css/css_snippets";
import {Link} from "react-router-dom";


export const ProfileWrapper = styled.div`
  width: 100vw;
  border-top: 0.085rem solid var(--MAIN_GREY);
  border-bottom: 0.085rem solid var(--MAIN_GREY);
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`


export const ProfileWrapper__InsideWrapper = styled.div`
  width: 63rem;
  height: 100%;
  display: flex;
  justify-content: flex-start;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`

export const ProfileWrapper__Menu = styled.div`
  width: 12.5rem;
  height: 20rem;
  background: var(--MAIN_GREY);
  box-sizing: border-box;
  padding: 2rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  letter-spacing: 0.1rem;
  font-weight: 600;
  font-size: 0.8rem;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    background: var(--MAIN_GREY);
    border-top: 0.1rem solid white;
    border-bottom: 0.1rem solid white;
    padding: 0 1rem;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: auto;
  }
`

export const ProfileWrapper__MenuPagesButtonBlock = styled.div`
  border-top: 0.1rem solid white;
  border-bottom: 0.1rem solid white;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    border-top: 0 solid white;
    border-bottom: 0 solid white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 20rem;
    border-left: 0.1rem solid white;
    border-right: 0.1rem solid white;
  }
`

export const ProfileWrapper__MenuButton = styled(Link)`
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s linear;
  cursor: pointer;
  ${props => props.$active ? SelectNone : SelectNoneWithoutPointer};
  background: ${props => props.$active  ? 'var(--MAIN_PREMIUM_BLACK)' : 'none'};
  color: white;
  
  &:hover{
    color: var(--MAIN_RED);
  }
`

export const ProfileWrapper__MenuPagesExitButton = styled(ProfileWrapper__MenuButton)`
  border-top: 0.1rem solid white;
  border-bottom: 0.1rem solid white;
  ${SelectNoneWithoutPointer};

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    border-top: 0 solid white;
    border-bottom: 0 solid white;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 6rem;
    border-left: 0.1rem solid white;
    border-right: 0.1rem solid white;
    margin-left: 1rem;
  }
`

export const ProfileWrapper__MainPart = styled.div`
  width: 100%;
  height: 100%;
`

export const ProfileWrapper__NonAuthText = styled.div`
  box-sizing: border-box;
  padding: 2rem 2rem;
  text-align: center;
  font-family: Montserrat,serif;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    margin-top: 0;
  }
`

