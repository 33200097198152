/*Styles*/
import styled from 'styled-components';
import {Link} from "react-router-dom";



export const OtherUrls = styled.div`
  width: 100%;
  background: var(--EASY_GREY);
  display: flex;
  justify-content: center;
`

export const OtherUrls__Wrapper = styled.div`
  padding: 0.3rem;
  width: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OtherUrls__Link = styled(Link)`
  padding-top: 0.1rem;
  color: var(--ALMOST_WHITE);
  font-size: 0.7rem;
  margin: 0 0.65rem;
  transition: 0.15s linear;
  
  &:hover{
    color: var(--MAIN_RED);
  }
`