/*React*/
import React, {useEffect, useState} from 'react';

/*Created components*/
import OrderPopupContext from "@components/order_popup/contexts/context";
import {useCart} from "@entry_point/initalizers/cart_context";
import {formatNumber} from "@core/functions/number_correct_margins";
import useRequest from "@core/api/hooks/useRequest";
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import OrderPopup from "@components/order_popup/order_popup";
import useFormData from "@core/hooks/useFormData";

/*Styles*/
import * as S from './styles/styles.js';
import {AnimatePresence} from "framer-motion";
import {slideUpDown} from "@core/components/form_slider_components/general_popup/motion_animations/animations";
import ApiRequest from "@core/api/api_request";
import Cookies from "js-cookie";
import {useLoaderData} from "react-router-dom";
import add_base_url from "@core/functions/add_base_url";



export async function cartLoader() {
    const cartCookie = Cookies.get('cart');
    let productIds;

    if (cartCookie) {
       let cart = JSON.parse(cartCookie)
       productIds  = cart.map(item => item.id);
    }
    const productsFromRequest = await ApiRequest("post", `api/products/get_order_products/`, {"productIds": productIds});
    return {productsFromRequest};
}

const CartProduct = () => {
    const [products, setProducts] = useState([])
    const [totalCost, setTotalCost] = useState(0);

    /*Loader*/
    const {productsFromRequest} = useLoaderData();

    useEffect(()=>{
        if (productsFromRequest && productsFromRequest.data){
            setProducts(productsFromRequest.data)
        }
    },[productsFromRequest])


    /*Main Request*/
    const {cart, removeItemFromCart, addItemToCart, updateItemCount} = useCart();
    const productIds = cart.map(item => item.id);
    const { executeRequest, successData } = useRequest();

    const fetchSettings = async () => {
        await executeRequest("post",`api/products/get_order_products/`, {"productIds": productIds});
    };

    useEffect(() => {
        if (productIds.length > 0) {
            fetchSettings();
        } else {
            setProducts([]);
            setTotalCost(0);
        }
    }, [cart]);

    useEffect(() => {
        if (successData){
            setProducts(successData)
        }
    }, [successData]);

    /**Products-Cart increase-decrease**/
    const handleIncreaseCount = (productId) => {
        addItemToCart(productId, 1);
    };

    const handleDecreaseCount = (productId) => {
        const currentCount = getCountForProduct(productId);
        if (currentCount > 1) {
            updateItemCount(productId, currentCount - 1);
        } else {
            removeItemFromCart(productId);
        }
    };

    /**Total cost**/
    useEffect(() => {
        if (products.length > 0) {
            calculateTotalCost();
        }
    }, [products, cart]);

    const getCountForProduct = (productId) => {
        const cartItem = cart.find(item => item.id === productId);
        return cartItem ? cartItem.count : 0;
    };

    const calculateTotalCost = () => {
        const total = products.reduce((sum, product) => {
            const count = getCountForProduct(product.id);
            return sum + (product.cost * count);
        }, 0);
        setTotalCost(total);
    };

    /**Order popup**/
    const [isExistPopupOrder, setIsExistPopupOrder] = useState(false);
    const popupStatesOrder = {isExist: isExistPopupOrder, setExist: setIsExistPopupOrder};

    const {formData, handleChange, resetFormData} = useFormData({
        products: cart,
        total_cost: totalCost,
        country: '',
        city: '',
        phone: '',
        fullname: '',
        delivery_type: '',
        street: '',
        house: '',
        entrance: '',
        floor: '',
        apartment: '',
    });

    useEffect(()=>{
        if (!isExistPopupOrder){
            setTimeout(()=>{
                resetFormData();
            },250)
        }
    },[isExistPopupOrder])

    const [redirectUrl, setRedirectUrl] = useState(null)

    return (
        <>
            <OrderPopupContext.Provider value={{totalCost, setTotalCost, formData, handleChange, redirectUrl, setRedirectUrl}}>
                <GeneralPopup {...popupStatesOrder}>
                    <OrderPopup/>
                </GeneralPopup>
            </OrderPopupContext.Provider>

            <S.CartProduct>
                <AnimatePresence>
                    {products.length > 0 ?
                        products.map(product => (
                            <S.CartProduct__WrapperLine key={product.id}
                                                initial={'noAnimInitial'}
                                                animate='animate'
                                                exit='exit'
                                                variants={slideUpDown()}>
                            <S.CartProduct__ProductBlock>
                                <S.CartProduct__ProductImageBlock>
                                    <S.CartProduct__ProductImage $image={add_base_url(`/media/${product.image_product_url}`)}/>
                                </S.CartProduct__ProductImageBlock>

                                <S.CartProduct__ProductInfo>
                                    <S.CartProduct__ProductTitleText>
                                        {product && product.name ? product.name.toUpperCase() : null}
                                    </S.CartProduct__ProductTitleText>
                                    <S.CartProduct__ProductCostText>{product && formatNumber(product.cost)}</S.CartProduct__ProductCostText>
                                    <S.CartProduct__ProductSizeBlock>
                                        <S.CartProduct__ProductTextThin>{product && product.size ? product.size.toUpperCase() : null}</S.CartProduct__ProductTextThin>
                                    </S.CartProduct__ProductSizeBlock>
                                    <S.CartProduct__ProductQuantityBlock>
                                        <S.CartProduct__ProductTextThin>QUANTITY:</S.CartProduct__ProductTextThin>
                                        <S.CartProduct__ProductQuantityInput>{getCountForProduct(product.id)}</S.CartProduct__ProductQuantityInput>
                                        <S.CartProduct__ProductQuantityIconsWrapper onClick={() => handleIncreaseCount(product.id)}>
                                            <S.CartProduct__ProductQuantityIcon/>
                                        </S.CartProduct__ProductQuantityIconsWrapper>

                                        <S.CartProduct__ProductQuantityIconsWrapper className={'minus'} onClick={() => handleDecreaseCount(product.id)}>
                                            <S.CartProduct__ProductQuantityIcon className={'minus'}/>
                                        </S.CartProduct__ProductQuantityIconsWrapper>

                                    </S.CartProduct__ProductQuantityBlock>
                                </S.CartProduct__ProductInfo>

                                <S.CartProduct__ProductDelete onClick={()=>removeItemFromCart(product.id)}>
                                    <S.CartProduct__ProductDeleteIcon />
                                </S.CartProduct__ProductDelete>
                            </S.CartProduct__ProductBlock>
                        </S.CartProduct__WrapperLine>
                        ))
                        :
                        <S.CartProduct__WrapperLine__NoProducts>
                            <S.CartProduct__WrapperLine__NoProducts__Text>There are no items in your cart</S.CartProduct__WrapperLine__NoProducts__Text>
                        </S.CartProduct__WrapperLine__NoProducts>
                    }
                </AnimatePresence>

                <S.CartProduct__ResultCost>
                    TOTAL AMOUNT:
                    <S.CartProduct__ProductTextBold>{formatNumber(totalCost)} RUB</S.CartProduct__ProductTextBold>
                </S.CartProduct__ResultCost>
                {totalCost !== 0 &&
                    <S.CartProduct__PaymentButton onClick={() => setIsExistPopupOrder(true)}>
                        PROCEED TO PAYMENT
                    </S.CartProduct__PaymentButton>
                }
            </S.CartProduct>
        </>
    );
};

export default CartProduct;
