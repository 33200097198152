/*React*/
import React, {useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';
import router from '@src/current/main/routers/root_router';
import store from '@src/current/entry_point/redux/store';
import {Provider, useDispatch} from 'react-redux';

/*Styles*/
import GlobalStyle from '@src/current/entry_point/global_style';

/*Created components*/
import AuthContext from "@src/current/entry_point/initalizers/auth_context";
import Loader from "@core/components/pages_loader/pages_loader";
import useAuthCheck from "@core/api/hooks/useAuthCheck";
import {CartProvider, useCart} from "@src/current/entry_point/initalizers/cart_context";
import OkPopup from "@core/components/form_slider_components/ok_popup/ok_popup";
import {setTextAndHeaderOkPopup} from "@src/current/main/redux_reducers/ok_popup_slice";
import YesNoPopup from "@core/components/form_slider_components/yes_no_popup/yes_no_popup";
import ScrollTopButton from "@components/scroll_top_button/scroll_top_button";
import NotifyMessage from "@components/notify_message/notify_message";
import useRequest from "@core/api/hooks/useRequest";
import {
    setNoCloseFunction,
    setTextYesNoPopup,
    setYesCloseFunction
} from "@current/main/redux_reducers/yes_no_popup_slice";



const Core = () => {
    const dispatch = useDispatch();

    /**New user notification**/
    useEffect(()=>{
        if (!localStorage.getItem('new_user_notify')) {
            localStorage.setItem('new_user_notify', "1");
            dispatch(setTextAndHeaderOkPopup({header: "Внимание!",
                text: "Сайт обрабатывает данные пользователя.\n Продолжая использование сайта, вы соглашаетесь с этим."}))
        }
    },[])

    /**Auth**/
    const { isAuthenticated, setIsAuthenticated, loading } = useAuthCheck();

    /**Resize-transition**/
    const [disableTransitions, setDisableTransitions] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setDisableTransitions(true);
            clearTimeout(window.resizeTimeout);
            window.resizeTimeout = setTimeout(() => setDisableTransitions(false), 100);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    /**End**/

    /**CheckPayments*/
    const {executeRequest, errors, successData} = useRequest()

    const CheckPayment = async () => {
        const payment_id = localStorage.getItem('paymentId');
        if (payment_id) {
            await executeRequest('post', `/api/orders/check_payment/`, {"paymentId": payment_id})
        }
    }

    useEffect(() => {
        CheckPayment()
    },[])

    useEffect(()=>{
        if (successData){
            dispatch(setTextAndHeaderOkPopup({text: "Ваш заказ успешно оплачен!", header: ""}))
            localStorage.removeItem('paymentId');
            localStorage.removeItem('paymentUrl');
        }
    },[successData])

    const yesOrder = () => {
        const paymentUrl = localStorage.getItem('paymentUrl');
        window.open(paymentUrl, '_blank');
    };

    const noOrder = async () => {
        localStorage.removeItem('paymentId');
        localStorage.removeItem('paymentUrl');
    };

    const handlerLogout = () =>{
        dispatch(setYesCloseFunction(yesOrder));
        dispatch(setNoCloseFunction(noOrder));
        dispatch(setTextYesNoPopup("У вас есть неоплаченный заказ.\nВы хотите продолжить оплату?"));
    }

    useEffect(()=>{
        if (errors){
            handlerLogout();
        }
    },[errors])
    /**End**/

    return (
        <>
        <GlobalStyle $disableTransitions={disableTransitions} />
        <NotifyMessage />
        <OkPopup />
        <YesNoPopup />
        <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated, loading}}>
                <CartProvider>
                    <RouterProvider router={router} />
                </CartProvider>
                <ScrollTopButton />
        </AuthContext.Provider>
        </>
    );
};



function reactInitiation() {
    const root = document.getElementById('root');
    const reactRoot = createRoot(root);

    reactRoot.render(
        <Provider store={store}>
            <Loader />
            <Core />
        </Provider>,
    );
}

export default reactInitiation;
