/*React*/
import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

/*Created components*/
import InputWrapper
    from "@core/components/form_slider_components/general_popup/input_components/input_wrapper/input_wrapper";
import HintMarker from "@core/components/form_slider_components/general_popup/input_components/hint_marker";

/*Styles*/
import * as S from './styles/radio_input_block_styles';
import addBrToString from "@core/functions/add_br_in_string";



const RadioInputBlock = ({mainText, name, id, dataChange, isExplanation = false, tooltipText}) => {
    const explanationRef = useRef(null);

    const handleChange = () => {
        dataChange(name, mainText)
    };

    return (
        <InputWrapper explanationRef={explanationRef} tooltipStandardText={tooltipText}>
            <S.RadioInputBlock>
                <S.RadioInputBlock__InputPart>
                    <S.RadioInputBlock__Input name={name}
                                              id={id}
                                              onChange={handleChange}/>
                    <S.RadioInputBlock__InputLabel htmlFor={id}>{addBrToString(mainText)}</S.RadioInputBlock__InputLabel>
                </S.RadioInputBlock__InputPart>

                <S.RadioInputBlock__ExplanationPart>
                    {isExplanation && explanationRef &&
                        <HintMarker explanationRef={explanationRef}></HintMarker>
                    }
                </S.RadioInputBlock__ExplanationPart>
            </S.RadioInputBlock>
        </InputWrapper>
    );
};

RadioInputBlock.propTypes = {
    mainText: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    dataChange: PropTypes.func,
    isExplanation: PropTypes.bool,
    tooltipText: PropTypes.string,
};

export default RadioInputBlock;
