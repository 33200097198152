/*React*/
import React from "react";
import * as S from "@core/components/form_slider_components/general_popup/page_components/styles/page_components";

/*Styles*/
import PropTypes from "prop-types";



/*Text*/
export const PageText = ({children, headerText}) => {
    return (
        <S.PageText>
            {headerText && (
                <S.PageText__Header>{headerText}</S.PageText__Header>
            )}
            <S.PageText__Text>{children}</S.PageText__Text>
        </S.PageText>
    );
};

PageText.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
        PropTypes.string
    ]),
    headerText: PropTypes.string
};