/*Styles*/
import styled from 'styled-components';
import AdditionalImages from "@values/additional_images";
import {SelectNone, SelectNoneWithoutPointer} from "@core/css/css_snippets";
import {motion} from "framer-motion";



export const ProductBlock = styled(motion.div)`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  overflow: hidden;
`

export const ProductBlock__Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ProductBlock__TitleBlock = styled.div`
  width: 95%;
  height: 6.6rem;
  margin-top: 1rem;
  ${SelectNoneWithoutPointer};
  z-index: 4;

  /*250%*/
  @media (max-width: 900px) {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    height: auto;
  }
`

export const ProductBlock__TitleBlockWrapper = styled.div`
  position: relative;
  width: 43.75rem;
  height: 100%;

  /*250%*/
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
  }
`

export const ProductBlock__TitleNumber = styled.div`
  position: absolute;
  bottom: 0;
  width: 3.125rem;
  height: 4.55rem;
  background: ${props => props.$sold_status  ? 'var(--EASY_GREY)' : 'var(--MAIN_RED)'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-weight: 1000;
  z-index: 1;

  /*250%*/
  @media (max-width: 900px) {
    display: none;
  }
`

export const ProductBlock__TitleTextWrapper = styled.div`
  position: absolute;
  margin-top: 1rem;
  left: 2rem;
  width: 46rem;
  height: 5.8rem;
  z-index: 2;

  /*250%*/
  @media (max-width: 900px) {
    position: relative;
    width: auto;
    left: 0;
    height: auto;
    margin-top: 0;
  }
`

export const ProductBlock__SoldOut = styled.div`
  position: absolute;
  font-size: 2rem;
  left: 2rem;
  bottom: -1rem;
  color: var(--MAIN_RED);
  font-weight: 1000;
  z-index: 3;
  letter-spacing: 0.1rem;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    left: 0;
    font-size: 1.2rem;
  }
`


export const ProductBlock__TitleText = styled.div`
  position: absolute;
  left: 1.6rem;
  color: white;
  font-size: 4.5rem;
  font-weight: 1000;
  z-index: 3;

  /*250%*/
  @media (max-width: 900px) {
    position: relative;
    font-size: 3.8rem;
    left: 0;
  }

  /*400%*/
  @media (max-width: 600px) {
    font-size: 2.8rem;
  }

  /*500%*/
  @media (max-width: 400px) {
    font-size: 2.7rem;
  }
`

export const ProductBlock__TitleAdditionalBottomBlock = styled.div`
  position: absolute;
  bottom: 0.625rem;
  width: 19.7rem;
  height: 1.65rem;
  z-index: 2;
  background: var(--MAIN_GREY);

  /*250%*/
  @media (max-width: 900px) {
    bottom: 0;
    width: 15rem;
    left: -1rem;
  }
`

/**Canvas**/
export const ProductBlock__ProductCanvas = styled.canvas`
  position: absolute;
  width: 100%;
  height: 35.5rem;
  z-index: 5;
  transition: 0.25s linear;
  opacity: ${props => props.$isLoaded  ? '100%' : '0'};
`

export const ModelLoader = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 35.5rem;
  z-index: 10;
  background: var(--MAIN_PREMIUM_BLACK);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  color: white;
`

/**Buttons**/
export const ProductBlock__ProductButtonsWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 5.2rem;
  height: 5.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 7;
  ${SelectNoneWithoutPointer};
`

const __ProductBlock__ProductButtons = styled(motion.div)`
  background: ${props => props.$sold_status  ? 'var(--EASY_GREY)' : 'var(--MAIN_RED)'};
  height: 45%;
  display: flex;
  justify-content: flex-start;
`

export const ProductBlock__ProductButtons_TypeAdditionalInfo = styled(__ProductBlock__ProductButtons)`
  position: relative;
  height: 2rem;
  opacity: 40%;
  transition: linear 0.25s;
  cursor: pointer;
  ${props => props.$blockedClick && SelectNone}
  padding: 0.1rem 0;
  
  &:hover{
    opacity: 100%;
  }
`

export const ProductBlock__ProductButtons_TypeAddInBasket = styled(__ProductBlock__ProductButtons)`
  cursor: pointer;
  opacity: 40%;
  transition: linear 0.25s;
  height: 2.5rem;
  background: ${props => props.$sold_status  ? 'var(--EASY_GREY)' : 'var(--MAIN_RED)'};
  
  &:hover{
    opacity: 100%;
  }
`

export const ProductBlock__ProductButtons__IconBlock = styled.div`
  width: 2.25rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductBlock__ProductButtons__Text = styled.div`
  width: auto;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  vertical-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.8rem 0.4rem;
  border-left: 0.085rem solid white;
  letter-spacing: 0.1em;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-weight: 400;
  }
`

export const ProductBlock__ProductButtons_TypeAdditionalInfo__Icon = styled.div`
  background: url(${AdditionalImages.ThinArrow}) no-repeat center;
  height: 70%;
  aspect-ratio: 1/1;
  background-size: contain;
  transform: rotate(90deg);
`

export const ProductBlock__ProductButtons_TypeAddInBasket__Icon = styled.div`
  color: white;
  margin-top: 0.2rem;
  font-size: 3rem;
  font-weight: 500;
`

export const ProductBlock__ProductButtons__Cost = styled.span`
  font-size: 0.96rem;
`

/**AdditionalInfoBlock*/
export const ProductBlock__AdditionalInfoBlock = styled(motion.div)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 19.6rem;
  height: auto;
  background: var(--MAIN_GREY);
  z-index: 2;
  display: flex;
  align-items: flex-end;
`

export const ProductBlock__AdditionalInfoWrapperInside = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const ProductBlock__AdditionalInfo_SideTop = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const ProductBlock__AdditionalInfo_SideBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const __ProductBlock__AdditionalInfoText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
  text-align: left;
  line-height: 1.25rem;
  color: white;
  font-family: 'Montserrat', sans-serif;
`

export const ProductBlock__AdditionalInfoTitle = styled.span`
  color: var(--MAIN_RED);
  white-space: nowrap;
`

export const ProductBlock__AdditionalInfoText_TypeName = styled(__ProductBlock__AdditionalInfoText)`
  font-size: 0.9rem;
`

export const ProductBlock__AdditionalInfoText_TypeSize = styled(__ProductBlock__AdditionalInfoText)`
  font-size: 0.9rem;
`

export const ProductBlock__AdditionalInfoText_TypeDescription = styled(__ProductBlock__AdditionalInfoText)`
  text-align: center;
  font-size: 0.8rem;
  margin-top: 0.2rem;
  margin-bottom: 0.35rem;
  line-height: 1.25rem;
  white-space: pre-line;
`

export const ProductBlock__AdditionalInfoText_TypeCost = styled(__ProductBlock__AdditionalInfoText)`
  position: relative;
  padding: 0.2rem;
  background: var(--MAIN_PREMIUM_BLACK);
`

export const ProductBlock__AdditionalInfoSmallCost = styled(__ProductBlock__AdditionalInfoText)`
  position: absolute;
  font-size: 0.65rem;
  left: 0.35rem;
  bottom: -0.3rem;
`

export const ProductBlock__AdditionalInfoButtonPrev = styled.div`
  width: 2.3rem;
  height: 2.3rem;
  background: ${props => props.$sold_status  ? 'var(--EASY_GREY)' : 'var(--MAIN_RED)'};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
  opacity: 65%;
  transition: linear 0.25s;
  cursor: pointer;
  
  &:hover{
    opacity: 100%;
  }
`

export const ProductBlock__AdditionalInfoButtonIconPrev = styled.div`
  background: url(${AdditionalImages.ThinArrow}) no-repeat center;
  box-sizing: border-box;
  height: 70%;
  aspect-ratio: 1/1;
  background-size: contain;
  transform: rotate(90deg);
`

export const ProductBlock__AdditionalInfoButtonBasket = styled(motion.div)`
  position: relative;
  opacity: 65%;
  height: 2.3rem;
  background: ${props => props.$sold_status  ? 'var(--EASY_GREY)' : 'var(--MAIN_RED)'};
  transition: linear 0.25s;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;

  &:hover{
    opacity: 100%;
  }
`

export const ProductBlock__AdditionalInfoButtonIconBasketBlock = styled.div`
  width: 2.3rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductBlock__AdditionalInfoButtonIconBasket = styled.div`
  color: white;
  margin-top: 0.2rem;
  font-size: 3rem;
  font-weight: 500;
`


export const ProductBlock__AdditionalInfoButtonIconBasketText = styled.div`
  height: 100%;
  width: auto;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  vertical-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0 0.8rem 0 0.4rem;
  border-left: 0.085rem solid white;
  letter-spacing: 0.1em;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-weight: 400;
  }
`

export const ProductBlock__ButtonSeeMoreWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

export const ProductBlock__ButtonSeeMore = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 1.67rem;
  margin-bottom: 1.67rem;
  font-size: 1rem;
  font-weight: 1000;
  letter-spacing: 0.05rem;
  padding: 0.65rem 1.3rem;
  background-color: rgba(255, 255, 255, 0);
  border: 0.1rem solid;
  border-image: ${props => props.$loading  ? 'linear-gradient(to right, white 100%, white) 1' : 
      'linear-gradient(to right, white, var(--MAIN_PREMIUM_BLACK) 40% 60%, white) 1'} ;
  color: white;
  transition: 0.25s linear;
  
  &:hover{
    border-image: linear-gradient(to right, white 100%, white) 1;
    color: var(--MAIN_RED);
  }
`

export const ProductBlock__ButtonSeeMoreText = styled.div`
  transition: 0.25s linear;
  opacity: ${props => props.$loading  ? '0' : '100%'};
`

export const ProductBlock__ButtonSeeMoreRemainsText = styled.div`
  margin-bottom: 1.67rem;
  color: white;
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
`

/**TwoPartOfProducts*/
export const ProductBlock__TwoIteration = styled(motion.div)`
  width: 100%;
  overflow: hidden;
`


/**Phone**/
export const ProductExplainBlock = styled.div`
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #161616;
  height: 100%;
  width: 40%;
  z-index: 3;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export const ProductOverlap = styled.div`
  display: none;
  position: absolute;
  height: 100%;
  bottom: 0;
  right: 0;
  width: 60%;
  z-index: 6;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    display: block;
  }
`

export const ProductExplainBlock__IconWrapper = styled.div`
  width: 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 15%;
`

export const ProductExplainBlock__Icon_TypeArrow = styled.div`
  background: url(${AdditionalImages.SharpArrow}) no-repeat center;
  margin: 0.5rem;
  width: 30%;
  aspect-ratio: 1/1;
  transform: rotate(180deg);
  
  &.bottom{
    transform: rotate(0deg);
  }
`

export const ProductExplainBlock__Icon_Line = styled.div`
  width: 0.11rem;
  height: 40%;
  background: white;
`

export const ProductExplainBlock__TextWrapper = styled.div`
  margin-left: 1.5rem;
  height: 100%;
  width: 2rem;
  color: white;
  writing-mode: vertical-rl;
  text-orientation: upright;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 15%;
`
