import React from "react";



const baseURL = process.env.BASE_URL;

const add_base_url = (url) => {
    if (url){
        return baseURL+"/"+url
    }
};

export default add_base_url;