/*React*/
import React from 'react';
import PropTypes from "prop-types";

/*Styles*/
import * as S from './styles/page_components';



export const PageWrapper = ({children}) => {
    return (
        <S.PageWrapper>
            {children}
        </S.PageWrapper>
    );
};

PageWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
        PropTypes.node,
    ]),
};

export default PageWrapper;
