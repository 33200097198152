/*React*/
import {useState} from "react";
import ApiRequest from "@core/api/api_request";


const useRequest = () => {
    const [loading, setLoading] = useState(false);
    const [successData, setSuccessData] = useState(null);
    const [errors, setErrors] = useState(null);

    const executeRequest = async (method, url, formData) => {
        setLoading(true);
        const result = await ApiRequest(method, url, formData);
        if (result.success) {
            setErrors(null);
            setSuccessData(result.data);
        } else {
            setSuccessData(null);
            setErrors(result.error);
        }
        setLoading(false);
    };

    return {executeRequest, loading, errors, setErrors, successData};
}

export default useRequest;