import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/profile_orders_page_styles';

/*Created components*/
import ProfileWrapper from "@pages/profile_pages/profile_wrapper/profile_wrapper";
import ApiRequest from "@core/api/api_request";
import {useLoaderData} from "react-router-dom";
import formatDateTime from "@current/main/functions/formatDateTime";
import formatNumber from "@current/main/functions/formatNumber";


export async function ordersLoader() {
    const ordersFromRequest = await ApiRequest("post", `api/orders/get_orders/`);
    return {ordersFromRequest};
}


const ProfileOrdersPage = () => {
    const [orders, setOrders] = useState([])

    /*Loader*/
    const {ordersFromRequest} = useLoaderData();

    useEffect(()=>{
        if (ordersFromRequest && ordersFromRequest.data){
            setOrders(ordersFromRequest.data)
        }
    },[setOrders])

    return (
        <ProfileWrapper active={2}>
            <S.ProfileSettings__Wrapper>
                {orders.length > 0 ?
                    orders.map(order => (
                        <S.ProfileSettings__OrderBlock key={order.id}>
                            <S.ProfileSettings__Header>
                                <S.ProfileSettings__HeaderId>ЗАКАЗ № {order.id}</S.ProfileSettings__HeaderId>
                                <S.ProfileSettings__HeaderDate>{formatDateTime(order.date)}</S.ProfileSettings__HeaderDate>
                            </S.ProfileSettings__Header>

                            <div>
                            {order.order_products && order.order_products.length > 0 && (
                                    order.order_products.map((order_product, index) => (
                                        <S.ProfileSettings__ProductLineBlock key={index}>
                                            <S.ProfileSettings__ProductLineTextId className={'start'} $grow={0.1}></S.ProfileSettings__ProductLineTextId>
                                            <S.ProfileSettings__ProductLineText $grow={1}>Наименование:<br /> {order_product.products.name}</S.ProfileSettings__ProductLineText>
                                            <S.ProfileSettings__ProductLineText $grow={1}>Количество: {order_product.count}</S.ProfileSettings__ProductLineText>
                                            <S.ProfileSettings__ProductLineText $grow={1.5}>Размер:<br /> {order_product.products.size}</S.ProfileSettings__ProductLineText>
                                            <S.ProfileSettings__ProductLineText $grow={1}>Стоимость: {order_product.products.cost}</S.ProfileSettings__ProductLineText>
                                            <S.ProfileSettings__ProductLineText $grow={1}></S.ProfileSettings__ProductLineText>
                                        </S.ProfileSettings__ProductLineBlock>
                                    ))
                                )
                            }
                            </div>

                            <S.ProfileSettings__ProductLineTextResult $grow={1}>Итог: {formatNumber(order.total_cost)} рублей</S.ProfileSettings__ProductLineTextResult>
                        </S.ProfileSettings__OrderBlock>
                    ))
                    : <div>У вас нет совершенных заказов.</div>
                }
            </S.ProfileSettings__Wrapper>
        </ProfileWrapper>
    );
};

export default ProfileOrdersPage;
