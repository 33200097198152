/*React*/
import React from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/styles';


const ModelLoader = ({progress}) => {
    return (
        <S.ModelLoader initial={{opacity: "100%"}} exit={{opacity: "0"}} transition={{duration: 1}}>
            {progress + "%"}
        </S.ModelLoader>
    );
};

ModelLoader.propTypes = {
    progress: PropTypes.number
};


export default ModelLoader;