import React from 'react';

/*Created components*/
import LineSeparator from "@components/line_separator/line_separator";

/*Styles*/
import * as S from './styles/info_pages_styles';



const DeliveryInfoPage = () => {
    return (
        <>
            <LineSeparator marginTop={1}/>
            <S.InfoWrapper>
                <S.MainTheme>Информация о доставке</S.MainTheme>
                <S.Theme>1. Доставка</S.Theme>
                <S.SimpleText>1.1. Бесплатная доставка для заказов от 12000 руб. (*Распространяется на все виды доставок, за исключением доставки по миру.)</S.SimpleText>
                <S.SimpleText>1.2. Типы доставки:</S.SimpleText>
                <S.SimpleCause>1.2.1. Пункт выдачи СДЭК (от 2 дней) - 300₽</S.SimpleCause>
                <S.SimpleCause>1.2.2. Курьер Москва в пределах МКАД (возможна доставка в день заказа) - 800₽</S.SimpleCause>
                <S.SimpleCause>1.2.3. Курьер СДЭК Москва за пределами МКАД (от 2 дней) - 800₽</S.SimpleCause>
                <S.SimpleCause>1.2.4. Курьер СДЭК Россия (от 2 дней) - 800₽</S.SimpleCause>
                <S.SimpleCause>1.2.5. Самовывоз Пн–Пт с 11:00 до 19:00. Москва, самовывоз возможен по предварительной договоренности.</S.SimpleCause>
                <S.SimpleCause>1.2.6. Другие страны Для доставки в другие страны свяжитесь с нами, написав на почту <S.LinkUnderlining>info@sad-blood.ru</S.LinkUnderlining> или написать в direct message в <S.LinkUnderlining href={"https://www.instagram.com/sad.blood?igsh=MXBhYmpuMXh5eXZhNg=="}>Instagram</S.LinkUnderlining> или <S.LinkUnderlining href={"https://t.me/sad_blood"}>Telegram</S.LinkUnderlining></S.SimpleCause>
                <S.SimpleText>1.3. Доставка осуществляется при 100% оплате заказа на сайте.
                    Срок сбора заказа при наличии изделий  1 рабочий день, не считая дня оформления заказа.</S.SimpleText>
                <S.SimpleText>1.4. При оформлении заказа в выходные и праздничные дни - отправка осуществляется в ближайший рабочий день.</S.SimpleText>
                <S.SimpleText>1.5. После того, как ваш заказ будет передан в транспортную компанию, вы получите трек-номер для отслеживания. Отследить заказ транспортной компании <S.LinkUnderlining href={"https://www.cdek.ru/ru/tracking"}>CDEK-отслеживание</S.LinkUnderlining></S.SimpleText>
                <S.Theme>2. Возвраты</S.Theme>
                <S.SimpleText>2.1. Условия возврата:</S.SimpleText>
                <S.SimpleText>2.1. Условия возврата:</S.SimpleText>
                <S.SimpleCause>2.1.1. Для осуществления возврата необходимо связаться с нами, написав на почту <S.LinkUnderlining>info@sad-blood.ru</S.LinkUnderlining> или написать в direct message в <S.LinkUnderlining href={"https://www.instagram.com/sad.blood?igsh=MXBhYmpuMXh5eXZhNg=="}>Instagram</S.LinkUnderlining> или <S.LinkUnderlining href={"https://t.me/sad_blood"}>Telegram</S.LinkUnderlining></S.SimpleCause>
                <S.SimpleCause>2.1.2. Сохранен товарный вид и потребительские свойства: на украшении нет следов носки, неповрежденная упаковка.</S.SimpleCause>
                <S.SimpleCause>2.1.3. С момента получения товара прошло не более 7 дней (при покупке онлайн) и не более 14 (при покупки офлайн) для возвратов по собственному желанию и 20 дней ввиду заводского брака.</S.SimpleCause>
                <S.SimpleCause>2.1.4. По закону мы не сможем принять назад украшение, которое сделали для вас по заказу или модифицировали — изменили размер, цвет покрытия и т.д.</S.SimpleCause>
                <S.SimpleText>2.2. Как вернуть украшения?</S.SimpleText>
                <S.SimpleCause>2.2.1. В письме необходимо указать номер вашего заказа, прикрепить чек о совершении покупки и детально описать причину возврата.</S.SimpleCause>
                <S.SimpleCause>2.2.2. Возврат осуществляется за счёт клиента. Стоимость доставки не возвращается.</S.SimpleCause>
                <S.SimpleCause>2.2.3. После получения возврата офисом, возврат денежных средств с нашей стороны осуществляется в течение 2-5 рабочих дней.</S.SimpleCause>
                <S.SimpleText>2.3. Вернуть украшение можно:</S.SimpleText>
                <S.SimpleCause>2.3.1. Курьером до двери любой транспортной компанией.</S.SimpleCause>
                <S.SimpleCause>2.3.2. Отправить в пункт выдачи CDEK (Москва, Кутузовский просп., 30, 121165 • помещение 540).</S.SimpleCause>
                <S.Theme>3. Контакты</S.Theme>
                <S.SimpleText>Свяжитесь с нами, написав на почту <S.LinkUnderlining>info@sad-blood.ru</S.LinkUnderlining> или написать в direct message в <S.LinkUnderlining href={"https://www.instagram.com/sad.blood?igsh=MXBhYmpuMXh5eXZhNg=="}>Instagram</S.LinkUnderlining> или <S.LinkUnderlining href={"https://t.me/sad_blood"}>Telegram</S.LinkUnderlining></S.SimpleText>
            </S.InfoWrapper>
        </>
    );
};

export default DeliveryInfoPage;