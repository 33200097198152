/*React*/
import React, {useContext} from 'react';

/*Created components*/
import useFormData from "@core/hooks/useFormData";
import {
    PopupExternalContext
} from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import {useDispatch} from "react-redux";
import {setTextAndHeaderOkPopup} from "@src/current/main/redux_reducers/ok_popup_slice";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import PhoneEmailInput from "@core/components/form_slider_components/general_popup/input_components/phone_email_input";
import DefaultReadyFullInput
    from "@core/components/form_slider_components/general_popup/input_components/default_ready_full_input";
import Textarea from "@core/components/form_slider_components/general_popup/input_components/textarea";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";



const AskPopUp = () => {
    const {closePopupFunc} = useContext(PopupExternalContext);
    const dispatch = useDispatch();

    /**Data logic**/
    const {formData, handleChange} = useFormData({
        contact_info_type: '',
        contact_info: '',
        author_name: '',
        question_text: '',
    });

    /**API**/
    const successFunc = () =>{
        closePopupFunc();
        dispatch(setTextAndHeaderOkPopup({ text: 'Ваш вопрос отправлен!\n Вам ответят уже совсем скоро.', header: ''}));
    }

    return (
        <PageWrapper>
            <PageHeader>Задать вопрос</PageHeader>

            <InputsForm requestUrl={'api/questions/add_question/'} formData={formData} formDataHandleChange={handleChange} successFunction={successFunc}>

                <PhoneEmailInput/>

                <DefaultReadyFullInput headerText={'Ваше имя'}
                                       placeholder={'Введите ваше имя'}
                                       name={'author_name'}/>

                <Textarea headerText={'Вопрос'}
                          placeholder={"Введите текст вопроса"}
                          name={"question_text"}/>


                <PageButtons__Wrapper margin={'big'}>
                    <PageButton>Отправить</PageButton>
                </PageButtons__Wrapper>
            </InputsForm>
        </PageWrapper>
    );
};

export default AskPopUp;