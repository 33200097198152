/*React*/
import React, {useContext, useEffect, useState} from 'react';

/*Styles*/
import {AnimatePresence} from "framer-motion";
import {slideUpDown} from "@core/components/form_slider_components/general_popup/motion_animations/animations";

/*Created components*/
import OrderPopupContext from "@components/order_popup/contexts/context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import SliderContext from "@core/components/slider/context";
import MotionDiv from "@core/components/motion_div/motion_div";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import CountryInput from "@components/nonstandard_form_inputs/country_input/country_input";
import DefaultReadyFullInput
    from "@core/components/form_slider_components/general_popup/input_components/default_ready_full_input";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";



const OrderFirstPage = () => {
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const {formData, handleChange} = useContext(OrderPopupContext);
    const {activePageName, setActivePageName, definedPages, setDefinedPages} = useContext(PopupInsideContext);
    /*Extra states*/
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    let noChangeActivePages = ['OrderFirstPage', 'ConnectWithUsPage', 'ContactsFullPage'];

    useEffect(()=>{
        handleChange('country', 'Россия');
        setActivePageName('OrderFirstPage')
        setDefinedPages({...definedPages, pageOne: 'OrderFirstPage'})
    },[])

    const handlerConnectWithUs = () => {
        handleChange('country', 'Другая страна');
        setIsFirstOpen(false);
        setActivePageName('ConnectWithUsPage');
        setDefinedPages({...definedPages, pageTwo: 'ConnectWithUsPage'});
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    const handlerOtherCityDeliveryTypes = () => {
        setIsFirstOpen(false);
        setActivePageName('OtherCityDeliveryTypesPage');
        setDefinedPages({...definedPages, pageTwo: 'OtherCityDeliveryTypesPage'});
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    const handlerMoscowDeliveryTypes= () => {
        setIsFirstOpen(false);
        setActivePageName('MoscowDeliveryTypesPage');
        setDefinedPages({...definedPages, pageTwo: 'MoscowDeliveryTypesPage'});
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    /**API**/
    const successFunction = (successData) =>{
        if (successData?.is_moscow){
            handlerMoscowDeliveryTypes();
        }
        else {
            handlerOtherCityDeliveryTypes();
        }
    }

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Определение типа доставки</PageHeader>
                <InputsForm requestUrl={"api/orders/check_location/"} formData={formData} formDataHandleChange={handleChange} successFunction={successFunction}>
                    <CountryInput name={'country'}
                                  value={"Россия"}
                                  onClickFunc={handlerConnectWithUs}/>

                    <AnimatePresence>
                        {noChangeActivePages.includes(activePageName) && (
                            <MotionDiv initial={isFirstOpen ? 'noAnimInitial' : 'usualInitial'}
                                       animate='animate'
                                       exit='exit'
                                       variants={slideUpDown()}>
                                <DefaultReadyFullInput headerText={'Город'}
                                                       placeholder={'Введите ваш город'}
                                                       name={'city'}/>
                            </MotionDiv>
                        )}
                    </AnimatePresence>

                    <PageButtons__Wrapper margin={'big'}>
                        <PageButton>Далее</PageButton>
                    </PageButtons__Wrapper>
                </InputsForm>
            </PageWrapper>
        </MotionDiv>
    );
};

export default OrderFirstPage;