/*Styles*/
import styled from 'styled-components';
import {SelectNoneWithoutPointer} from "@core/css/css_snippets";


export const RadioInputBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const RadioInputBlock__InputPart = styled.div`
  display: flex;
  align-items: center;
`

export const RadioInputBlock__Input = styled.input.attrs({ type: "radio" })`
  position: relative;
  height: 0.7rem;
  aspect-ratio: 1/1;
  margin-right: 0.5rem;
  border: 0.1rem solid black;
  border-radius: 50%;
  
  &:checked:after{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 65%;
    height: 65%;
    border-radius: 50%;
    background: var(--STANDARD);
  }
  
`

export const RadioInputBlock__InputLabel = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  ${SelectNoneWithoutPointer};
`

export const RadioInputBlock__ExplanationPart = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
`





export const a6 = styled.div`

`