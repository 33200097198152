/*React*/
import React, {useContext, useState} from 'react';

/*Created components*/
import SliderContext from "@core/components/slider/context";
import OrderPopupContext from "@components/order_popup/contexts/context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import MotionDiv from "@core/components/motion_div/motion_div";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import RadioInputBlock
    from "@core/components/form_slider_components/general_popup/input_components/radio_input_block/radio_input_block";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";
import {slideUpDown} from "@core/components/form_slider_components/general_popup/motion_animations/animations";
import {AnimatePresence} from "framer-motion";



const OtherCityDeliveryTypesPage = () => {
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const {formData, handleChange, totalCost} = useContext(OrderPopupContext);
    const {activePageName, setActivePageName, definedPages, setDefinedPages} = useContext(PopupInsideContext);
    let noChangeActivePages = ['OtherCityDeliveryTypesPage'];

    const slideToOrderFirstPage = () => {
        setActivePageName('OrderFirstPage');
        sliderDispatch({
            type: 'SLIDE_LEFT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    /**Next pages functions**/
    const handlerContactsFullPage = () => {
        setActivePageName('ContactsFullPage');
        setDefinedPages({...definedPages, pageThree: 'ContactsFullPage'});
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    const handlerContactsCDEKPage = () => {
        setActivePageName('ContactsCDEKPage');
        setDefinedPages({...definedPages, pageThree: 'ContactsCDEKPage'});
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    /**Form tooltip error**/
    const [formError, setFormError] = useState("")

    /**Processing radio inputs**/
    const slideToFullContactPage = async (e) => {
        e.preventDefault();
        let delivery_type = formData.delivery_type;
        let deliveryData  = customDeliveryStingSeparator(delivery_type)

        if (deliveryData?.description){
            switch (deliveryData.description){
                case 'CDEK или Yandex, доставка в пункт выдачи': {
                    handlerContactsFullPage();
                    break;
                }
                case 'CDEK или Yandex, курьерская доставка': {
                    handlerContactsFullPage();
                    break;
                }
            }
            handleChange('delivery_type', deliveryData.description)
            handleChange('total_cost', parseFloat(totalCost) + parseFloat(deliveryData.cost))
        }
        else {
            setFormError("Необходимо выбрать один из пунктов.")
        }
    };

    const customDeliveryStingSeparator = (deliveryString) =>{
        if (deliveryString){
            const [description, details] = deliveryString.split(/\s+\(/);
            const cost = details.match(/\d+/)[0];
            return {description, cost};
        }
    };

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Выберите тип доставки</PageHeader>

                <InputsForm isTooltip={true} tooltipErrorText={formError}>
                    <AnimatePresence>
                        {noChangeActivePages.includes(activePageName) && (
                            <MotionDiv initial={'usualInitial'}
                                       animate='animate'
                                       exit='exit'
                                       variants={slideUpDown()}>
                                <RadioInputBlock
                                    mainText={"CDEK или Yandex, доставка в пункт выдачи (300 руб., от 2 дней)"}
                                    name={'delivery_type'}
                                    id={'delivery_other_city_type_1'}
                                    dataChange={handleChange}
                                    isExplanation={true}
                                    tooltipText={"Доставка в пункт выдачи CDEK или Yandex в вашем городе."}/>

                                <RadioInputBlock
                                    mainText={"CDEK или Yandex, курьерская доставка (800 руб., от 2 дней)"}
                                    name={'delivery_type'}
                                    id={'delivery_other_city_type_2'}
                                    dataChange={handleChange}
                                    isExplanation={true}
                                    tooltipText={"Доставка до вашей двери в вашем городе."}/>
                            </MotionDiv>
                        )}
                    </AnimatePresence>
                </InputsForm>

                <PageButtons__Wrapper margin={'big'}>
                    <PageButton onClick={slideToOrderFirstPage} >Назад</PageButton>
                    <PageButton onClick={slideToFullContactPage}>Далее</PageButton>
                </PageButtons__Wrapper>
            </PageWrapper>
        </MotionDiv>
    );
};

export default OtherCityDeliveryTypesPage;
