/*React*/
import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';

/*Created components*/
import MotionDiv from "@core/components/motion_div/motion_div";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import SliderContext from "@core/components/slider/context";
import OrderPopupContext from "@components/order_popup/contexts/context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import RadioInputBlock
    from "@core/components/form_slider_components/general_popup/input_components/radio_input_block/radio_input_block";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";
import {slideUpDown} from "@core/components/form_slider_components/general_popup/motion_animations/animations";
import {AnimatePresence} from "framer-motion";



const MoscowDeliveryTypesPage = () => {
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const {formData, handleChange, totalCost} = useContext(OrderPopupContext);
    const {activePageName, setActivePageName, definedPages, setDefinedPages} = useContext(PopupInsideContext);
    let noChangeActivePages = ['MoscowDeliveryTypesPage'];

    const slideToOrderFirstPage = () => {
        setActivePageName('OrderFirstPage');
        sliderDispatch({
            type: 'SLIDE_LEFT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    /**Next pages functions**/
    const handlerContactsFullPage = () => {
        setActivePageName('ContactsFullPage');
        setDefinedPages({...definedPages, pageThree: 'ContactsFullPage'});
        sliderDispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    /**Form tooltip error**/
    const [formError, setFormError] = useState("")

    /**Processing radio inputs**/
    const slideToFullContactPage = async (e) => {
        e.preventDefault();
        let delivery_type = formData.delivery_type;
        let deliveryData  = customDeliveryStingSeparator(delivery_type)

        if (deliveryData?.description){
            switch (deliveryData.description){
                case 'Самовывоз': {
                    handlerContactsFullPage();
                    break;
                }
                case 'Доставка до вашей двери в пределах МКАД': {
                    handlerContactsFullPage();
                    break;
                }
                case 'CDEK или Yandex, за пределами МКАД': {
                    handlerContactsFullPage();
                    break;
                }
            }
            handleChange('delivery_type', deliveryData.description)
            handleChange('total_cost', parseFloat(totalCost) + parseFloat(deliveryData.cost))
        }
        else {
            setFormError("Необходимо выбрать один из пунктов.")
        }
    };

    const customDeliveryStingSeparator = (deliveryString) =>{
        if (deliveryString){
            const [description, details] = deliveryString.split(/\s+\(/);
            const cost = details.match(/\d+/)[0];
            return {description, cost};
        }
    };

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Выберите тип доставки (Москва)</PageHeader>

                <InputsForm>
                    <AnimatePresence>
                        {noChangeActivePages.includes(activePageName) && (
                            <MotionDiv initial={'usualInitial'}
                                       animate='animate'
                                       exit='exit'
                                       variants={slideUpDown()}>

                        <RadioInputBlock
                            mainText={"Самовывоз (0 руб.)"}
                            name={'delivery_type'}
                            id={'delivery_moscow_type_1'}
                            dataChange={handleChange}
                            isExplanation={false}/>

                        <RadioInputBlock
                            mainText={"Доставка до вашей двери в пределах МКАД (800 руб.)"}
                            name={'delivery_type'}
                            id={'delivery_moscow_type_2'}
                            dataChange={handleChange}
                            isExplanation={false}/>

                        <RadioInputBlock
                            mainText={"CDEK или Yandex, за пределами МКАД (800 руб.)"}
                            name={'delivery_type'}
                            id={'delivery_moscow_type_3'}
                            dataChange={handleChange}
                            isExplanation={false}/>
                            </MotionDiv>
                        )}
                    </AnimatePresence>

                    <PageButtons__Wrapper margin={'big'}>
                        <PageButton onClick={slideToOrderFirstPage} >Назад</PageButton>
                        <PageButton onClick={slideToFullContactPage}>Далее</PageButton>
                    </PageButtons__Wrapper>
                </InputsForm>
            </PageWrapper>
        </MotionDiv>
    );
};

export default MoscowDeliveryTypesPage;
