/*React*/
import React, {useContext, useRef} from 'react';

/*Styles*/
import * as S from './styles/textarea.js';

/*Created components*/
import {
    InputHeader,
} from "@core/components/form_slider_components/general_popup/input_components/input_header/input_header";
import {
    InputWrapper
} from "@core/components/form_slider_components/general_popup/input_components/input_wrapper/input_wrapper";
import inputsFormContext
    from "@core/components/form_slider_components/general_popup/input_components/page_form/context/InputsFormContext";



const Textarea = ({headerText, placeholder, name, isExplanation = false, tooltipText}) => {
    const {formData, formDataHandleChange} = useContext(inputsFormContext)
    const explanationRef = useRef(null);

    const handleChange = (e) => {
        formDataHandleChange(name, e.target.value);
    };

    return (
        <InputWrapper explanationRef={explanationRef} tooltipStandardText={tooltipText} tooltipName={name}>
            <InputHeader isExplanation={isExplanation} explanationRef={explanationRef} headerText={headerText}></InputHeader>
            <S.Textarea type={"textarea"}
                        name={name}
                        value={formData[name]}
                        placeholder={placeholder}
                        onChange={handleChange}
                        rows={"8"}/>
        </InputWrapper>
    );
};

export default Textarea;

