/*Styled imports*/
import { createGlobalStyle } from 'styled-components';
import '@assets/fonts/clash-display.css';



const GlobalStyle = createGlobalStyle`
  :root {
    --STANDARD: black;
    --ADDIT: GREY;
    
    /*Grey*/
    --MAIN_GREY: #2d2c2c;
    --EASY_GREY: #434343;
    --ALMOST_WHITE: #b7b7b7;
    
    /*Black*/
    --MAIN_PREMIUM_BLACK: #0f0f0f;
    
    /*Green*/

    /*Yellow*/

    /*Red*/
    --MAIN_RED: #bb1226;
  }
  
  
  
  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: 'ClashDisplay-Regular', sans-serif;
    font-size: 23px;
    font-weight: 400;
    width: 100%;
    background: var(--MAIN_PREMIUM_BLACK);
    display: flex;
    justify-content: center;
    ${props => props.$disableTransitions && `* {transition: none !important;}`};

    html > div[style] {
      display: none !important;
    }

    body::-webkit-scrollbar {
      width: 0.3rem;
    }

    body::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background: var(--EASY_GREY);
    }

    body::-webkit-scrollbar-track {
      opacity: 0;
      background: var(--MAIN_PREMIUM_BLACK);
    }

    *:focus {
      outline: none;
    }

    a{
      text-decoration: none;
    }

    br {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    input {
      all: unset;
      outline: none;
    }
    
    canvas {
      display: block;
    }
    
    
    
    /**PC**/
    /*110%*/
    @media (max-width: 1800px) {
      font-size: 21.5px;
    }
    
    /*125%*/
    @media (max-width: 1600px) {
      font-size: 19px;
    }
    
    /*150%*/
    @media (max-width: 1400px) {
      font-size: 17px;
    }

    /*175%*/
    @media (max-width: 1200px) {
      font-size: 16px;
    }

    /*200%*/
    @media (max-width: 1000px) {
      font-size: 16px;
    }
    
    /**Phone**/
    /*250%*/
    @media (max-width: 900px) {
      font-size: 16px;
    }
    
    /*300%*/
    @media (max-width: 700px) {
      font-size: 14px;
    }

    /*400%*/
    @media (max-width: 600px) {
      font-size: 12px;
    }

    /*500%*/
    @media (max-width: 400px) {
      font-size: 10px;
    }
  }
`;

export default GlobalStyle;

