/*Styles*/
import styled, {keyframes} from 'styled-components';
import {motion} from "framer-motion";



const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const RequestLoaderBlock = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`

export const RequestLoaderBlock__Loader = styled.div`
  position: relative;
  height: 50%;
  padding: 0.25em;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: white;
  opacity: 50%;
  --_m:
          conic-gradient(transparent 10%, white),
          linear-gradient(white 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: ${rotate} 1s infinite linear;
`

