/*Styles*/
import styled from "styled-components";
import MainImages from "@values/main_images";
import AdditionalImages from "@values/additional_images";
import {Link} from "react-router-dom";
import {SelectNoneWithoutPointer} from '@core/css/css_snippets';



export const Header = styled.div`
  position: relative;
  width: 75rem;
  height: 6.25rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  z-index: 10;
  ${SelectNoneWithoutPointer};

  /*150%*/
  @media (max-width: 1400px) {
    width: 70rem;
  }

  /*175%*/
  @media (max-width: 1200px) {
    width: 62rem;
  }

  /*200%*/
  @media (max-width: 1000px) {
    width: 55rem;
  }

  /*250%*/
  @media (max-width: 900px) {
    width: 47rem;
    height: auto;
    margin-top: 0;
    justify-content: center;
  }

  /*400%*/
  @media (max-width: 600px) {
    width: 42rem;
  }
  
  /*500%*/
  @media (max-width: 400px) {
    width: 40rem;
  }
`

export const Header__LogoIcon = styled.div`
  background: url(${MainImages.SadBloodLogo}) no-repeat center;
  aspect-ratio: 1/1;
  background-size: contain;
  filter: invert(1);
  height: 100%;
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 0.1;
  
  /*250%*/
  @media (max-width: 900px) {
    opacity: 0;
    position: absolute;
  }
`

export const Header__LogoIconTextWrapper = styled.div`
  justify-content: center;
  display: none;

  background-size: contain;
  filter: invert(1);
  cursor: pointer;
  width: 20rem;
  margin-top: 1rem;
  margin-bottom: 2rem;

  /*250%*/
  @media (max-width: 900px) {
    display: block;
    z-index: 10;
  }
`

export const Header__LogoIconText = styled.div`
  background: url(${MainImages.SadBloodLogoText}) no-repeat center;
  width: 100%;
  height: 1.5rem;
`

export const Header__NavigationWrapper = styled.div`
  height: 80%;
  flex-basis: 0;
  flex-grow: 0.8;
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  

  /*250%*/
  @media (max-width: 900px) {
    margin-top: 0.5rem;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
  }
`

export const Header__NavigationInsideWrapper_PosTop = styled.div`
  border-bottom: 0.085rem solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  
  /*250%*/
  @media (max-width: 900px) {
    width: 100%;
    padding-bottom: 0.25rem;
    z-index: 10;
  }
`

export const Header__NavPoint = styled.div`
  margin: 0 0.8rem;
  font-weight: 500;
  color: white;
  letter-spacing: 0.1rem;
  transition: linear 0.15s;
  cursor: pointer;
  
  &:hover{
    transform: scale(1rem);
    color: var(--MAIN_RED);
  }
  
  &.extra{
    background: var(--MAIN_GREY);
    padding: 0.165rem 0.5rem;
    margin-left: 0.5rem;
  }
  
  /*Adaptive*/
  &.clause_main{
    /*175%*/
    @media (max-width: 1200px) {
      display: none;
    }
  }

  /*250%*/
  @media (max-width: 900px) {
    font-size: 1.15rem;
  }
`

export const Header__NavigationInsideWrapper_PosBottom = styled.div`
  width: 100%;

  /*200%*/
  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
  }

  /*250%*/
  @media (max-width: 900px) {
    display: none;
  }
`

export const Header__Slogan = styled.div`
  color: white;
  margin-top: 0.5rem;
  font-size: 0.6rem;
  letter-spacing: 0.1rem;
  font-weight: 600;
`

export const Header__ButtonsWrapper = styled.div`
  height: 100%;
  flex-basis: 0;
  flex-grow: 0.1;

  /*250%*/
  @media (max-width: 900px) {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }

  /*300%*/
  @media (max-width: 700px) {
    width: 100%;
    font-size: 14px;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
`

export const Header__ButtonsInsideWrapper = styled.div`
  width: 100%;
  height: 38%;
  display: flex;
  justify-content: flex-start;

  /*300%*/
  @media (max-width: 700px) {
    width: 78%;
    justify-content: space-between;
    margin-top: 1.15rem;
  }

  /*500%*/
  @media (max-width: 400px) {
    width: 76%;
  }
`

const __Header__Buttons = styled(Link)`
  position: relative;
  aspect-ratio: 1/1;
  background-size: contain;
  transition: linear 0.15s;
  cursor: pointer;
  width: 2rem;
  
  &:hover{
    transform: scale(1.05);
  }

  /*125%*/
  @media (max-width: 1600px) {
    width: 2.25rem;
    height: 2.25rem;
  }

  /*300%*/
  @media (max-width: 700px) {
    width: 3rem;
    height: 3rem;
  }
`

export const Header__BasketWrapper = styled(__Header__Buttons)`
  background: url(${AdditionalImages.Basket}) no-repeat center;
  margin-left: 0.85rem;

  /*125%*/
  @media (max-width: 1600px) {
    margin-left: 0;
  }

  /*300%*/
  @media (max-width: 700px) {
    width: 3rem;
    height: 3rem;
  }
`

export const Header__ProfileWrapperNonAuth = styled(__Header__Buttons)`
  background: url(${AdditionalImages.ProfileNonAuth}) no-repeat center;
  margin-left: 1rem;
`

export const Header__ProfileWrapperAuth = styled(__Header__Buttons)`
  background: url(${AdditionalImages.ProfileAuth}) no-repeat center;
  margin-left: 1rem;
`

export const Header__BasketNotification = styled.div`
  position: absolute;
  right: -0.25rem;
  top: -0.17rem;
  width: 0.85rem;
  height: 0.85rem;
  background: var(--MAIN_RED);
  border-radius: 50%;
  color: white;
  font-size: 0.65rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;

  /*125%*/
  @media (max-width: 1600px) {
    width: 1.1rem;
    height: 1.1rem;
    right: -0.35rem;
    top: -0.27rem;
    font-size: 0.75rem;
  }

  /*300%*/
  @media (max-width: 700px) {
    top: -0.2rem;
    width: 1.25rem;
    height: 1.25rem;
  }
`