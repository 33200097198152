/*React*/
import {useState} from 'react';



const useFormData = (initialState) => {
    const [formData, setFormData] = useState(initialState);

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const resetFormData = () => {
        setFormData(initialState);
    };

    return {
        formData,
        handleChange,
        resetFormData,
    };
};

export default useFormData;