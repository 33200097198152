/*React*/
import React, {useContext} from 'react';

/*Created components*/
import SliderContext from '@core/components/slider/context';
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import DefaultReadyFullInput from '@core/components/form_slider_components/general_popup/input_components/default_ready_full_input';
import {slideUpDown} from '@core/components/form_slider_components/general_popup/motion_animations/animations';
import useFormData from '@core/hooks/useFormData';
import MotionDiv from "@core/components/motion_div/motion_div";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import PasswordInput from "@core/components/form_slider_components/general_popup/input_components/password_input";
import PhoneEmailInput from "@core/components/form_slider_components/general_popup/input_components/phone_email_input";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";

/*Styles*/
import {AnimatePresence} from 'framer-motion';



const Register = () => {
    const {dispatch} = useContext(SliderContext);
    const {activePageName,
           setActivePageName,
           definedPages,
           setUserContact,
           setDefinedPages,
           setCodeExpiryDate,
    } = useContext(PopupInsideContext);
    /*Extra states*/
    let activePages__ForSecondInput = ['Entry', 'Register'];

    const slideToEntry = () => {
        setActivePageName('Entry');
        dispatch({
            type: 'SLIDE_LEFT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    function slideToRegisterConfirmation() {
        setActivePageName('RegisterConfirmation');
        setDefinedPages({...definedPages, pageThree: 'RegisterConfirmation'});
        dispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    }

    /**API**/
    const {formData, handleChange} = useFormData({
        contact_info_type: '',
        contact_info: '',
        name: '',
        password: '',
    });

    const successFunction = (successData) =>{
        setUserContact({contact_info: formData.contact_info, contact_info_type: formData.contact_info_type})
        setCodeExpiryDate(successData.expiry_time)
        slideToRegisterConfirmation()
    }

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Регистрация</PageHeader>

                <InputsForm requestUrl={"api/users/register/start_registration/"} formData={formData} formDataHandleChange={handleChange} successFunction={successFunction}>
                    <PhoneEmailInput/>

                    <AnimatePresence>
                        {activePageName === 'Register' && (
                            <MotionDiv initial={'usualInitial'}
                                              animate='animate'
                                              exit='exit'
                                              variants={slideUpDown()}>

                            <DefaultReadyFullInput headerText={'Ваше имя'}
                                                   placeholder={'Введите ваше имя'}
                                                   name={'name'}/>
                            </MotionDiv>
                        )}
                    </AnimatePresence>

                    <AnimatePresence>
                        {activePages__ForSecondInput.includes(activePageName) && (
                            <MotionDiv initial={'usualInitial'}
                                              animate='animate'
                                              exit='exit'
                                              variants={slideUpDown()}>

                                <PasswordInput/>
                            </MotionDiv>
                        )}
                    </AnimatePresence>

                    <PageButtons__Wrapper margin={'big'}>
                        <PageButton onClick={slideToEntry}>Назад</PageButton>
                        <PageButton>Далее</PageButton>
                    </PageButtons__Wrapper>
                </InputsForm>
            </PageWrapper>
        </MotionDiv>
    );
};

export default Register;
