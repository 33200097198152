/*Styles*/
import styled from 'styled-components';
import {SelectNone} from "@core/css/css_snippets";
import {motion} from "framer-motion";


export const DropInfo = styled(motion.div)`
  position: relative;
  margin-top: -2rem;
  width: 75rem;
  height: 25rem;
  display: flex;
  justify-content: center;
  margin-bottom: 6.25rem;
  ${SelectNone};

  /*200%*/
  @media (max-width: 1000px) {
    margin-top: 0rem;
    width: 70rem;
    height: 22rem;
    margin-bottom: 2.25rem;
  }
`

export const DropInfo__InfoBlockWrapper = styled.div`
  position: absolute;
  margin: auto;
  height: 100%;
  width: 100%;
`

export const DropInfo__InfoBlockInsideWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`

const __InfoBlock = styled.div`
  height: 100%;
`

export const DropInfo__AdditionalInfo_SideLeft = styled(__InfoBlock)`
  position: relative;
  width: 20rem;
  flex-basis: 0;
  flex-grow: 0.2;
`

export const DropInfo__AdditionalInfo_SideRight = styled(__InfoBlock)`
  position: relative;
  flex-basis: 0;
  flex-grow: 0.2;
`

export const DropInfo__MainInfo = styled(__InfoBlock)`
  flex-basis: 0;
  flex-grow: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DropInfo__MainInfoTextBlock = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
`

export const DropInfo__MainInfoAdditionalBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 6.25rem;
  top: 36%;
  display: flex;
  justify-content: space-between;
  z-index: 1;

  /*200%*/
  @media (max-width: 1000px) {
    width: 70%;
    top: 16%;
  }

  /*250%*/
  @media (max-width: 900px) {
    width: 60%;
    top: 10%;
  }
`

export const __DropInfo__MainInfoAdditionalText = styled.div`
  width: 8.35rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  background-color: rgba(255, 255, 255, 0);
  border: 0.085rem solid;
  color: var(--MAIN_RED);

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    width: 7.9rem;
    height: 2.2rem;
    padding:0.3rem;
    font-size: 1.1rem;
  }
`

export const DropInfo__MainInfoAdditionalText_SideLeft = styled(__DropInfo__MainInfoAdditionalText)`
  border-image: linear-gradient(to right, white, var(--MAIN_PREMIUM_BLACK) 60%) 1;


`

export const DropInfo__MainInfoAdditionalText_SideRight = styled(__DropInfo__MainInfoAdditionalText)`
  border-image: linear-gradient(to left, white, var(--MAIN_PREMIUM_BLACK) 60%) 1;
`

export const DropInfo__MainInfoBigText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  font-weight: 1000;
  letter-spacing: 0.02em;
  line-height: 3.4rem;
  text-align: center;
  z-index: 3;
`

export const DropInfo__MainInfoSmallText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;
  font-weight: 1000;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  position: absolute;
  bottom: 5.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25rem;
  height: 1.45rem;
  background: var(--MAIN_RED);
  z-index: 2;

  /*200%*/
  @media (max-width: 1000px) {
    bottom: 3.75rem;
  }

  /*400%*/
  @media (max-width: 600px) {
    bottom: 3rem;
    padding: 0.15rem;
    height: 1.75rem;
  }
`