import styled from 'styled-components';
import AdditionalImages from "@values/additional_images";
import {SelectNone} from '@core/css/css_snippets';



/*Slider*/
export const Slider__MainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Slider__WindowOverflow = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const Slider__SlidersContainer = styled.div.attrs((props) => ({
    style: {
        transitionDuration: `${props.$transition}ms`,
        transform: `translateX(${props.$offset}px)`,
    },
}))`
  display: flex;
  height: 100%;
  transition: transform ${props => props.transitionDuration} ease;
  transform: translateX(${props => props.translateX});
`;


/*Slide*/
export const Slider__Slide = styled.div.attrs((props) => ({
    style: {
        minWidth: `${props.$width}px`,
        maxWidth: `${props.$width}px`,
    },
}))`
  height: 100%;
  min-width: ${props => props.width}px;
  max-width: ${props => props.width}px;
  
  &{
    position: relative;
    width: auto;
    height: ${props => props.$height || 'auto'};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;


/*Switcher*/
export const Slider__SwitcherMainBlock = styled.div`
  position: absolute;
  bottom: 0;
  opacity: 50%;
  height: 0.9rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.25s;
  z-index: 1;

  &:hover {
    opacity: 100%;
  }
`;

export const Slider__SwitcherWrapperInside = styled.div`
  width: 14rem;
  height: 100%;
  padding: 0.25rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Slider__SwitcherButtonClickArea = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0.3rem;
`;

export const Slider__SwitcherButton = styled.div`
  background: ${props => props.$active ? 'linear-gradient(to right, var(--STANDARD), var(--STANDARD)) 0 100%' : 'var(--STANDARD)'};
  height: 0.17rem;
  margin: 0 0.4rem;
  flex-grow: 1;
  border-radius: 1rem;
`;


/*Arrows*/
export const Slider_ArrowBlock_GeneralSetup = styled.div`
  position: absolute;
  top: 0;
  width: 2.5rem;
  padding: 0 0.25rem;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.25s;
  transition-property: opacity;
  z-index: 2;
  opacity: ${(props) => props.$arrowIsVisible ? '50%' : '0%'};
  pointer-events: ${(props) => (props.$arrowIsVisible ? 'auto' : 'none')};

  &:hover {
    opacity: 100%;
  }
`;

export const Slider__ArrowBlock_SideLeft = styled(Slider_ArrowBlock_GeneralSetup)`
  left: 0;
  background: url(${AdditionalImages.ThinArrow}) no-repeat center;
  background-size: 22.5% 22.5%;
`;

export const Slider__ArrowBlock_SideRight = styled(Slider_ArrowBlock_GeneralSetup)`
  right: 0;
  background: url(${AdditionalImages.ThinArrow}) no-repeat center;
  background-size: 22.5% 22.5%;
  transform: rotate(180deg);
`;


/*Addition things*/
export const Slider__InteractBlocker = styled.div`
  ${SelectNone};
  position: absolute;
  display: ${props => props.$display || 'none'};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

