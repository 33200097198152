import React, {useContext} from 'react';

/*Created components*/
import OrderPopupContext from "@components/order_popup/contexts/context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import SliderContext from "@core/components/slider/context";
import MotionDiv from "@core/components/motion_div/motion_div";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import DefaultReadyFullInput
    from "@core/components/form_slider_components/general_popup/input_components/default_ready_full_input";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {AnimatePresence} from "framer-motion";
import {slideUpDown} from "@core/components/form_slider_components/general_popup/motion_animations/animations";
import PhoneInput from "@core/components/form_slider_components/general_popup/input_components/phone_input";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";
import {setTextAndHeaderOkPopup} from "@current/main/redux_reducers/ok_popup_slice";
import PopupExternalContext
    from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import {useDispatch} from "react-redux";
import {useCart} from "@entry_point/initalizers/cart_context";



const ConnectWithUsPage = () => {
    const {closePopupFunc} = useContext(PopupExternalContext);
    const dispatch = useDispatch();
    const {dispatch: sliderDispatch} = useContext(SliderContext);
    const {formData, handleChange} = useContext(OrderPopupContext);
    const {activePageName, setActivePageName} = useContext(PopupInsideContext);
    const {removeCart} = useCart();
    /*Extra states*/
    let noChangeActivePages = ['OrderFirstPage', 'ConnectWithUsPage'];

    const slideToOrderFirstPage = () => {
        handleChange('country', 'Россия');
        setActivePageName('OrderFirstPage');
        sliderDispatch({
            type: 'SLIDE_LEFT',
            payload: {
                blockDisplay: true,
            },
        });
    };

    const successFunction = (successData) =>{
        closePopupFunc();
        dispatch(setTextAndHeaderOkPopup({text: successData?.success, header: ""}))
        removeCart();
    }

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Контактные данные</PageHeader>

                <InputsForm requestUrl={"api/orders/connect_with_us/"} formData={formData} formDataHandleChange={handleChange} successFunction={successFunction}>
                    <PhoneInput/>

                    <AnimatePresence>
                        {noChangeActivePages.includes(activePageName) && (
                            <MotionDiv initial={'noAnimInitial'}
                                       animate='animate'
                                       exit='exit'
                                       variants={slideUpDown()}>
                                <DefaultReadyFullInput headerText={'Ваше имя'}
                                                       placeholder={'Введите ваше имя'}
                                                       name={'fullname'}/>
                            </MotionDiv>
                        )}
                    </AnimatePresence>

                    <PageButtons__Wrapper margin={'big'}>
                        <PageButton onClick={slideToOrderFirstPage}>Назад</PageButton>
                        <PageButton>Отправить</PageButton>
                    </PageButtons__Wrapper>
                </InputsForm>
            </PageWrapper>
        </MotionDiv>
    );
};

export default ConnectWithUsPage;