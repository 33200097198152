/*Styles*/
import styled from 'styled-components';
import {GeneralDefaultInputCSS} from "@core/components/form_slider_components/general_popup/input_components/input/styles/input_styles";



export const Textarea = styled.textarea`
  ${GeneralDefaultInputCSS};
  height: 8rem;
  resize: none;
  box-sizing: border-box;
`

