/*Styles*/
import styled, {css} from 'styled-components';
import {IMaskInput} from "react-imask";
import {GeneralDefaultInputCSS} from "@core/components/form_slider_components/general_popup/input_components/input/styles/input_styles";



/*PhoneEmailInput*/
const PhoneEmailInputsActive = css`
  pointer-events: none;
  transition: all ease 0.25s;
`;

const PhoneEmailInputsInactive = css`
  color: var(--ADDIT);
  cursor: pointer;
  user-select: auto;
  pointer-events: auto;
`;

export const PhoneEmailInput__PhoneText = styled.span`
  ${PhoneEmailInputsActive}
  &.inactive {
    ${PhoneEmailInputsInactive};
  }
`;

export const PhoneEmailInput__PhoneEmailBetween = styled.span`
  color: var(--STANDARD);
  font-size: 1em;
  margin: 0 0.3em;
`;

export const PhoneEmailInput__EmailText = styled.span`
  ${PhoneEmailInputsActive}
  &.inactive {
    ${PhoneEmailInputsInactive};
  }
`;

export const PhoneInput__MainInput = styled(IMaskInput)`
  ${GeneralDefaultInputCSS};
`;

export const EmailInput__MainInput = styled.input`
  ${GeneralDefaultInputCSS};
`;