/*React*/
import React from 'react';

/*Styles*/
import * as S from './styles/styles';



const AdditionalBackground = () => {
    return (<S.AdditionalBackground />);
};

export default AdditionalBackground;
