/*React*/
import React, {useContext} from 'react';

/*Styles*/
import {AnimatePresence} from 'framer-motion';

/*Created components*/
import PopupExternalContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import SliderComponent from '@core/components/slider/slider';
import Entry from '@core/components/form_slider_components/simple_form_slider_auth/auth_pop-up/entry';
import Register from '@core/components/form_slider_components/simple_form_slider_auth/auth_pop-up/register';
import ChangePassword from '@core/components/form_slider_components/simple_form_slider_auth/auth_pop-up/change_password';
import RegisterConfirmation
    from '@core/components/form_slider_components/simple_form_slider_auth/auth_pop-up/register_confirmation';
import ChangePasswordConfirmation
    from "@core/components/form_slider_components/simple_form_slider_auth/auth_pop-up/change_password_confirmation";
import ChangePasswordAddNewPassword
    from "@core/components/form_slider_components/simple_form_slider_auth/auth_pop-up/change_password_add_new_password";



const AuthPopup = () => {
    const {isStartFirstPos} = useContext(PopupExternalContext)
    const {definedPages} = useContext(PopupInsideContext)

    const SliderSettings = {
        closeStatus: isStartFirstPos,
    };

    return (
        <SliderComponent {...SliderSettings}>
            <SliderComponent.Slide>
                <Entry/>
            </SliderComponent.Slide>

            <SliderComponent.Slide>
                <AnimatePresence>
                    {(() => {
                        switch (definedPages.pageTwo) {
                            case 'ChangePassword':
                                return <ChangePassword/>;
                            case 'Register':
                                return <Register/>;
                        }
                    })()}
                </AnimatePresence>
            </SliderComponent.Slide>

            <SliderComponent.Slide>
                <AnimatePresence>
                    {(() => {
                        switch (definedPages.pageThree) {
                            case 'ChangePasswordConfirmation':
                                return <ChangePasswordConfirmation/>;
                            case 'RegisterConfirmation':
                                return <RegisterConfirmation/>;
                        }
                    })()}
                </AnimatePresence>
            </SliderComponent.Slide>

            <SliderComponent.Slide>
                <AnimatePresence>
                    {(() => {
                        switch (definedPages.pageFour) {
                            case 'ChangePasswordAddNewPassword':
                                return <ChangePasswordAddNewPassword/>;
                        }
                    })()}
                </AnimatePresence>
            </SliderComponent.Slide>
        </SliderComponent>
    );
};

export default AuthPopup;

