/*React*/
import {useState, useEffect} from 'react';
import ApiClient from "@core/api/api_client";



export const useAuthCheck = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await ApiClient('/api/auth-check/');
                setIsAuthenticated(response.status === 200);
            } catch (error) {
                setIsAuthenticated(false);
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
            } finally {
                setLoading(false);
            }
        };
        checkAuth();
    }, []);

    return { isAuthenticated, setIsAuthenticated, loading };
};

export default useAuthCheck;

