import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/styles.js';
import {AnimatePresence} from "framer-motion";

/*Created components*/
import {useCart} from "@entry_point/initalizers/cart_context";
import {formatNumber} from "@core/functions/number_correct_margins";
import CreateScene from "@current/main/functions/3D/default_model_script";
import ModelLoader from "@components/product_block/model_loader";
import {addNotification,} from "@current/main/redux_reducers/notify_message_slice";
import {useDispatch} from "react-redux";
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import PreOrder from "@components/pre_order/pre_order";
import add_base_url from "@core/functions/add_base_url";



const ProductBlock = ({productInfo}) => {
    const productsCanvas = useRef(null)
    const [isLoaded, setIsLoaded] = useState(false)
    let modelUrl = "/media/"+productInfo.model_url;
    let cameraValues = productInfo.camera;
    let scaleValues = productInfo.scale;
    let positionValues = productInfo.position;

    let productId = productInfo.id;
    let productName = productInfo.name.toUpperCase();

    /**3D Model**/
    const [progress, setProgress] = useState(0);
    const [isModelLoaded, setModelIsLoaded] = useState(false);

    const handleLoadProgress = (progress) => {
        setProgress(progress);
    };

    const handleLoadComplete = () => {
        setTimeout(()=>{
            setModelIsLoaded(true);
        },100)
    };

    useEffect(() => {
        CreateScene(
            productsCanvas.current,
            ()=>{setIsLoaded(true)},
            add_base_url(modelUrl),
            cameraValues,
            scaleValues,
            positionValues,
            true,
            true,
            handleLoadProgress,
            handleLoadComplete)
    }, [])

    /*Additional info*/
    const [isShowInfo, setIsShowInfo] = useState(false)

    const itemVariants = {
        hidden: {opacity: 0, height: "35.5rem"},
        visible: {
            opacity: 1,
            height: "35.5rem",
            transition: {
                duration: 1,
            },
        },
    };

    /**Cart Add**/
    const dispatch = useDispatch();
    const {cart, addItemToCart} = useCart();
    const [productCount, setProductCount] = useState(0);

    const handlerAddProductToCard = () =>{
        dispatch(addNotification(productName + " added to cart!"));
        addItemToCart(productId, 1)
    }

    useEffect(() => {
        const product = cart.find(item => item.id === productId);
        if (product) {
            setProductCount(product.count);
        } else {
            setProductCount(0);
        }
    }, [cart, productId]);

    /**PreOrder Popup**/
    const [isExistPreOrderPopup, setIsExistPreOrderPopup] = useState(false);
    const popupStates = {isExist: isExistPreOrderPopup, setExist: setIsExistPreOrderPopup};
    const [preOrderProductId, setPreOrderProductId] = useState(null)

    const handlerPreOrder = (id) =>{
        setPreOrderProductId(id)
        setIsExistPreOrderPopup(true)
    }

    return (
        <>
            <GeneralPopup {...popupStates}>
                <PreOrder productId={preOrderProductId}/>
            </GeneralPopup>

            <S.ProductBlock variants={itemVariants} initial="hidden" animate="visible" exit="hidden">

                <S.ProductBlock__Wrapper>
                    <S.ProductBlock__TitleBlock>
                        <S.ProductBlock__TitleBlockWrapper>
                            <S.ProductBlock__TitleNumber $sold_status={productInfo.sold_status}>{productInfo.order}</S.ProductBlock__TitleNumber>
                            <S.ProductBlock__TitleTextWrapper>
                                {productInfo.sold_status &&
                                    <S.ProductBlock__SoldOut>SOLD-OUT</S.ProductBlock__SoldOut>
                                }
                                <S.ProductBlock__TitleText>{productName}</S.ProductBlock__TitleText>
                                <S.ProductBlock__TitleAdditionalBottomBlock/>
                            </S.ProductBlock__TitleTextWrapper>
                        </S.ProductBlock__TitleBlockWrapper>
                    </S.ProductBlock__TitleBlock>

                    <AnimatePresence>{!isModelLoaded && <ModelLoader progress={progress} />}</AnimatePresence>
                    <S.ProductBlock__ProductCanvas ref={productsCanvas} $isLoaded={isLoaded}></S.ProductBlock__ProductCanvas>

                    <S.ProductExplainBlock>
                        <S.ProductExplainBlock__TextWrapper>
                            DRAG ZONE
                        </S.ProductExplainBlock__TextWrapper>

                        <S.ProductExplainBlock__IconWrapper>
                            <S.ProductExplainBlock__Icon_TypeArrow />
                            <S.ProductExplainBlock__Icon_Line />
                            <S.ProductExplainBlock__Icon_TypeArrow className={'bottom'}/>
                        </S.ProductExplainBlock__IconWrapper>
                    </S.ProductExplainBlock>

                    <S.ProductOverlap></S.ProductOverlap>
                </S.ProductBlock__Wrapper>

                <S.ProductBlock__ProductButtonsWrapper>
                    <S.ProductBlock__ProductButtons_TypeAdditionalInfo $sold_status={productInfo.sold_status} $blockedClick={isShowInfo} onClick={() => setIsShowInfo(true)} exit={{x:50, delay: 0.25}}>
                        <S.ProductBlock__ProductButtons__IconBlock>
                            <S.ProductBlock__ProductButtons_TypeAdditionalInfo__Icon/>
                        </S.ProductBlock__ProductButtons__IconBlock>

                        <S.ProductBlock__ProductButtons__Text >ADDIT.<br/>INFO</S.ProductBlock__ProductButtons__Text>
                    </S.ProductBlock__ProductButtons_TypeAdditionalInfo>

                    <AnimatePresence>
                        {isShowInfo &&
                            <S.ProductBlock__AdditionalInfoBlock
                                initial={{ x: "100%", opacity: 0 }}
                                animate={{ x: "0%", opacity: 1 }}
                                exit={{ x: "100%", opacity: 0 }}
                                transition={{
                                    x: { duration: 0.35 },
                                    opacity: { duration: 0.35 }
                                }}>

                                <S.ProductBlock__AdditionalInfoWrapperInside>
                                    <S.ProductBlock__AdditionalInfo_SideTop>
                                        <S.ProductBlock__AdditionalInfoText_TypeName>
                                            <S.ProductBlock__AdditionalInfoTitle>Материал |&nbsp;</S.ProductBlock__AdditionalInfoTitle>{productInfo.material}
                                        </S.ProductBlock__AdditionalInfoText_TypeName>
                                        <S.ProductBlock__AdditionalInfoText_TypeSize>
                                            <S.ProductBlock__AdditionalInfoTitle>Размеры |&nbsp;</S.ProductBlock__AdditionalInfoTitle>{productInfo.size}
                                        </S.ProductBlock__AdditionalInfoText_TypeSize>
                                        <S.ProductBlock__AdditionalInfoText_TypeDescription>{productInfo.description}</S.ProductBlock__AdditionalInfoText_TypeDescription>
                                        <S.ProductBlock__AdditionalInfoText_TypeCost>
                                            {formatNumber(productInfo.cost)} RUB
                                            <S.ProductBlock__AdditionalInfoSmallCost>COST:</S.ProductBlock__AdditionalInfoSmallCost>
                                        </S.ProductBlock__AdditionalInfoText_TypeCost>
                                    </S.ProductBlock__AdditionalInfo_SideTop>

                                    <S.ProductBlock__AdditionalInfo_SideBottom>
                                        <S.ProductBlock__AdditionalInfoButtonPrev $sold_status={productInfo.sold_status} onClick={() => setIsShowInfo(false)}>
                                            <S.ProductBlock__AdditionalInfoButtonIconPrev/>
                                        </S.ProductBlock__AdditionalInfoButtonPrev>

                                        {!productInfo.sold_status

                                            ?   <S.ProductBlock__AdditionalInfoButtonBasket onClick={handlerAddProductToCard}>
                                                    <S.ProductBlock__AdditionalInfoButtonIconBasketBlock>
                                                        <S.ProductBlock__AdditionalInfoButtonIconBasket>+</S.ProductBlock__AdditionalInfoButtonIconBasket>
                                                    </S.ProductBlock__AdditionalInfoButtonIconBasketBlock>

                                                    <S.ProductBlock__AdditionalInfoButtonIconBasketText>
                                                        ADD TO CART ({productCount})
                                                    </S.ProductBlock__AdditionalInfoButtonIconBasketText>
                                                </S.ProductBlock__AdditionalInfoButtonBasket>

                                            :   <S.ProductBlock__AdditionalInfoButtonBasket $sold_status={productInfo.sold_status} onClick={() =>{handlerPreOrder(productInfo.id)}}>
                                                    <S.ProductBlock__AdditionalInfoButtonIconBasketBlock>
                                                        <S.ProductBlock__AdditionalInfoButtonIconBasket>+</S.ProductBlock__AdditionalInfoButtonIconBasket>
                                                    </S.ProductBlock__AdditionalInfoButtonIconBasketBlock>

                                                    <S.ProductBlock__AdditionalInfoButtonIconBasketText>
                                                        PRE-ORDER
                                                    </S.ProductBlock__AdditionalInfoButtonIconBasketText>
                                                </S.ProductBlock__AdditionalInfoButtonBasket>
                                        }
                                    </S.ProductBlock__AdditionalInfo_SideBottom>

                                </S.ProductBlock__AdditionalInfoWrapperInside>
                            </S.ProductBlock__AdditionalInfoBlock>
                        }
                    </AnimatePresence>

                    {!productInfo.sold_status
                        ?   <S.ProductBlock__ProductButtons_TypeAddInBasket onClick={handlerAddProductToCard}>
                                <S.ProductBlock__ProductButtons__IconBlock>
                                    <S.ProductBlock__ProductButtons_TypeAddInBasket__Icon>+</S.ProductBlock__ProductButtons_TypeAddInBasket__Icon>
                                </S.ProductBlock__ProductButtons__IconBlock>

                                <S.ProductBlock__ProductButtons__Text>
                                    <S.ProductBlock__ProductButtons__Cost>{formatNumber(productInfo.cost)} RUB</S.ProductBlock__ProductButtons__Cost>
                                    ADD TO CART ({productCount})
                                </S.ProductBlock__ProductButtons__Text>
                            </S.ProductBlock__ProductButtons_TypeAddInBasket>

                        :   <S.ProductBlock__ProductButtons_TypeAddInBasket $sold_status={productInfo.sold_status} onClick={() =>{handlerPreOrder(productInfo.id)}}>
                                <S.ProductBlock__ProductButtons__IconBlock>
                                    <S.ProductBlock__ProductButtons_TypeAddInBasket__Icon>+</S.ProductBlock__ProductButtons_TypeAddInBasket__Icon>
                                </S.ProductBlock__ProductButtons__IconBlock>

                                <S.ProductBlock__ProductButtons__Text>PRE-ORDER</S.ProductBlock__ProductButtons__Text>
                            </S.ProductBlock__ProductButtons_TypeAddInBasket>
                    }
                </S.ProductBlock__ProductButtonsWrapper>
            </S.ProductBlock>
        </>
    );
};

export default ProductBlock;

ProductBlock.propTypes = {
    productInfo: PropTypes.object.isRequired,
};