/*React*/
import React from 'react';
import PropTypes from "prop-types";

/*Styles*/
import * as S from './styles/styles.js';



const LineSeparator = ({marginTop}) => {
    return (
        <S.LineSeparator $marginTop={marginTop}></S.LineSeparator>
    );
};

LineSeparator.propTypes = {
    marginTop: PropTypes.number,
};

export default LineSeparator;

