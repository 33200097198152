/*Styles*/
import styled from 'styled-components';
import {SelectNone} from "@core/css/css_snippets";


export const Author = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  padding-bottom: 1rem;
  font-weight: 400;
  color: white;
  ${SelectNone}
`
