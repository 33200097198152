/*React*/
import React from 'react';
import PropTypes from 'prop-types';

/*Created components*/
import Input from "@core/components/form_slider_components/general_popup/input_components/input/input";
import InputWrapper
    from "@core/components/form_slider_components/general_popup/input_components/input_wrapper/input_wrapper";

/*Styles*/
import * as S from './styles/country_input_styles';



const CountryInput = ({name, value, onClickFunc}) => {
    return (
        <InputWrapper>
        <S.CountryInput__Header>
            <S.CountryInput__HeaderText>Страна</S.CountryInput__HeaderText>
            <S.PasswordInput__OtherCountry onClick={onClickFunc}>
                Нахожусь в другой стране
            </S.PasswordInput__OtherCountry>
        </S.CountryInput__Header>

        <S.CountryInput__InputWrapper>
            <Input type={'text'}
                   name={name}
                   value={value}
                   disabled={true}/>
        </S.CountryInput__InputWrapper>
        </InputWrapper>
    );
};

CountryInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    dataChange: PropTypes.func,
    tooltipErrorText: PropTypes.string,
    onClickFunc: PropTypes.func,
};

export default CountryInput;