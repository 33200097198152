import React, {useContext} from 'react';

/*Styles*/

/*Created components*/
import PreOrderForm from "@components/pre_order/pre_order_form";
import PopupExternalContext
    from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import SliderComponent from "@core/components/slider/slider";



const PreOrder = ({productId}) => {
    const {isStartFirstPos} = useContext(PopupExternalContext)

    const SliderSettings = {
        closeStatus: isStartFirstPos,
    };

    return (
        <SliderComponent {...SliderSettings}>
            <SliderComponent.Slide>
                <PreOrderForm productId={productId}/>
            </SliderComponent.Slide>
        </SliderComponent>
    );
};

export default PreOrder;