/*React*/
import React from 'react';
import {createBrowserRouter} from "react-router-dom";

/*Created components*/
import BaseLayout from "@pages/base_layout";
import GeneralErrorPage from "@current/main/routers/error_pages/general_error_page/general_error_page";
import {contactsLoader} from "@components/contacts/contacts";
import ScrollToAnchorWrapper from "@components/scroll_to_anchor_wrapper/scroll_to_anchor_wrapper";
import {productsLoader} from "@components/product_block/products";
import {cartLoader} from "@components/cart_product/cart_product";
import MainPage from "@pages/main_page/main_page";
import CartPage from "@pages/cart_page/cart_page";
import ProfileSettingsPage, {userInfoLoader} from "@pages/profile_pages/profile_settings_page/profile_settings_page";
import ProfileOrdersPage, {ordersLoader} from "@pages/profile_pages/profile_orders_page/profile_orders_page";
import DeliveryInfoPage from "@pages/info_pages/delivery_info_page";
import PublicOfferPage from "@pages/info_pages/public_offer_page";



const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <BaseLayout/>
        ),
        loader: contactsLoader,
        errorElement: <GeneralErrorPage/>,
        children: [
            {
                loader: productsLoader,
                path: '',
                element: <ScrollToAnchorWrapper><MainPage/></ScrollToAnchorWrapper>
            },
            {
                loader: cartLoader,
                path: 'cart',
                element: <CartPage/>
            },
            {
                loader: userInfoLoader,
                path: 'profile_settings',
                element: <ProfileSettingsPage/>
            },
            {
                loader: ordersLoader,
                path: 'profile_orders',
                element: <ProfileOrdersPage/>
            },
            {
                path: 'delivery_info',
                element: <DeliveryInfoPage/>
            },
            {
                path: 'public_offer',
                element: <PublicOfferPage/>
            },
        ],
    },
]);

export default router;

