/*Styles*/
import styled from 'styled-components';



export const CountryInput__Header = styled.div`
  width: 100%;
  font-size: 0.85em;
  margin-left: 0.18em;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: self-end;
`;

export const CountryInput__HeaderText = styled.div`
`;

export const PasswordInput__OtherCountry = styled.div`
  cursor: pointer;
  font-size: 0.7em;
  font-weight: 500;
  margin-right: 0.35em;
  transition: all ease 0.4s;

  &:hover {
    color: var(--STANDARD)
  }
`

export const CountryInput__InputWrapper = styled.div`
  position: relative;
`
