/*React*/
import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/profile_settings_page_styles';
import ApiRequest from "@core/api/api_request";
import {useLoaderData} from "react-router-dom";

/*Created components*/
import ProfileWrapper from "@pages/profile_pages/profile_wrapper/profile_wrapper";
import UserDataContext from "@pages/profile_pages/profile_settings_page/user_data_context";
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import UpdateUserDataPopup from "@core/components/form_slider_components/update_user_data_popup/update_user_data_popup";



export async function userInfoLoader() {
    const userData = await ApiRequest("get", `api/users/get_info`);
    return {userData};
}

const ProfileSettingsPage = () => {
    /**Load user data**/
    const [userData, setUserData] = useState([])
    const [userDataForConfirm, setUserDataForConfirm] = useState([])

    const {userData: userDataLoader} = useLoaderData();

    useEffect(()=>{
        if (userDataLoader && userDataLoader.data){
            setUserData(userDataLoader.data);
        }
    },[userDataLoader])

    /**Update user data popup**/
    const [isExistPopupUpdate, setIsExistPopupUpdate] = useState(false);
    const popupStatesUpdate = {isExist: isExistPopupUpdate, setExist: setIsExistPopupUpdate};

    return (
        <>
            <UserDataContext.Provider value={{userData, setUserData, userDataForConfirm, setUserDataForConfirm}}>
                <GeneralPopup {...popupStatesUpdate}>
                    <UpdateUserDataPopup />
                </GeneralPopup>
            </UserDataContext.Provider>

            <ProfileWrapper active={1}>
                <S.ProfileSettings__Wrapper>

                    <S.ProfileSettings__Column>
                        <S.ProfileSettings__InsideBlock>
                            <S.ProfileSettings__Header>CONTACT INFO:</S.ProfileSettings__Header>
                            <S.ProfileSettings__Text $margin={0.25}>Имя: {userData && userData.name}</S.ProfileSettings__Text>
                            {userData && userData.surname &&
                                <S.ProfileSettings__Text $margin={0.25}>Фамилия: {userData.surname}</S.ProfileSettings__Text>
                            }
                            {userData && userData.patronymic &&
                                <S.ProfileSettings__Text $margin={0.25}>Отчество: {userData.patronymic}</S.ProfileSettings__Text>
                            }
                            {userData && userData.phone &&
                                <S.ProfileSettings__Text $margin={0.5}>Телефон: {userData.phone}</S.ProfileSettings__Text>
                            }
                            {userData && userData.email &&
                                <S.ProfileSettings__Text $margin={0.5}>Почта: {userData.email}</S.ProfileSettings__Text>
                            }
                            <S.ProfileSettings__ButtonChange onClick={() => setIsExistPopupUpdate(true)}>Изменить данные</S.ProfileSettings__ButtonChange>
                        </S.ProfileSettings__InsideBlock>
                    </S.ProfileSettings__Column>

                    {/*<S.ProfileSettings__Column>
                    <S.ProfileSettings__InsideBlock>
                        <S.ProfileSettings__Header>FOLLOWS:</S.ProfileSettings__Header>
                        <S.ProfileSettings__Text $margin={0.3}>Телефон: Александр</S.ProfileSettings__Text>
                        <S.ProfileSettings__ButtonChange $margin={1}>Отменить подписку</S.ProfileSettings__ButtonChange>
                        <S.ProfileSettings__Text $margin={0.3}>Телефон: Александр</S.ProfileSettings__Text>
                        <S.ProfileSettings__ButtonChange>Отменить подписку</S.ProfileSettings__ButtonChange>
                    </S.ProfileSettings__InsideBlock>
                </S.ProfileSettings__Column>*/}

                    <S.ProfileSettings__Column>
                    </S.ProfileSettings__Column>
                </S.ProfileSettings__Wrapper>
            </ProfileWrapper>
        </>
    );
};

export default ProfileSettingsPage;
