/*Styles*/
import styled from 'styled-components';
import {SelectNone} from '@core/css/css_snippets';



export const HintMarker__Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-top: 0.1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.45rem;
  height: 0.675rem;
  width: 0.675rem;
  color: black;
  border: 0.1rem solid transparent;
  border-radius: 4rem;
  background:
      linear-gradient(white 0 0) padding-box,
      linear-gradient(to right, black, black) border-box;
  transition: all ease 0.6s;
  margin-right: 0.35rem;

  &:hover{
    color: white;
  }

  &:hover::before{
    position: absolute;
    opacity: 1;
  }
  
  &.hover{
    color: white;
  }

  &.hover::before{
    position: absolute;
    opacity: 1;
  }
  
  &::before {
    position: absolute;
    content: "";
    inset: 0;
    background: black;
    border-radius: 4rem;
    opacity: 0;
    transition: all ease 0.6s;
  }
`

export const HintMarker__InsideSign = styled.span`
  ${SelectNone};
  font-size: 0.675rem;
  font-weight: 600;
  z-index: 2;
  transition: all ease 0.18s;
`


