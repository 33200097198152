import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {addNotification} from "@current/main/redux_reducers/notify_message_slice";


const useMainPageAction = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return () => {
        if (location.pathname === '/') {
            dispatch(addNotification("You are already on the main page!"));
        } else {
            navigate('/');
        }
    };
};

export default useMainPageAction;