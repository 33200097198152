import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from "prop-types";
import {scrollToAnchor} from "@core/functions/anchors_scrolling";



const ScrollToAnchorWrapper = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        const currentUrl = window.location.href;
        const urlArr = currentUrl.split("/");
        urlArr.forEach(part => {
            if (part.includes("#")) {
                scrollToAnchor(part);
            }
        });
    }, [location]);

    return <>{children}</>;
};

ScrollToAnchorWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
    ]),
};

export default ScrollToAnchorWrapper;

