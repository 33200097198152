/*React*/
import React from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/motion_div_styles';



const MotionDiv = ({children, initial, animate, exit, variants}) => {
    return (
        <S.MotionDiv initial={initial}
                     animate={animate}
                     exit={exit}
                     variants={variants}>
            {children}
        </S.MotionDiv>);
};

MotionDiv.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
    ]),
    initial: PropTypes.string,
    animate: PropTypes.string,
    exit: PropTypes.string,
    transition: PropTypes.string,
    variants: PropTypes.object,
};

export default MotionDiv;