/*React*/
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

/*Created components*/
import addBrToString from "@core/functions/add_br_in_string";
import {removeNotification} from "@current/main/redux_reducers/notify_message_slice";

/*Styles*/
import * as S from './styles/styles';
import {AnimatePresence} from "framer-motion";



const NotifyMessage = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.NotifyMessageReducer.notifications);

    useEffect(() => {
        const timers = notifications.map(notification =>
            setTimeout(() => {
                dispatch(removeNotification(notification.id));
            }, 1750)
        );

        return () => {
            timers.forEach(timer => clearTimeout(timer));
        };
    }, [notifications]);

    return (
        <S.NotifyMessage__MessageBlock>
                <AnimatePresence>
                    {notifications.slice().map(notification => (
                        <S.NotifyMessage key={notification.id}
                                         initial={{x: "-100%", opacity: 0, height: 0}}
                                         animate={{x: "0%", opacity: 1, height: 'auto'}}
                                         exit={{x: "-100%", opacity: 0, height: 0}}
                                         transition={{
                                            duration: 0.35
                                         }}>
                            <S.NotifyMessage__Text>{addBrToString(notification.text)}</S.NotifyMessage__Text>
                        </S.NotifyMessage>
                    ))}
                </AnimatePresence>
        </S.NotifyMessage__MessageBlock>);
};

export default NotifyMessage;
