/*React*/
import React from 'react';

/*Created components*/
import CartProduct from "@components/cart_product/cart_product";
import LineSeparator from "@components/line_separator/line_separator";



const CartPage = () => {
    return (
        <>
            <CartProduct />
            <LineSeparator/>
        </>
    );
};

export default CartPage;