import {createSlice} from '@reduxjs/toolkit';



const okPopupSlice = createSlice({
    name: 'okPopupSlice',
    initialState: {
        okPopupHeaderText: "",
        okPopupText: ""
    },
    reducers: {
        setTextAndHeader: (state, action) => {
            state.okPopupText = action.payload.text;
            state.okPopupHeaderText = action.payload.header;
        }
    },
});

export const {setTextAndHeader: setTextAndHeaderOkPopup} = okPopupSlice.actions;

export default okPopupSlice.reducer;

