/*Styles*/
import styled from 'styled-components';
import {FloatingArrow} from "@floating-ui/react";


export const TooltipBlock = styled.div`
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  background: #222;
  color: white;
  font-weight: 400;
  padding: 0.2rem 0.3rem;
  border-radius: 0.2rem;
  font-size: 0.675rem;
  text-align: center;
  z-index: ${props => props.$zIndex};
  display: flex;
  justify-content: center;
  align-items: center;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 0.8rem;
    padding: 0.3rem 0.4rem;
  }
`

export const Arrow = styled(FloatingArrow)`
    fill: #222;
`
