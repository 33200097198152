/*React*/
import React, {useContext, useEffect} from 'react';

/*Created components*/
import SliderContext from '@core/components/slider/context';
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import useFormData from '@core/hooks/useFormData';
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import PhoneEmailInput from "@core/components/form_slider_components/general_popup/input_components/phone_email_input";
import useRequest from "@core/api/hooks/useRequest";
import MotionDiv from "@core/components/motion_div/motion_div";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";



const ChangePassword = () => {
    const {dispatch} = useContext(SliderContext);
    const {setActivePageName,
           definedPages,
           setDefinedPages,
           setUserContact,
           setCodeExpiryDate
    } = useContext(PopupInsideContext);

    const slideToChangePassword = () => {
        setActivePageName('Entry');
        dispatch({
            type: 'SLIDE_LEFT',
            payload: {
                blockDisplay: true,
            },
        });
        setActivePageName('Entry');
    };

    function slideToChangePasswordConfirmation() {
        setDefinedPages({...definedPages, pageThree: 'ChangePasswordConfirmation'});
        setActivePageName('ChangePasswordConfirmation');
        dispatch({
            type: 'SLIDE_RIGHT',
            payload: {
                blockDisplay: true,
            },
        });
    }

    /**Data logic**/
    const {formData, handleChange} = useFormData({
        contact_info_type: '',
        contact_info: '',
    });

    const successFunction = (successData) =>{
        setUserContact({contact_info: formData.contact_info, contact_info_type: formData.contact_info_type})
        setCodeExpiryDate(successData.expiry_time)
        slideToChangePasswordConfirmation()
    }

    /**Tooltip*/
    let tooltipText = "Введите контактные данные, которые вы использовали\nпри регистрации или редактировании профиля."

    return (
        <MotionDiv>
            <PageWrapper>
                <PageHeader>Восстановление пароля</PageHeader>

                <InputsForm requestUrl={'api/users/change_password/start/'} formData={formData} formDataHandleChange={handleChange} successFunction={successFunction}>
                    <PhoneEmailInput isExplanation={true}
                                     tooltipText={tooltipText}
                                     forPage={"ChangePassword"}/>

                    <PageButtons__Wrapper margin={'big'}>
                        <PageButton onClick={slideToChangePassword}>Назад</PageButton>
                        <PageButton>Далее</PageButton>
                    </PageButtons__Wrapper>
                </InputsForm>
            </PageWrapper>
        </MotionDiv>
    );
};

export default ChangePassword;