/*React*/
import React, {useContext, useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/styles.js';

/*Created components*/
import {handlerAnchorClick} from "@core/functions/anchors_scrolling";
import AskPopUp from "@core/components/form_slider_components/ask_popup/ask_pop-up";
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import AuthPopup from "@core/components/form_slider_components/simple_form_slider_auth/auth_pop-up/auth_pop-up";
import AuthContext from "@entry_point/initalizers/auth_context";
import {useCart} from "@entry_point/initalizers/cart_context";
import useMainPageAction from "@current/main/functions/checkUrlMainPage";
import useScrollCheck from "@current/main/functions/checkUrlScroll";



const Header = () => {
    /**Auth check**/
    const {isAuthenticated} = useContext(AuthContext)

    /**Auth popup**/
    const [isExistPopupAuth, setIsExistPopupAuth] = useState(false);
    const popupStatesAuth = {isExist: isExistPopupAuth, setExist: setIsExistPopupAuth};

    /**Ask popup**/
    const [isExistPopupAsk, setIsExistPopupAsk] = useState(false);
    const popupStatesAsk = {isExist: isExistPopupAsk, setExist: setIsExistPopupAsk};

    /**Cart**/
    const {cart} = useCart();
    const [cartProductsCount, setCartProductsCount] = useState(0);

    useEffect(() => {
        const count = cart.reduce((total, item) => total + item.count, 0);
        setCartProductsCount(count);
    }, [cart]);

    /**Page additional functional**/
    const handlerMainPage = useMainPageAction();
    const handlerCheckScroll = useScrollCheck(handlerAnchorClick);
    const handleClickScroll = (event) => {
        handlerCheckScroll(event);
    };

    return (
        <>
            {!isAuthenticated && (
                <GeneralPopup {...popupStatesAuth}>
                    <AuthPopup />
                </GeneralPopup>
            )}

            <GeneralPopup {...popupStatesAsk}>
                <AskPopUp/>
            </GeneralPopup>

            <S.Header id='top'>
                <S.Header__LogoIcon onClick={handlerMainPage} />

                <S.Header__NavigationWrapper>
                    <S.Header__LogoIconTextWrapper onClick={handlerMainPage}>
                        <S.Header__LogoIconText/>
                    </S.Header__LogoIconTextWrapper>

                    <S.Header__NavigationInsideWrapper_PosTop>
                        <S.Header__NavPoint className={'clause_main'} onClick={handlerMainPage}>Main</S.Header__NavPoint>
                        <S.Header__NavPoint as={"a"} href='/#products'
                                            onClick={handleClickScroll}>Products</S.Header__NavPoint>
                        <S.Header__NavPoint as={"a"} href='/#contacts'
                                            onClick={handleClickScroll}>Contacts</S.Header__NavPoint>
                        <S.Header__NavPoint onClick={() => setIsExistPopupAsk(true)}>Ask a question</S.Header__NavPoint>
                    </S.Header__NavigationInsideWrapper_PosTop>

                    <S.Header__NavigationInsideWrapper_PosBottom>
                        <S.Header__Slogan>CHILL PILL FOR YOUR SAD BLOOD</S.Header__Slogan>
                    </S.Header__NavigationInsideWrapper_PosBottom>
                </S.Header__NavigationWrapper>

                <S.Header__ButtonsWrapper>
                    <S.Header__ButtonsInsideWrapper>
                        <S.Header__BasketWrapper to={'cart'}>
                            <S.Header__BasketNotification>
                                {cartProductsCount}
                            </S.Header__BasketNotification>
                        </S.Header__BasketWrapper>

                        {isAuthenticated
                            ? <S.Header__ProfileWrapperAuth to={'profile_settings'}/>
                            : <S.Header__ProfileWrapperNonAuth as={'div'} onClick={() => setIsExistPopupAuth(true)}/>
                        }
                    </S.Header__ButtonsInsideWrapper>
                </S.Header__ButtonsWrapper>
            </S.Header>
        </>
    );
};

export default Header;
