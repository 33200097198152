import React, {useContext} from 'react';

/*Styles*/

/*Created components*/
import PopupExternalContext
    from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import {useDispatch} from "react-redux";
import useFormData from "@core/hooks/useFormData";
import {setTextAndHeaderOkPopup} from "@current/main/redux_reducers/ok_popup_slice";
import PageWrapper from "@core/components/form_slider_components/general_popup/page_components/page_wrapper";
import {
    PageButton,
    PageButtons__Wrapper,
    PageHeader
} from "@core/components/form_slider_components/general_popup/page_components/page_standard_components";
import InputsForm from "@core/components/form_slider_components/general_popup/input_components/page_form/inputs_form";
import PhoneEmailInput from "@core/components/form_slider_components/general_popup/input_components/phone_email_input";



const PreOrderForm = ({productId}) => {
    const {closePopupFunc} = useContext(PopupExternalContext);
    const dispatch = useDispatch();

    /**Data logic**/
    const {formData, handleChange} = useFormData({
        contact_info_type: '',
        contact_info: '',
        product: productId,
    });

    const successFunction = () =>{
        closePopupFunc();
        dispatch(setTextAndHeaderOkPopup({text: "Вам придет уведомление,\n в случае появления товара в наличии!", header: ""}))
    }

    return (
        <PageWrapper>
            <PageHeader>Сообщить о поступлении</PageHeader>

            <InputsForm requestUrl={'api/products/pre_order/'} formData={formData} formDataHandleChange={handleChange} successFunction={successFunction}>
                <PhoneEmailInput/>

                <PageButtons__Wrapper margin={'big'}>
                    <PageButton>Далее</PageButton>
                </PageButtons__Wrapper>
            </InputsForm>
        </PageWrapper>
    );
};

export default PreOrderForm;