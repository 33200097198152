/*Styles*/
import styled from 'styled-components';
import {SelectNoneWithoutPointer} from "@core/css/css_snippets";



export const Contacts = styled.div`
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

export const Contacts__Wrapper = styled.div`
  width: 75rem;
  height: 8.3rem;
  display: flex;
  justify-content: center;
  color: white;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;

  /*150%*/
  @media (max-width: 1400px) {
    width: 68rem;
  }

  /*175%*/
  @media (max-width: 1200px) {
    width: 64rem;
  }

  /*200%*/
  @media (max-width: 1000px) {
    width: 55rem
  }

  /*250%*/
  @media (max-width: 900px) {
    width: 42rem
  }

  /*400%*/
  @media (max-width: 600px) {
    justify-content: space-between;
    width: 31rem
  }

  /*500%*/
  @media (max-width: 400px) {
    padding: 0 0 1rem 0;
    width: 29rem;
  }
`

export const Contacts__ContactInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
  width: 100%;
  height: 100%;
`

const __Contacts__Wrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
`

export const Contacts__Wrapper_SideLeft = styled(__Contacts__Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  /*400*/
  @media (max-width: 600px) {
    font-size: 0.85rem;
  }
  
  /*500%*/
  @media (max-width: 400px) {
    font-size: 0.8rem;
  }
`

export const Contacts__Wrapper_SideCenter = styled(__Contacts__Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Contacts__Wrapper_SideRight = styled(__Contacts__Wrapper)`
  display: flex;
  justify-content: flex-end;
`

export const Contacts__ContactsTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 1000;
  letter-spacing: 0.05em;
  ${SelectNoneWithoutPointer};

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 1.25rem;
  }
`

export const Contacts__ContactsAddress = styled.div`
  margin-top: 0.2rem;
  margin-bottom: 0.9rem;
  line-height: 1.2rem;
  white-space: pre-line;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 0.9rem;
  }
`

export const Contacts__ContactsContacts = styled.div`
  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 0.9rem;
  }
`

export const Contacts__InsideWrapper_SideCenter = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
`

export const Contacts__IconWrapper = styled.a`
  width: 1.875rem;
  height: 1.875rem;
  border: 0.165rem solid white;
  margin: 0 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: linear 0.15s;
  cursor: pointer;

  /*400%*/
  @media (max-width: 600px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const Contacts__Icon = styled.div`
  cursor: pointer;
  aspect-ratio: 1/1;
  filter: invert(1);
  background-size: contain;
  height: ${props => props.$height}%;
  background: ${props => `url(${props.$image})`} no-repeat center;
`

export const Contacts__FollowWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.4rem;
`

export const Contacts__FollowTitle = styled.a`
  display: block;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 1000;
  letter-spacing: 0.05em;
  background: var(--MAIN_GREY);
  padding: 0.2rem 0.4rem;
  transition: linear 0.1s;
  cursor: pointer;
  ${SelectNoneWithoutPointer};
  
  &:hover{
    transform: scale(1.02);
  }

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 1rem;
  }
`

export const Contacts__FollowInfo = styled.div`
  text-align: center;
  margin-top: 0.4rem;
  font-size: 0.75rem;
  margin-bottom: 0.8rem;
  ${SelectNoneWithoutPointer};

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 0.65rem;
  }
`




/**IEInfo**/
export const IEInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 400;
  color: white;
`

export const IEInfo__Text = styled.div`
  font-family: Montserrat,serif;
  font-size: 0.6rem;
  color: var(--ALMOST_WHITE);
  padding: 1rem 0.25rem;
  letter-spacing: 0.025rem;
`