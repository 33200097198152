/*React*/
import React from 'react';
import PropTypes from 'prop-types';

/*Created components*/
import HintMarker from "@core/components/form_slider_components/general_popup/input_components/hint_marker";

/*Styles*/
import * as S from './styles/input_header_styles';



export const InputHeader = ({children, headerText, isExplanation, explanationRef}) => {
    return (
        <S.InputHeader>
            <S.InputHeader__Wrapper>
                {children ? (
                    children
                ) :
                (
                    <S.InputHeader__Text>{headerText}</S.InputHeader__Text>
                )}
            </S.InputHeader__Wrapper>
            {isExplanation && explanationRef &&
                <HintMarker explanationRef={explanationRef}></HintMarker>
            }
        </S.InputHeader>);
};

InputHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
    ]),
    headerText: PropTypes.string,
    isExplanation: PropTypes.bool,
    explanationRef: PropTypes.shape({ current: PropTypes.instanceOf(Element)})
};

export default InputHeader;

