import { createSlice } from '@reduxjs/toolkit';




const yesNoPopupSlice = createSlice({
    name: 'yesNoPopupSlice',
    initialState: {
        yesNoPopupText: "",
    },
    reducers: {
        setText: (state, action) => {
            state.yesNoPopupText = action.payload;
        }
    },
});

export const {setText: setTextYesNoPopup} = yesNoPopupSlice.actions;

export default yesNoPopupSlice.reducer;

let yesFunction = null;
let noFunction = null;

export const setYesCloseFunction = (func) => (dispatch) => {
    yesFunction = func;
};

export const setNoCloseFunction = (func) => (dispatch) => {
    noFunction = func;
};

export const yesClose = () => (dispatch) => {
    if (yesFunction) {
        yesFunction();
    }
};

export const noClose = () => (dispatch) => {
    if (noFunction) {
        noFunction();
    }
};