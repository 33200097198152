/*Styles*/
import styled from 'styled-components';


export const ProfileSettings__Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: default;
  /*FIX*/
  color: white;
  font-family: Montserrat, serif;
  align-items: center;
`

export const ProfileSettings__OrderBlock = styled.div`
  border-top: 0.085rem white solid;
  border-bottom: 0.085rem white solid;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: white;
  font-family: Montserrat, serif;
  box-sizing: border-box;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
`

export const ProfileSettings__Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  letter-spacing: 0.05rem;
`

export const ProfileSettings__HeaderId = styled.div`
  font-weight: 500;
`

export const ProfileSettings__HeaderDate = styled.div`
  font-size: 0.7rem;

`

export const ProfileSettings__ProductLineBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 0.9rem;
  margin-bottom: 0.7rem;
`
export const ProfileSettings__ProductLineText = styled.div`
  flex-basis: 0;
  flex-grow: ${props => props.$grow};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.25rem;
`

export const ProfileSettings__ProductLineTextId = styled(ProfileSettings__ProductLineText)`
  padding: 0.1rem 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem white solid;
  font-size: 0.8rem;
  margin-right: 1rem;

  &.start {
    border: 0;
  }
`

export const ProfileSettings__ProductLineTextResult = styled(ProfileSettings__ProductLineText)`
  width: 100%;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
`
