/*React*/
import React, {useContext} from 'react';
import PropTypes from 'prop-types';

/*Created components*/
import AuthContext from "@entry_point/initalizers/auth_context";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setTextYesNoPopup, setYesCloseFunction} from "@current/main/redux_reducers/yes_no_popup_slice";
import {setTextAndHeaderOkPopup} from "@current/main/redux_reducers/ok_popup_slice";
import logout from "@core/api/logout";

/*Styles*/
import * as S from './styles/profile_wrapper_styles';



const ProfileWrapper = ({children, active}) => {
    /**Auth check**/
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);

    /**Logout**/
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const yesCloseLogout = () => {
        dispatch(setTextAndHeaderOkPopup({text: "Вы успешно вышли из аккаунта!", header: ""}));
        logout();
        setIsAuthenticated(false);
        navigate('/');
    };

    const handlerLogout = () =>{
        dispatch(setYesCloseFunction(yesCloseLogout));
        dispatch(setTextYesNoPopup("Вы действительно хотите выйти из аккаунта?"));
    }

    return (
        <S.ProfileWrapper>
            <S.ProfileWrapper__InsideWrapper>

                <S.ProfileWrapper__Menu>
                    <S.ProfileWrapper__MenuPagesButtonBlock>
                        <S.ProfileWrapper__MenuButton $active={active === 1} to={'../profile_settings'}>PROFILE</S.ProfileWrapper__MenuButton>
                        <S.ProfileWrapper__MenuButton $active={active === 2} to={'../profile_orders'}>HISTORY</S.ProfileWrapper__MenuButton>
                    </S.ProfileWrapper__MenuPagesButtonBlock>

                    {isAuthenticated &&
                        <S.ProfileWrapper__MenuPagesExitButton onClick={handlerLogout}>LOGOUT</S.ProfileWrapper__MenuPagesExitButton>
                    }
                </S.ProfileWrapper__Menu>

                {isAuthenticated
                    ? <S.ProfileWrapper__MainPart>{children}</S.ProfileWrapper__MainPart>
                    : <S.ProfileWrapper__MainPart>
                        <S.ProfileWrapper__NonAuthText>
                            Для взайимодействия со страницами профиля необходимо авторизоваться.
                        </S.ProfileWrapper__NonAuthText>
                    </S.ProfileWrapper__MainPart>
                }
            </S.ProfileWrapper__InsideWrapper>
        </S.ProfileWrapper>
    );
};

ProfileWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
    ]),
    active: PropTypes.number,
};

export default ProfileWrapper;


