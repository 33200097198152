/*React*/
import React from 'react';

/*Styles*/
import * as S from './styles/styles.js';



const OtherUrls = () => {
    return (
        <S.OtherUrls>
            <S.OtherUrls__Wrapper>
                <S.OtherUrls__Link to={'/delivery_info'}>Delivery info</S.OtherUrls__Link>
                <S.OtherUrls__Link to={'/public_offer'}>Public offer</S.OtherUrls__Link>
            </S.OtherUrls__Wrapper>
        </S.OtherUrls>
    );
};

export default OtherUrls;