/*Styled*/
import styled, {keyframes} from 'styled-components';
import {motion} from 'framer-motion';



const l3 = keyframes`
  100% {
    transform: rotate(1turn)
  }
`;

export const Loader__Wrapper = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--STANDARD);
  overflow: hidden;
`;


export const Loader__MainImages = styled.div`
  width: 7rem;
  aspect-ratio: 1;
  display: grid;
  color: white;
  -webkit-mask: radial-gradient(circle 0.21rem, #0000 90%, #000);
  animation: ${l3} 1.5s infinite linear;

  &:before,
  &:after {
    content: "";
    grid-area: 1/1;
    background: radial-gradient(farthest-side at bottom left, currentColor 94%, #0000) top left,
    radial-gradient(farthest-side at top right, currentColor 94%, #0000) bottom right;
    background-size: 63% 50%;
    background-repeat: no-repeat;
    -webkit-mask: radial-gradient(65% 110% at bottom left, #0000 94%, #000) top left,
    radial-gradient(65% 110% at top right, #0000 94%, #000) bottom right;
    -webkit-mask-size: 62% 50%;
    -webkit-mask-repeat: no-repeat;
  }

  &:after {
    transform: rotate(90deg);
  }
`;

