/*Logos*/
import SadBloodLogo from '@assets/images/logos/sad_blood_logo.svg'
import SadBloodLogoText from '@assets/images/logos/sad_blood_logo_text.svg'



const MainImages = {
    SadBloodLogo,
    SadBloodLogoText,
};

export default MainImages;