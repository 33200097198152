/*Styles*/
import styled from 'styled-components';
import {SelectNone} from '@core/css/css_snippets';
import {motion} from "framer-motion";


export const Timer__Text = styled(motion.div)`
  ${SelectNone};
  font-size: 0.675em;
  z-index: 2;
  transition: all ease 0.25s;
`;

export const Timer__Wrapper = styled(motion.div)`
  height: auto;
  color: ${props => props.$isActiveTimer ? 'white' : 'black'};
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.1em;
  width: ${props => props.$isActiveTimer ? '73%' : '60%'};
  max-height: 1em;
  margin-top: 0.4em;
  border: 0.1em solid transparent;
  border-radius: 0.25em;
  background: ${props => !props.$isActiveTimer && 'linear-gradient(white 0 0) padding-box, '} linear-gradient(to right, var(--STANDARD), var(--STANDARD)) border-box;
  transition: width ease-out 0.25s;
  overflow: hidden;
  
  ${props => !props.$isActiveTimer && `
    &:hover {
      color: white;
    }

    &:hover::before {
      position: absolute;
      opacity: 1;
      transition-delay: 0.025s;
    }
    
    &::before {
      position: absolute;
      content: "";
      inset: 0;
      background: linear-gradient(to right, var(--STANDARD), var(--STANDARD));  
      opacity: 0;
      transition-delay: 0.025s;
      transition: all ease 0.25s;
    }
  `}
`;


