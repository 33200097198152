import React from "react";

/*Styles*/
import * as S from "@core/components/form_slider_components/general_popup/input_components/input_wrapper/styles/input_wrapper_styles";

/*Created components*/
import StandardTextTooltip
    from "@core/components/form_slider_components/general_popup/tooltip_components/form_tooltip/standard_text_tooltip";
import ErrorTooltip
    from "@core/components/form_slider_components/general_popup/tooltip_components/form_tooltip/error_tooltip";



export const InputWrapper = ({children, explanationRef, tooltipStandardText, forPage, tooltipName}) => {
    return (
        <>
            <S.InputWrapper>{children}</S.InputWrapper>

            <StandardTextTooltip explanationRef={explanationRef} tooltipStandardText={tooltipStandardText} forPage={forPage}/>
            <ErrorTooltip tooltipName={tooltipName}></ErrorTooltip>
        </>
    )
};

export default InputWrapper;