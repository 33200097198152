/*Styles*/
import styled from 'styled-components';


export const GeneralErrorPage = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const GeneralErrorPage__MainText = styled.div`
  font-size: 3rem;
  color: var(--MAIN_RED);
`

export const GeneralErrorPage__Description = styled.div`
  margin-top: 0.5rem;
  font-size: 1.1rem;
  color: white;
`

export const GeneralErrorPage__ErrorTypeText = styled.div`
  margin-top: 2rem;
  font-size: 1.1rem;
  color: white;
`

