/*React*/
import React, {useEffect, useState} from 'react';

/*Styles*/
import * as S from './styles/styles'

/*Created components*/
import ApiRequest from "@core/api/api_request";
import GeneralPopup from "@core/components/form_slider_components/general_popup/general_popup/general_popup";
import FollowPopup from "@core/components/form_slider_components/follow_pop-up/follow_pop-up";
import {useLoaderData} from "react-router-dom";
import OtherUrls from "@components/other_urls/other_urls";
import {IEInfo} from "./styles/styles";
import Author from "@components/author/author";
import add_base_url from "@core/functions/add_base_url";



export async function contactsLoader() {
    const contacts = await ApiRequest("get", "api/settings/get_all_contacts/");
    return { contacts };
}

const Contacts = () => {
    /**Loader data**/
    const {contacts} = useLoaderData();
    const [social, setSocial] = useState(null);

    useEffect(()=>{
        if (contacts && contacts.data){
            setSocial(contacts.data)
        }
    },[contacts])

    /**Follow Popup**/
    const [isExistFollowPopup, setIsExistFollowPopup] = useState(false);
    const popupStates = {isExist: isExistFollowPopup, setExist: setIsExistFollowPopup};

    return (
        <>
            <GeneralPopup {...popupStates}>
                <FollowPopup />
            </GeneralPopup>

            <S.Contacts id='contacts'>
                <OtherUrls />

                <S.Contacts__Wrapper id='contacts'>
                    <S.Contacts__ContactInfoBlock>
                        <S.Contacts__Wrapper_SideLeft>
                            {social &&
                            <>
                                <S.Contacts__ContactsTitle>CONTACTS:</S.Contacts__ContactsTitle>
                                <S.Contacts__ContactsAddress>
                                    RUSSIA, MOSCOW
                                    <br/>
                                    {social.contact_info.address.toUpperCase()}
                                </S.Contacts__ContactsAddress>
                                <S.Contacts__ContactsContacts>
                                    {social.contact_info.phone}
                                    <br/>
                                    {social.contact_info.email.toUpperCase()}
                                </S.Contacts__ContactsContacts>
                            </>
                            }
                        </S.Contacts__Wrapper_SideLeft>


                        <S.Contacts__Wrapper_SideCenter>
                            {social &&
                                <S.Contacts__InsideWrapper_SideCenter>
                                    {social.social.length > 0 &&
                                        social.social.map(item =>(
                                            <S.Contacts__IconWrapper key={item.id} href={item.href}>
                                                <S.Contacts__Icon  $image={add_base_url(`/media/${item.image_url}`)} $height={item.height_image}></S.Contacts__Icon>
                                            </S.Contacts__IconWrapper>
                                        ))
                                    }
                                </S.Contacts__InsideWrapper_SideCenter>
                            }
                        </S.Contacts__Wrapper_SideCenter>

                        <S.Contacts__Wrapper_SideRight>
                            <S.Contacts__FollowWrapper>
                                <S.Contacts__FollowTitle onClick={() => setIsExistFollowPopup(true)}>FOLLOW US</S.Contacts__FollowTitle>
                                <S.Contacts__FollowInfo>ALL FRESH  INFO<br/>ABOUT  SAD-BLOOD</S.Contacts__FollowInfo>
                            </S.Contacts__FollowWrapper>
                        </S.Contacts__Wrapper_SideRight>
                    </S.Contacts__ContactInfoBlock>
                </S.Contacts__Wrapper>

                <IEInfo />
                <Author />
            </S.Contacts>
        </>
    );
};

export default Contacts;