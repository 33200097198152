/*React*/
import ApiClient from "@core/api/api_client";



const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete ApiClient.defaults.headers.common['Authorization'];
};

export default logout;