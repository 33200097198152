/*React*/
import React, {useContext} from 'react';

/*Created components*/
import PopupExternalContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context";
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import SliderComponent from "@core/components/slider/slider";
import OrderFirstPage from "@components/order_popup/order_first_page";
import ConnectWithUsPage from "@components/order_popup/connect_with_us_page";
import OtherCityDeliveryTypesPage from "@components/order_popup/other_city_delivery_types_page";
import MoscowDeliveryTypesPage from "@components/order_popup/moscow_delivery_types_page";
/*import ContactsCDEKPage from "@ui/components/order_popup/contacts_cdek_page";*/
import ContactsFullPage from "@components/order_popup/contacts_full_page";

/*Styles*/
import {AnimatePresence} from "framer-motion";
import WaitPaymentPage from "@components/order_popup/wait_payment_page/wait_payment_page";




const OrderPopup = () => {
    const {isStartFirstPos} = useContext(PopupExternalContext)
    const {definedPages} = useContext(PopupInsideContext)

    const SliderSettings = {
        closeStatus: isStartFirstPos,
    };

    return (
        <SliderComponent {...SliderSettings}>
            <SliderComponent.Slide>
                <OrderFirstPage />
            </SliderComponent.Slide>

            <SliderComponent.Slide>
                <AnimatePresence>
                    {(() => {
                        switch (definedPages.pageTwo) {
                            case 'ConnectWithUsPage':
                                return <ConnectWithUsPage/>;
                            case 'OtherCityDeliveryTypesPage':
                                return <OtherCityDeliveryTypesPage/>;
                            case 'MoscowDeliveryTypesPage':
                                return <MoscowDeliveryTypesPage/>;
                        }
                    })()}
                </AnimatePresence>
            </SliderComponent.Slide>

            <SliderComponent.Slide>
                <AnimatePresence>
                    {(() => {
                        switch (definedPages.pageThree) {
                            case 'ConnectWithUsPage':
                                return <ConnectWithUsPage/>;
                            case 'ContactsFullPage':
                                return <ContactsFullPage/>;
                        }
                    })()}
                </AnimatePresence>
            </SliderComponent.Slide>

            <SliderComponent.Slide>
                <AnimatePresence>
                    <WaitPaymentPage/>
                </AnimatePresence>
            </SliderComponent.Slide>
        </SliderComponent>
    );
};

export default OrderPopup;

