/*React*/
import React from 'react';
import PropTypes from 'prop-types';

/*Styles*/
import * as S from './styles/input_styles';



export const Input = ({type, placeholder = "", name, value, dataChange = null, externalRef, disabled = false}) => {
    const handleChange = (e) => {
        if (dataChange){
            dataChange(name, e.target.value);
        }
    };

    return (
        <S.Input type={type}
                 $disabled={disabled}
                 placeholder={placeholder}
                 name={name}
                 value={value}
                 onChange={handleChange}
                 ref={externalRef}/>
    );
};

Input.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
    ]),
    type: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    dataChange: PropTypes.func,
    externalRef: PropTypes.shape({ current: PropTypes.instanceOf(Element)}),
    disabled: PropTypes.bool,
};

export default Input;