/*Styles*/
import styled from 'styled-components';


export const LineSeparator = styled.div`
  width: 100vw;
  height: 0.085rem;
  background: var(--MAIN_GREY);
  margin-top: ${props => props.$marginTop}rem;
`
