/*Styles*/
import styled from 'styled-components';
import {motion} from 'framer-motion';



export const MainBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 11;
`

export const AdditionalBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 12;
`