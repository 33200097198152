/*Styles*/
import styled from "styled-components";



export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0.75em;
  width: 72.5%;
`;
