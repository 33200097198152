import styled from 'styled-components';
import AdditionalImages from "@values/additional_images";
import {SelectNone, SelectNoneWithoutPointer} from "@core/css/css_snippets";
import {motion} from "framer-motion";


export const CartProduct = styled.div`
  width: 100vw;
  height: auto;
  margin-top: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
`

export const CartProduct__WrapperLine = styled(motion.div)`
  box-sizing: border-box;
  width: 100%;
  height: 9.8rem;
  border-top: 0.1rem solid var(--MAIN_GREY);
  display: flex;
  justify-content: center;
`

export const CartProduct__WrapperLine__NoProducts = styled(motion.div)`
  box-sizing: border-box;
  width: 100%;
  height: 9.8rem;
  border-top: 0.1rem solid var(--MAIN_GREY);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CartProduct__WrapperLine__NoProducts__Text = styled.div`
  letter-spacing: 0.05rem;
`

export const CartProduct__ProductBlock = styled.div`
  position: relative;
  width: 30.45rem;
  height: 100%;
  display: flex;
  justify-content: flex-start;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    width: 29rem;
  }
`

export const CartProduct__ProductImageBlock = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CartProduct__ProductImage = styled.div`
  background: ${props => `url(${props.$image})`} no-repeat center;
  aspect-ratio: 1/1;
  height: 130%;
  background-size: contain;
`

export const CartProduct__ProductInfo = styled.div`
  box-sizing: border-box;
  padding: 1.3rem 0.85rem;
  width: 55%;
  height: 100%;
`

export const CartProduct__ProductTitleText = styled.div`
  font-size: 1.3rem;
  font-weight: 1000;
  letter-spacing: 0.05em;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 1.15rem;
  }
`

export const CartProduct__ProductCostText = styled.div`
  margin-top: 0.15rem;
  font-size: 1rem;
  font-weight: 1000;
  letter-spacing: 0.05em;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 0.95rem;
  }
`

export const CartProduct__ProductSizeBlock = styled.div`
  margin-top: 0.85rem;
  font-size: 0.9rem;
  letter-spacing: 0.05em;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 0.85rem;
  }
`

export const CartProduct__ProductTextThin = styled.span``


export const CartProduct__ProductTextBold = styled.span`
  font-size: 0.98rem;
  font-weight: 1000;
  color: var(--MAIN_RED);
  margin-left: 1rem;
`


export const CartProduct__ProductQuantityBlock = styled.div`
  margin-top: 0.215rem;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /**Phone**/
  /*250%*/
  @media (max-width: 900px) {
    font-size: 0.85rem;
  }
`

export const CartProduct__ProductQuantityInput = styled.div`
  margin-left: 0.215rem;
  height: 1.1rem;
  width: 1.5rem;
  font-size: 0.8rem;
  text-align: center;
  background: white;
  color: black;
  font-weight: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  ${SelectNone};
`

export const CartProduct__ProductQuantityIconsWrapper = styled.div`
  background: var(--MAIN_GREY);
  padding: 0.25rem;
  border-radius: 0.1rem;
  width: 0.8rem;
  margin: 0 0 0 1rem;
  cursor: pointer;

  &.minus{
    margin: 0 0 0 0.25rem;
  }
`

export const CartProduct__ProductQuantityIcon = styled.div`
  background: url(${AdditionalImages.Plus}) no-repeat center;
  filter: invert(1);
  aspect-ratio: 1/1;
  
  &.minus{
    background: url(${AdditionalImages.Minus}) no-repeat center;
  }
`

export const CartProduct__ProductDelete = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 1.3rem;
  height: 2.15rem;
  background: var(--MAIN_RED);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  ${SelectNoneWithoutPointer};
  transition: 0.15s linear;
  
  &:hover{
    transform: scale(1.05);
  }
`


export const CartProduct__ProductDeleteIcon = styled.div`
  background: url(${AdditionalImages.Plus}) no-repeat center;
  background-size: contain;
  aspect-ratio: 1/1;
  height: 1rem;
  transform: rotate(45deg);
  margin-bottom: 0.215rem;
`

export const CartProduct__ResultCost = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.05em;
  padding: 1rem 0;
  border-top: 0.1rem solid var(--MAIN_GREY);
`

export const CartProduct__PaymentButton = styled.div`
  font-size: 0.8rem;
  font-weight: 1000;
  letter-spacing: 0.1em;
  padding: 0.6rem 0.7rem;
  background-color: rgba(255, 255, 255, 0);
  border: 0.1rem solid;
  border-image: linear-gradient(to right, white, var(--MAIN_PREMIUM_BLACK) 40% 60%, white) 1;
  transition: 0.25s linear;
  cursor: pointer;
  margin-bottom: 3rem;
  ${SelectNoneWithoutPointer};
  
  &:hover{
    border-image: linear-gradient(to right, white 100%, white) 1;
    color: var(--MAIN_RED);
  }
`

