/*React*/
import React, {useState} from 'react';
import PropTypes from 'prop-types';

/*Created components*/
import PopupExternalContext from '@core/components/form_slider_components/general_popup/general_popup/contexts/popup_external_context';
import PopupInsideContext from "@core/components/form_slider_components/general_popup/general_popup/contexts/popup_inside_context";
import MainBackground from '@core/components/form_slider_components/general_popup/general_popup/backgrounds/background_main';
import AdditionalBackground from "@core/components/form_slider_components/general_popup/general_popup/backgrounds/background_additional";
import usePrevious from "@core/hooks/usePrevious";

/*Styles*/
import * as S from './styles/general_popup';
import {AnimatePresence} from "framer-motion";
import {standardFadeOut} from "@core/components/form_slider_components/general_popup/motion_animations/animations";




/**Main wrapper with backgrounds**/
const GeneralPopup = ({children, isExist, setExist}) => {
    const [isAdditionalBackground, setIsAdditionalBackground] = useState(false);

    /***External Context***/
    /**Close popup**/
    const [isStartFirstPos, setIsStartFirstPos] = useState(false);

    /*Preparing (scroll to first position*/
    const closePopupFunc = () => {
        setIsStartFirstPos(true);
        if (definedPages.pageOne){
            setActivePageName(definedPages.pageOne)
        }
        setTimeout(() => {
            setExist(false);
            setIsStartFirstPos(false);
        }, 100);
    };

    /***Inside Context***/
    /*Contact info*/
    const [userContact, setUserContact] = useState({contact_info_type: "PHONE"})

    /*Expire time for code*/
    const [codeExpiryDate, setCodeExpiryDate] = useState(null)

    /*For multi-page forms*/
    const [activePageName, setActivePageName] = useState(null);
    const prevPageName = usePrevious(activePageName);
    const [definedPages, setDefinedPages] = useState({});

    return (
        <PopupExternalContext.Provider value={{ isStartFirstPos, closePopupFunc, isAdditionalBackground, setIsAdditionalBackground }}>
            <AnimatePresence>
                {isExist && (
                    <>
                        <MainBackground closeClickFunc={closePopupFunc} />
                        {isAdditionalBackground && (<AdditionalBackground />)}

                        <S.GeneralPopup__Wrapper_TypeExternal {...standardFadeOut(1, 0.3)}>

                            <PopupInsideContext.Provider value={{
                                activePageName,
                                setActivePageName,
                                prevPageName,
                                definedPages,
                                setDefinedPages,
                                userContact,
                                setUserContact,
                                codeExpiryDate,
                                setCodeExpiryDate,
                            }}>
                                <S.GeneralPopup__Wrapper_TypeInside>
                                        {children}
                                </S.GeneralPopup__Wrapper_TypeInside>
                            </PopupInsideContext.Provider>
                        </S.GeneralPopup__Wrapper_TypeExternal>
                    </>
                )}
            </AnimatePresence>
        </PopupExternalContext.Provider>
    );
};

GeneralPopup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.PropTypes.element,
    ]),
    isExist: PropTypes.bool,
    setExist: PropTypes.func,
};

export default GeneralPopup;