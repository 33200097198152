import ApiClient from "@core/api/api_client";



export const ApiRequest = (method, url, formData) => {
    return ApiClient({
        method: method,
        url: url,
        data: {...formData}
    }).then(response => {
        return {success: true, data: response.data};
    }) .catch(error=>{
        return {success: false, error: error.response ? error.response.data : 'Неожиданная ошибка.'};
    })
}

export default ApiRequest