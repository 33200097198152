/*React*/
import React from 'react';

/*Styles*/
import * as S from './styles/styles';


const Author = () => {
    return (
        <S.Author>
            Created by Alex Groz
        </S.Author>
    );
};

export default Author;
